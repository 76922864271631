import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import { motion, useAnimation, useInView } from 'framer-motion';
import yout from "../../../assets/images/youtu.png";
import Logo1 from "../../../assets/images/calendar-03.png";
import eye from '../../../assets/images/chat-eye.png';

const PodcastDetail = () => {
    const { name } = useParams();
    const [podcast, setPodcast] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchPodcast = async () => {
            try {
                // URL-encode the name to handle special characters
                const encodedName = encodeURIComponent(name);
                const response = await axios.get(`${BASE_URL}/admins/podcast/${encodedName}/`);
                setPodcast(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching podcast:', error);
                setError('Failed to fetch podcast details.');
                setLoading(false);
            }
        };

        fetchPodcast();
    }, [name]);

    const formatTime = (timeString) => {
        if (!timeString) return 'Invalid time';

        const [hours, minutes] = timeString.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${period}`;
    };


    const extractYouTubeId = (url) => {
        if (!url) return '';
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|live\/)?([^"&?\/\s]{11})|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?\/\s]{11})/);
        return match ? (match[1] || match[2]) : '';
    };


    const videoId = extractYouTubeId(podcast?.youtube_url);  // Fetch the actual YouTube URL from podcast data
    const videoUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : '';


    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-all-20'>
                        <div className='relative rounded-[30px]'>
                            <div className='p-6'>
                                <motion.div
                                    ref={ref}
                                    animate={controls}
                                    initial={{ opacity: 0, y: 50 }}
                                    className=""
                                >

                                    <div className='flex justify-between p-12'>

                                        <p className='text-[#00549A] text-[49px] font-bold className="text-2xl font-bold">{podcast.name}'>{podcast?.name}</p>


                                        <div className='flex  gap-12'>
                                            <div className='flex  gap-4'>
                                                <div>
                                                    <img src={Logo1} alt="Calendar" className="pt-4 " />

                                                </div>


                                                <div className='flex gap-4 pt-1'>
                                                    <p className='text-[#222222] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>{podcast?.date}</p>
                                                    <p className='text-[#222222] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>
                                                        {formatTime(podcast?.starting_time)} to {formatTime(podcast?.ending_time)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='border h-[85px] px-4 py-2 rounded-[10px]'>
                                                <div className='flex gap-2 pt-2 '>
                                                    <div className='pt-1'>
                                                        <img src={eye} alt="" />
                                                    </div>
                                                    <div>
                                                        <p className='text-[#00549A] text-[22px]'>{ }</p>
                                                    </div>
                                                </div>
                                                <p className='text-[#58585A] text-[14px] '>People are currently Watching</p>
                                            </div>




                                        </div>

                                    </div>
                                    <hr className='mt-2' />





                                    <div className="p-12">


                                        {videoId ? (
                                            <div style={{ width: '100%', minWidth: '400px' }}>
                                                <div style={{ position: 'relative', width: '100%', overflow: 'hidden', paddingTop: '56.25%' }}>
                                                    <iframe
                                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
                                                        src={videoUrl}
                                                        title="Podcast Video"
                                                        allowFullScreen
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    ></iframe>
                                                </div>
                                            </div>
                                        ) : (
                                            <p>No YouTube video available</p>
                                        )}

                                    </div>

                                    <div className='grid grid-cols-2 p-12'>
                                        {/* Hosts Section */}
                                        <div>
                                            <h2 className="text-[26px] text-[#58585A] font-semibold">Hosts :</h2>
                                            {podcast?.hosts && podcast.hosts.length > 0 ? (
                                                <div className="flex flex-wrap gap-8 overflow-x-auto">
                                                    {podcast.hosts.map((host) => (
                                                        <div key={host.id} className="flex-shrink-0 flex flex-col items-center mx-2 my-2">
                                                            {host.photo && (
                                                                <img
                                                                    src={BASE_URL + host.photo}
                                                                    alt={host.name}
                                                                    className="w-28 h-28 rounded-full"
                                                                />
                                                            )}
                                                            <p className='text-[18px] text-center mt-2 p-3'>{host.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p>No hosts available</p>
                                            )}
                                        </div>

                                        {/* Guests Section */}
                                        <div>
                                            <h2 className="text-[26px] text-[#58585A] font-semibold">Guests :</h2>
                                            {podcast?.guests && podcast.guests.length > 0 ? (
                                                <div className="flex flex-wrap gap-8 overflow-x-auto">
                                                    {podcast.guests.map((guest) => (
                                                        <div key={guest.id} className="flex-shrink-0 flex flex-col items-center mx-2 my-2">
                                                            {guest.photo && (
                                                                <img
                                                                    src={BASE_URL + guest.photo}
                                                                    alt={guest.name}
                                                                    className="w-28 h-28 rounded-full"
                                                                />
                                                            )}
                                                            <p className='text-[18px] text-center mt-2 p-3'>{guest.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p>No guests available</p>
                                            )}
                                        </div>
                                    </div>


                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PodcastDetail;
