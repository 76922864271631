import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import Logo1 from "../../../assets/images/calendar.png";
import arrow2 from "../../../assets/images/ev-1.png";
import arrow1 from "../../../assets/images/ev.png";
import lin from "../../../assets/images/linkdn.png";
import facebook from "../../../assets/images/face.png";
import twit from "../../../assets/images/twit.png";
import insta from "../../../assets/images/insta.png";
import yout from "../../../assets/images/youtu.png";
import cancel from "../../../assets/images/speak cac.png";
import { getLocal } from '../../../helpers/auth';
import { isTokenExpired } from '../../../helpers/auth';
import { motion, useAnimation, useInView } from 'framer-motion';


const Detail = () => {
    const { slug } = useParams();
    const location = useLocation();

    console.log("slug", slug)
    const [event, setEvent] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [enrolled, setEnrolled] = useState(false);
    const [watchNowVisible, setWatchNowVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [speakers, setSpeakers] = useState([]);
    const speakersPerPage = 4;
    const indexOfLastSpeaker = currentPage * speakersPerPage;
    const indexOfFirstSpeaker = indexOfLastSpeaker - speakersPerPage;
    const currentSpeakers = speakers.slice(indexOfFirstSpeaker, indexOfLastSpeaker);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null); // State for the selected speaker
    const [isModalOpen, setIsModalOpen] = useState(false);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const handleSpeakerClick = (speaker) => {
        setSelectedSpeaker(speaker);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSpeaker(null);
    };

    const goToPrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        console.log("slug:", slug);

        const fetchSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/`, {

                });
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvent();
    }, [slug]);
    useEffect(() => {
        console.log("slug:", slug);

        const fetchGeneralSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/generalevents/${slug}/`, {

                });
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchGeneralSingleEvent();
    }, [slug]);

    const checkEnrollmentStatus = async () => {
        try {
            if (event) {
                const response = await axios.get(`${BASE_URL}/accounts/check-enrollment/${event.slug}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                setEnrolled(response.data.enrolled);
    
                const today = new Date();
                const eventDate = new Date(event.date);
                const eventEndDate = new Date(eventDate);
                eventEndDate.setHours(23, 59, 59, 999);  
    
                // Determine if the event has ended
                const hasEnded = today > eventEndDate;
    
                // Check if the user is enrolled
                if (response.data.enrolled) {
                    if (hasEnded) {
                        // If event has ended, show "Watch Now" button
                        setWatchNowVisible(true);
                    } else if (event.single_events.length > 0) {
                        // If event is not completed, check if "Watch Now" should be visible within one hour before the start
                        const firstMultiEvent = event.single_events[0].multi_events[0];
                        const firstMultiTime = firstMultiEvent.starting_time;
                        const firstMultiTimeDate = new Date(`${eventDate.toDateString()} ${firstMultiTime}`);
                        
                        // Calculate one hour before the event start time
                        const oneHourBefore = new Date(firstMultiTimeDate.getTime() - 60 * 60 * 1000);
    
                        
                        if (today >= oneHourBefore) {
                            setWatchNowVisible(true);
                        } else {
                            setWatchNowVisible(false);
                        }
                    } else {
                        setWatchNowVisible(false);
                    }
                } else {
                    setWatchNowVisible(false);
                }
            }
        } catch (error) {
            console.error('Error checking enrollment status:', error);
        }
    };
    
    
    
   
    
    
    const [generalEnrolled, setGeneralEnrolled] = useState(false);


    const checkgeneralEnrollmentStatus = async () => {
        try {
            if (event) {
                const response = await axios.get(`${BASE_URL}/accounts/general_check-enrollment/${event.slug}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                setGeneralEnrolled(response.data.enrolled);
    
                const today = new Date();
                const eventDate = new Date(event.date);
                const eventEndDate = new Date(eventDate);
                eventEndDate.setHours(23, 59, 59, 999); // Assuming the event ends at the end of the day
    
                // Determine if the event has ended
                const hasEnded = today > eventEndDate;
    
                // Check if the user is enrolled
                if (response.data.enrolled) {
                    if (hasEnded) {
                        // If event has ended, show "Watch Now" button
                        setWatchNowVisible(true);
                    } else if (event.single_events.length > 0) {
                        // If event is not completed, check if "Watch Now" should be visible within one hour before the start
                        const firstMultiEvent = event.single_events[0].multi_events[0];
                        const firstMultiTime = firstMultiEvent.starting_time;
                        const firstMultiTimeDate = new Date(`${eventDate.toDateString()} ${firstMultiTime}`);
                        
                        // Calculate one hour before the event start time
                        const oneHourBefore = new Date(firstMultiTimeDate.getTime() - 60 * 60 * 1000);
    
                        // Check if current time is within one hour before the event start or any time after the event starts
                        if (today >= oneHourBefore) {
                            setWatchNowVisible(true);
                        } else {
                            setWatchNowVisible(false);
                        }
                    } else {
                        setWatchNowVisible(false);
                    }
                } else {
                    setWatchNowVisible(false);
                }
            }
        } catch (error) {
            console.error('Error checking general enrollment status:', error);
        }
    };
    
    
    
    useEffect(() => {
       
        checkEnrollmentStatus();
        checkgeneralEnrollmentStatus();
    
       
        const intervalId = setInterval(checkEnrollmentStatus, 10000); // Check every 60 seconds
    
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [isLoggedIn, event]);


    const navigate = useNavigate();
    const handleLoginClick = () => {
        const currentPath = window.location.pathname;
        localStorage.setItem('loginRedirectPath', currentPath);
        navigate('/login');
    };




    const enrollInEvent = async () => {
        try {
            await axios.post(`${BASE_URL}/accounts/enroll/${event?.slug}/`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`
                }
            });
    
            setEnrolled(true);
    
            // Fetch the updated enrollment status and event details
            await checkEnrollmentStatus(); // Ensure the watch now button visibility is updated based on the event time
    
            // alert('Successfully enrolled in the event.');
        } catch (error) {
            console.error('Error enrolling in the event:', error);
            // alert('Failed to enroll in the event. Please try again later.');
        }
    };

    const generalEnrollInEvent = async () => {
        try {
            await axios.post(`${BASE_URL}/accounts/general_enroll/${event?.slug}/`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            setGeneralEnrolled(true);

            await checkEnrollmentStatus(); 

            // alert('Successfully enrolled in the general event.');
        } catch (error) {
            console.error('Error enrolling in the general event:', error);
            // alert('Failed to enroll in the general event. Please try again later.');
        }
    };



    useEffect(() => {
        const fetchEventSpeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/speakers/`);
                if (response.data && Array.isArray(response.data.speakers)) {
                    setSpeakers(response.data.speakers);

                } else {
                    console.error('Invalid data format for speakers:', response.data);
                }
            } catch (error) {
                console.error('Error fetching event speakers:', error);
            }
        };

        fetchEventSpeakers();
    }, [slug]);

    useEffect(() => {
        const fetchGeneralEventSpeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_events/${slug}/speakers/`);
                if (response.data && Array.isArray(response.data.speakers)) {
                    setSpeakers(response.data.speakers);

                } else {
                    console.error('Invalid data format for speakers:', response.data);
                }
            } catch (error) {
                console.error('Error fetching event speakers:', error);
            }
        };

        fetchGeneralEventSpeakers();
    }, [slug]);

    const formatTime = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        const formattedHours = parseInt(hours) > 12 ? parseInt(hours) - 12 : parseInt(hours);
        return `${formattedHours}:${minutes} ${parseInt(hours) >= 12 ? 'PM' : 'AM'}`;
    };

    // useEffect(() => {
    //     const checkAuth = () => {
    //         const user_auth = getLocal();
    //         const isAuthValid = user_auth && !isTokenExpired(user_auth);
    //         setIsLoggedIn(isAuthValid);
    //     };

    //     // Check the auth status immediately
    //     checkAuth();

    //     // Set an interval to check the auth status every 5 minutes (300000 ms)
    //     const interval = setInterval(checkAuth, 300000);

    //     // Cleanup interval on component unmount
    //     return () => clearInterval(interval);
    // }, [location]);

    const handleJoinFCPI = () => { };

    const handleWatchEvent = () => { };
    const { id } = useParams();
    const [speaker, setSpeaker] = useState(null);
    useEffect(() => {
        const fetchSpeaker = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/speakers/${id}/`);
                setSpeaker(response.data);
            } catch (error) {
                console.error('Error fetching speaker:', error);
            }
        };

        fetchSpeaker();
    }, [id]);
    const handleEnrollClick = async () => {
        try {
            // Execute both enrollments independently
            const enrollPromise = enrollInEvent();
            const generalEnrollPromise = generalEnrollInEvent();
    
            // Wait for both promises to resolve
            await Promise.all([enrollPromise, generalEnrollPromise]);
    
            // Fetch and update the enrollment status
            await checkEnrollmentStatus(); // Call to update the state
    
            alert('Successfully enrolled in this event.');
        } catch (error) {
            console.error('Error handling enrollment click:', error);
            alert('Failed to enroll in the event. Please try again later.');
        }
    };
    

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-all-20'>
                        <div className='overflow-hidden relative rounded-[30px]'>
                            <div className='aspect-[4] bg-card3-background bg-cover to-slate-900 bg-opacity-40 gradient responsive-card rounded-[30px]'>
                                {event && (
                                    <>
                                        <div className='flex relative z-10 pl-12 pt-32'>
                                            <p className='text-white text-[40px] not-italic font-bold leading-[56px] uppercase '>{event.event_name}</p>
                                        </div>
                                        <p className='text-white pl-12 text-[26px] mob-left relative z-10 not-italic font-normal leading-[normal]'>{event.forum_name}</p>
                                        <hr className='mt-8 w-[95%] ml-9 relative z-10' />
                                        <div className='flex relative z-10 p-10 pl-12 gap-8'>
                                            <div className='flex content-center gap-4'>
                                                <div>
                                                    <img src={Logo1} alt="Calendar" className="" />
                                                </div>
                                                <div className='flex  gap-12'>
                                                    {event.days === 1 ? (

                                                        <>
                                                            <p className='text-white pt-2 text-[17px] mm-0 not-italic font-normal leading-[normal]'>
                                                                {event.date}
                                                            </p>
                                                            {event.single_events.map((singleEvent, index) => (
                                                                <div key={index} className='  rounded-[10px]   pt-1 mb-3'>
                                                                    <div className="flex">
                                                                        {singleEvent.multi_events.length > 0 && (
                                                                            <div className="flex">
                                                                                <p className="text-[#58585A]   text-[17px] not-italic font-normal leading-[normal]">
                                                                                    {singleEvent.multi_events.length > 0 && (
                                                                                        <div className="flex">
                                                                                            <p className="text-white pt-1 text-[17px] not-italic font-normal leading-[normal]">
                                                                                                {`${formatTime(singleEvent.multi_events[0].starting_time)} - ${formatTime(singleEvent.multi_events[singleEvent.multi_events.length - 1].ending_time)}`}
                                                                                            </p>
                                                                                        </div>
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        )}


                                                                    </div>

                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (

                                                        <>
                                                            <p className='text-white pt-2 text-[17px] mm-0 not-italic font-normal leading-[normal]'>
                                                                {event.start_date} - {event.end_date}
                                                            </p>
                                                        </>
                                                    )}



                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='pl-12 pr-12 pt-16'>
                            <p className='text-[#222] text-[35px] not-italic font-semibold leading-[normal]'>Speaker Desk</p>
                            <div className='flex justify-between '>
                                  <p className='text-[#58585A] pt-7 text-[17px] not-italic font-normal leading-[28px]'> 
                                    {/* Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web */}
                                    <br />
                                    {/* sites still in their infancy. Various versions have evolved */}
                                </p>  
                                <div className='text-end pt-16'>
                                    <button onClick={goToPrevPage} disabled={currentPage === 1}>
                                        <img src={arrow1} alt="Previous Page" className='pl-32' />
                                    </button>
                                    <button onClick={goToNextPage} disabled={indexOfLastSpeaker >= speakers.length}>
                                        <img src={arrow2} alt="Next Page" className='pl-2' />
                                    </button>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pb-8 pt-8 gap-8">
                                {currentSpeakers.map((speaker, index) => (
                                    speaker ? (

                                        <motion.div
                                            className="relative group"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 2,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <Link to="#">
                                                <div
                                                    className="bg-gray-100 hover:bg-gray-200 rounded-[10px] p-4"
                                                    onClick={() => handleSpeakerClick(speaker)}
                                                >
                                                    <img
                                                        src={BASE_URL + (speaker.photo || '')}
                                                        alt={speaker.name || 'Speaker'}
                                                        className="w-[308px] h-[308px] object-cover rounded-[10px]"
                                                    />
                                                    <p className="text-black text-center pt-8 pb-8 text-[18px] not-italic font-semibold leading-[18px]">
                                                        {speaker.name || 'No Name Available'}
                                                    </p>
                                                </div>
                                            </Link>
                                            {/* Tooltip */}
                                            <div className="absolute bottom-full -mb-40 left-1/2   transform -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 whitespace-nowrap">
                                                {speaker.tooltip || 'Click Here to View Profile '}
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <div key={index} className="bg-gray-100 rounded-[10px] text-center py-8">
                                            <p className="text-black text-center text-[18px]">Speaker data is missing</p>
                                        </div>
                                    )
                                ))}


                            </div>
                            {isModalOpen && selectedSpeaker && (

                                <motion.div
                                    className="fixed  inset-0 flex items-center justify-center mt-50 bg-black bg-opacity-50"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <div className='bg-white p-8 rounded-[12px] w-[780px]  '>
                                        <div className='flex justify-between '>

                                            <p className='text-[24px] font-semibold'>Speaker Desk</p>

                                            <div>

                                                <img src={cancel} alt="" onClick={handleCloseModal} />
                                            </div>
                                        </div>
                                        <hr className='mt-6' />

                                        <div className='flex pt-8'>
                                            <div className=' '>
                                                <img src={BASE_URL + selectedSpeaker?.photo} alt="" className=" w-[160px] h-[145px] object-cover rounded-[10px]" />
                                            </div>
                                            <div className='mt-4 pl-6 w-[100%]'>
                                                <p className='text-[20px] pt-2  text-[#00549A] font-bold'>{selectedSpeaker.name}</p>
                                                <hr className='mt-4' />
                                                <p className='text-[16px] pt-4 font-semibold'>Qualification: {selectedSpeaker.qualification}</p>
                                                <p className='text-[13px]'> Designation: {selectedSpeaker.designation}</p>

                                            </div>

                                        </div>
                                        <p className='text-[13px] pt-6'> {selectedSpeaker.description}</p>
                                        <hr className='mt-6' />
                                        <div className='flex space-x-4 pt-6 '>
                                            {selectedSpeaker.facebook && (
                                                <a href={selectedSpeaker.facebook} target="_blank" rel="noopener noreferrer">
                                                    <img src={facebook} alt="Facebook" className=" " />
                                                </a>
                                            )}
                                            {selectedSpeaker.linkedin && (
                                                <a href={selectedSpeaker.linkedin} target="_blank" rel="noopener noreferrer">
                                                    <img src={lin} alt="LinkedIn" className="" />
                                                </a>
                                            )}
                                            {selectedSpeaker.twitter && (
                                                <a href={selectedSpeaker.twitter} target="_blank" rel="noopener noreferrer">
                                                    <img src={twit} alt="twitter" className=" " />
                                                </a>
                                            )}
                                            {selectedSpeaker.instagram && (
                                                <a href={selectedSpeaker.instagram} target="_blank" rel="noopener noreferrer">
                                                    <img src={insta} alt="instagram" className=" " />
                                                </a>
                                            )}
                                            {selectedSpeaker.youtube && (
                                                <a href={selectedSpeaker.youtube} target="_blank" rel="noopener noreferrer">
                                                    <img src={yout} alt="youtube" className=" " />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                        </div>
                        <div className='p-12 pl-12'>
                            <div className='rounded-[10px] text-left w-[48%] mob-full p-10' style={{ backgroundColor: 'rgba(197, 229, 255, 0.50)' }}>
                                <p className='text-[#222] text-[36px] not-italic font-semibold leading-[normal]'>
                                    {isLoggedIn ? "Enroll Event" : "If you are not Logged In FCPI Please"}
                                </p>
                                <div className='flex pt-6 pl-2 mob-center'>
                                    {!isLoggedIn ? (
                                        <>
                                            <button
                                                onClick={handleLoginClick}
                                                className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A]"
                                            >
                                                <p className="text-[14px] not-italic font-medium leading-[normal]">Login</p>
                                            </button>
                                            <Link to='/register'>
                                                <button
                                                    onClick={handleJoinFCPI}
                                                    className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A] ml-4"
                                                >
                                                    <p className="text-[14px] not-italic font-medium leading-[normal]">Join FCPI</p>
                                                </button>
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={handleEnrollClick}
                                                className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A]"
                                                disabled={enrolled && generalEnrolled} // Disable if both enrollments are done
                                            >
                                                <p className="text-[14px] not-italic font-medium leading-[normal]">
                                                    {(enrolled || generalEnrolled) ? "Enrolled" : "Enroll"}
                                                </p>
                                            </button>

                                            {watchNowVisible && (
                                                <Link to={`/enroll/${event?.slug}/`}>
                                                    <button
                                                        onClick={handleWatchEvent}
                                                        className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A] ml-4"
                                                    >
                                                        <p className="text-[14px] white not-italic font-medium leading-[normal]">Watch Now</p>
                                                    </button>
                                                </Link>
                                            )}


                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;
