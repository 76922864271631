import React, { useState, useEffect,useRef } from 'react';
import { BASE_URL } from '../../../../Utils/Config';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getUserProfile } from '../../../../helpers/auth';
import Logoa from "../../../../assets/images/add.png";
import Log1 from "../../../../assets/images/cancel.png";
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
const Live = () => {
    const [user, setUser] = useState(null);
    const [liveEvents, setLiveEvents] = useState([]);
    const [toggleDetailsVisible, setToggleDetailsVisible] = useState(null);
    const authToken = localStorage.getItem('authToken');
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                if (authToken) {
                    const profile = await getUserProfile();
                    setUser(profile.user);
                    toast.success('User profile fetched successfully');
                } else {
                    toast.error('Authentication token is missing');
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
                toast.error('Error fetching user profile');
            }
        };

        fetchUserProfile();
    }, [authToken]);

    useEffect(() => {
        const fetchEnrolledEvents = async () => {
            try {
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/accounts/users/enrolled-events/`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }),
                    axios.get(`${BASE_URL}/accounts/general_users/enrolled-events/`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    })
                ]);

                const enrolledEvents1 = response1.data.events;
                const enrolledEvents2 = response2.data.events;
                const combinedEvents = [...enrolledEvents1, ...enrolledEvents2];

                console.log("Raw response data from both endpoints:", combinedEvents);

                const live = combinedEvents.filter(event => event.status === 'Live');
                setLiveEvents(live);
            } catch (error) {
                console.error('Error fetching enrolled events:', error);
            }
        };

        fetchEnrolledEvents();
    }, [authToken]);

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        if (isNaN(d.getTime())) return 'Invalid date';
        const pad = num => num.toString().padStart(2, '0');
        return `${pad(d.getDate())}-${pad(d.getMonth() + 1)}-${d.getFullYear()}`;
    };

    const formatTime = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        let formattedHours = parseInt(hours, 10);
        const ampm = formattedHours >= 12 ? 'PM' : 'AM';
        if (formattedHours > 12) formattedHours -= 12;
        if (formattedHours === 0) formattedHours = 12; // Midnight or Noon case
        return `${formattedHours}:${minutes} ${ampm}`;
    };

    const toggleImageVisible = (index) => {
        setToggleDetailsVisible(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <div className='bg-[#f4f4f4] w-[100%] h-[100%] p-6 '>
            <div className='bg-white p-6'>
                <div className="dropdown-content mt-4">
                    {liveEvents.map((event, index) => (
                        <div key={index} className=''>
                            <motion.div
                                    className="flex gap-[2rem] pt-6 "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                <div className='w-full'>
                                <p className={`text-[#222] w-[93%] text-[19px]  text-left not-italic font-bold leading-[30px] ${toggleDetailsVisible === index ? 'text-green-500' : ''}`}>{event.event_name}</p>
                                </div>
                                <button
                                    className={`px-4 py-4 rounded-[30px] smooth`}
                                    style={{ backgroundColor: toggleDetailsVisible === index ? '#00549A' : '#C5E5FF' }}
                                    onClick={() => toggleImageVisible(index)}
                                >
                                    <img src={toggleDetailsVisible === index ? Log1 : Logoa} alt="Toggle Details" className="rounded-[30px]" />
                                </button>
                                </motion.div>
                            <div className='flex gap-4 pt-4'>
                             
                                <div className='w-full'>
                                    {toggleDetailsVisible === index && (
                                         <motion.div
                                         className="  "
                                         initial={{ opacity: 0 }}
                                         animate={{ opacity: 4 }}
                                         transition={{
                                             duration: 1,
                                             ease: "easeInOut",
                                         }}
                                         ref={ref}
                                     >
                                            <div className='flex gap-[16rem]'>
                                                <p className='text-[#58585A] text-[16px] font-bold'>{event.forum_name}</p>
                                            </div>
                                            <div className='flex gap-4 pt-2'>
                                                <p className='text-[#58585A] text-[17px] font-normal'>  {formatDate(event.start_date)}</p>
                                                {event.end_date && <p className='text-[#58585A] text-[17px] font-normal'> - {formatDate(event.end_date)}</p>}
                                            </div>
                                            {event.days === 1 && event.start_time && event.end_time && (
                                                <div className='flex gap-4 pt-2'>
                                                    <p className='text-[#58585A] text-[17px] font-normal'>Start Time: {formatTime(event.start_time)}</p>
                                                    <p className='text-[#58585A] text-[17px] font-normal'>End Time: {formatTime(event.end_time)}</p>
                                                </div>
                                            )}
                                            {/* Omit times for multi-day events */}
                                            <div className='pt-3'>
                                                <button className='bg-[#1AB78D] rounded-[105px] px-7 py-1.5'>
                                                    <Link to={`/events-detail/${event.slug}/`}>
                                                        <p className='text-white text-[18px] font-semibold'>View Event</p>
                                                    </Link>
                                                </button>
                                            </div>
                                            </motion.div>
                                    )}
                                </div>
                            </div>
                            <hr className='mt-6' />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Live;





 