import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import { Link } from 'react-router-dom';
import cap from "../../../assets/images/lineee.svg";
import blog from "../../../assets/images/Blogs.jpg";
import arrow from "../../../assets/images/arrow-right-01-round.png";
import arrow3 from "../../../assets/images/arrow3.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import pdf from "../../../assets/images/pdf-02.png";


const Newsletter = () => {
    const [blogs, setBlogs] = useState([]);
    const [generalBlogs, setGeneralBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const [newsletters, setNewsletters] = useState([]);
    useEffect(() => {
        const fetchNewsletters = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/newsletterslist/`);
                setNewsletters(response.data);
                console.log("response.data", response.data)
            } catch (error) {
                console.error('Error fetching newsletters:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsletters();
    }, []);



    const allBlogs = [...blogs, ...generalBlogs];

    const formatPeriod = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startMonth = start.toLocaleString('default', { month: 'long' });
        const endMonth = end.toLocaleString('default', { month: 'long' });
        const year = end.getFullYear();

        if (startMonth === endMonth) {
            return `${startMonth} ${year}`;
        }
        return `${startMonth}-${endMonth} ${year}`;
    };
    const handleDownload = async (pdfUrl, fileName) => {
        try {
            const response = await axios.get(pdfUrl, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); // Append anchor to body for Firefox
            a.click();
            document.body.removeChild(a); // Remove anchor from body
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    
    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px] pb-8">

                        <div className="grid grid-cols-1 md:grid-cols-2 bg-[#00549A] rounded-t-[30px] relative overflow-hidden brb-30 ptb-30">
                            <div>
                                <p className="text-white text-[32px] not-italic font-semibold leading-[normal] p-8">News Letter</p>

                                <motion.div
                                    className="blogimg "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <img src={blog} alt="Blogs" className="w-full pt-12 rounded-tr-[30px]" />
                                </motion.div>
                            </div>
                            <div className="start position-relative z-10 pl-30">
                                <p className="text-[#FFc233] text-[23px] pt-28 not-italic font-medium leading-[normal]">FEATURED</p>
                                <p className="text-white text-[42px] pt-4 not-italic font-normal leading-[51px]">Looking back at <br />2023</p>
                                <div className="pt-4">
                                    <button className="bg-white px-[32px] py-2 rounded-[30px] ">
                                        <div className="flex gap-4">
                                            <p className="text-[#00549A] text-[16px] not-italic font-semibold leading-[normal]">Continue reading</p>
                                            <img src={arrow} alt="Arrow" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="bg-line">
                                <img src={cap} alt="Line Decoration" className="w-full pt-12 rounded-tr-[30px]" />
                            </div>
                        </div>

                        <div className='p-8 '>
                            <p className='text-[32px] font-semibold p-2 pt-16'>Freshly squeezed</p>
                            <p className=' text-[#58585A] text-[16px] w-[45%] p-2'>Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved</p>


                        </div>

                        <motion.div
                            className="grid grid-cols-3 pr-10 pl-10  pt-100 gap-12"
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 1 }}
                            ref={ref}
                        >
                            {newsletters.map((newsletter) => (
                                
                                
                                    <div className="bg-[#f4f4f4]  hover:bg-[#f4f4f4] p-8 rounded-[10px] ">
                                        <img src={newsletter.thumbnail} alt={newsletter.title} className="rounded-[8px] w-[99%]" />
                                        <p className="text-[#222] pt-4 text-[21px]  not-italic font-medium leading-[28px]">
                                            {newsletter.title}
                                        </p>
                                        <div className=" justify-between  gap-[12rem]">
                                            <p className="text-[#222] text-[16px] pt-4 not-italic font-normal leading-[28px]">{newsletter.edition}</p>

                                        </div>
                                        <p className="text-[color:var(--Gray,#58585A)] pt-4 w-[300px] text-[16px] not-italic font-normal leading-[normal]">
                                            {formatPeriod(newsletter.start_date, newsletter.end_date)}
                                        </p>


                                        <div className='pt-8 w-[65%]'>
    <button
        onClick={() => handleDownload(newsletter.pdf, `${newsletter.title}.pdf`)}
        className='flex gap-2 bg-[#1AB78D] rounded-[10px] w-full py-2 p-4'
    >
        <p className='text-white text-center text-[16px] not-italic font-semibold leading-[normal]'>Download Newsletter</p>
        <img src={pdf} alt="PDF Icon" />
    </button>
</div>



                                    </div>
                              
                            ))}
                        </motion.div>








                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
