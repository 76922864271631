import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Utils/Config';
import imag from "../../../assets/images/user-circle.png";
import imag1 from "../../../assets/images/mail.png";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import "./Acertificate.css"

const Certificates = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setMailingAddressActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);
    const [editSpeakerVisible, setEditSpeakerVisible] = useState(false);
    const [selectedCertificates, setSelectedCertificates] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [formData, setFormData] = useState({
        event: '',
        image: null,

    });
    const [certificates, setCertificates] = useState([]);
    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/`);
                setEvents(response.data);
                console.log("event", response.data)
            } catch (error) {
                console.error('Error fetching events:', error);

                setEvents([]);
            }
        };

        fetchEvents();
    }, []);


   
        const fetchCertificates = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/certificateslist/`);
                console.log("Certificates data:", response.data);
                setCertificates(response.data);
            } catch (error) {
                console.error('Error fetching certificates:', error);
            }
        };
        useEffect(() => {
        fetchCertificates()
    }, []);



    const handleFormSubmit = async (e, certificateID) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('event', selectedCertificates.event);
            formData.append('image', selectedImage || selectedCertificates.image);
    
            let response;
            if (!certificateID) {
        
                response = await axios.post(`${BASE_URL}/admins/certificates/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                alert('Certificate added successfully!');
            } else {
        
                response = await axios.put(`${BASE_URL}/admins/certificates/${certificateID}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                alert('Certificate updated successfully!');
            }
    
            const updatedCertificate = response.data;
            setCertificates(prevCertificates => {
                if (!certificateID) {
              
                    return [...prevCertificates, updatedCertificate];
                } else {
                   
                    return prevCertificates.map(certificate =>
                        certificate.id === updatedCertificate.id ? updatedCertificate : certificate
                    );
                }
            });
    
            setEditFormVisible(false);
            setSelectedCertificates({ event: '', image: '' });  
            fetchCertificates();
        } catch (error) {
            console.error('Error updating certificate:', error);
            alert('Failed to update certificate. Please try again.');
        }
    };
    
    const [events, setEvents] = useState([]);




    const handleDelete = async (certificateID) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this certificate?');
    
        if (confirmDelete) {
            try {
                await axios.delete(`${BASE_URL}/admins/certificates/${certificateID}/delete`);
    
               
                setCertificates(prevCertificates => 
                    prevCertificates.filter(certificate => certificate.id !== certificateID)
                );
    
                alert('Certificate deleted successfully!');
            } catch (error) {
                console.error('Error deleting certificate:', error);
                alert('Failed to delete certificate. Please try again.');
            }
        }
    };
    

    const handleEdit = (certificate) => {
        console.log('Certificate:', certificate);
        setSelectedCertificates(certificate);
        setEditFormVisible(true);
        setSelectedImage(null);

        const eventId = certificate.event ? certificate.event : '';
        console.log('Certificate Event ID:', eventId);

        setFormData({
            ...formData,
            event: eventId,
            image: formData.image
        });
    };








    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        
        // Validate file type (e.g., only allow image files)
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (imageFile && !allowedTypes.includes(imageFile.type)) {
            alert('Please select a valid image file (JPEG, PNG, GIF).');
            return;
        }
    
        setSelectedImage(imageFile);
        setFormData(prevFormData => ({
            ...prevFormData,
            image: imageFile
        }));
    };
    




    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };



    const handleFieldChange = (index, fieldName, value) => {
        const updatedFormData = { ...formData };
        updatedFormData.certificates_contents[index][fieldName] = value;
        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const postData = new FormData();
            console.log("formData", formData)
            postData.append('event', formData.event);
            postData.append('image', formData.image);
    
            const response = await axios.post(`${BASE_URL}/admins/certificates/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            console.log(response.data);
    
            alert('Certificate added successfully!');
            window.location.reload();
    
            setFormData({
                event: '',
                image: null
            });
    
        } catch (error) {
            console.error('Error adding certificate:', error);
            // Handle specific error message for duplicates
            if (error.response && error.response.status === 400 && error.response.data.error) {
                alert(`Failed to add certificate: ${error.response.data.error}`);
            } else {
                alert('Failed to add certificate. Please try again.');
            }
        }
    };
    






    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);
        if (!personalInfoActive) {
            setMailingAddressActive(false);
        }
    };

    const toggleMailingAddress = () => {
        setMailingAddressActive(!mailingAddressActive);
        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Certificates Management</p>
                <div className='flex pt-6 gap-[12rem] p-6'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Certificate</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={toggleMailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Certificate</p>
                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className=''>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <div className=' gap-2'>
                                    <div className="w-[50%]  relative">
                                        <div className="relative pt-2">
                                            <p className='text-[color:var(--Black,#222)] pt-8 pb-2 text-[18px] not-italic font-medium leading-[24px]'>Choose Event</p>
                                            <div className=''>
                                                <select
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    value={formData.event}
                                                    onChange={(e) => setFormData({ ...formData, event: e.target.value })}
                                                >
                                                    <option value="" disabled>Select an event</option>
                                                    {events && events.live_events && events.upcoming_events && events.completed_events && events.live_events.concat(events.upcoming_events, events.completed_events).map(event => (
                                                        <option key={event.id} value={event.id}>{event.event_name}</option>
                                                    ))}
                                                </select>



                                            </div>

                                        </div>
                                    </div>

                                    <div className='w-[50%]  relative'>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pb-2 not-italic pt-8 font-medium leading-[24px]'>Template</p>
                                        <div className="pt-1">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="border border-gray-400 w-[100%] rounded-[6px] px-[20px] py-2  bg-[#F4F4F4]"
                                            />
                                        </div>
                                    </div>

                                </div>




                                <div className='pt-4'>
                                    <div className='flex gap-2 justify-start'>


                                        <div className=' w-[235px]'>
                                            <button type="submit" className='submit-btn-blog rounded-[10px] w-full  py-4'>
                                                <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Generate</p>
                                            </button>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editFormVisible && (
                            <div className='pt-4'>
                                <div className='bg-gray-100 py-4 w-full flex p-6 gap-[29.5rem] '>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold leading-[normal]'>Event Name</p>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold leading-[normal]'>Actions</p>
                                </div>
                                {certificates.map(certificate => (
                                    <div key={certificate.id} className='flex pt-4 pb-4 pl-8 border-b border-gray-200'>
                                        <div className='flex gap-4'>
                                            <p className='text-[color:var(--Gray,#58585A)] w-[570px] text-[16px] not-italic font-normal leading-[normal]'>
                                                {certificate.event_name ? certificate.event_name : 'Event name not available'}
                                            </p>
                                        </div>
                                        <div className='flex gap-6'>
                                            <div>
                                                <img src={ed} alt="" className=' ' onClick={() => handleEdit(certificate)} />
                                            </div>
                                            <div>
                                                <img src={del} alt="" className=' ' onClick={() => handleDelete(certificate.id)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <hr className='' />
                            </div>
                        )}

                        {editFormVisible && selectedCertificates && (
                            <div className='w-[50%]'>
                                <form onSubmit={(e) => handleFormSubmit(e, selectedCertificates.id)}>
                                    <div className="text-start">
                                        <div className="w-[100%]  relative">
                                            <div className="relative pt-2">
                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Choose Event</p>
                                                <select
                                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    value={formData.event}
                                                    onChange={(e) => setFormData({ ...formData, event: e.target.value })}
                                                >
                                                    <option value="" disabled>Select an event</option>

                                                    {/* Render options for each event */}
                                                    {events && Object.values(events).flat().map(event => (
                                                        <option key={event.id} value={event.id}>
                                                            {event.event_name}
                                                        </option>
                                                    ))}
                                                </select>


                                            </div>
                                        </div>
                                        {selectedCertificates && (
                                            <div className="pt-2">
                                                <img src={selectedImage ? URL.createObjectURL(selectedImage) : selectedCertificates.image} alt="Old Forum Image" className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-[400px] bg-[#F4F4F4]" />
                                            </div>
                                        )}
                                        <div className="pt-2">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="border border-gray-400 rounded-[6px] px-[20px]  w-full bg-[#F4F4F4]"
                                            />
                                        </div>

                                        {/* <div className='pt-8 flex'>
                                            <div className='flex gap-2 justify-end'>
                                                <button type="submit" className='border border-gray-300 rounded-[10px] px-4 py-2'>
                                                    <p className='text-[#1A1A1A]  text-center text-[16px] not-italic font-normal leading-[normal]'>Submit</p>
                                                </button>
                                            </div>
                                            <button
                                            type="button"
                                            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                                            onClick={handleExitEditForm}
                                        >
                                            Exit
                                        </button>
                                     
                                        </div> */}


                                        <div className='flex'>
                                            <div className='pt-8 w-[500px]'>
                                                <button type="submit" className='submit-btn-certificate rounded-[10px] w-full  py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                                                </button>
                                            </div>
                                            <div className='pt-8 w-[25%] ml-6'>
                                                <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitEditForm}>Close</p>
                                                </button>
                                            </div>
                                        </div>




                                    </div>
                                </form>
                            </div>
                        )}

                    </>
                )}



            </div>
        </div>
    );
};

export default Certificates;
