import React, { useState, useEffect, useRef } from 'react';
import log from "../../../../assets/images/Frame.png";
import "./UserDashboard.css"
import logo1 from "../../../../assets/images/planet.png";
import logo5 from "../../../../assets/images/circle.png";
import { BASE_URL } from '../../../../Utils/Config';
import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
import { getUserProfile } from '../../../../helpers/auth';
import { Link } from 'react-router-dom';
import pro from "../../../../assets/images/profile.png"
import { useNavigate } from 'react-router-dom';
import { isTokenExpired, getLocal } from '../../../../helpers/auth';
import { motion, useAnimation, useInView } from 'framer-motion';

const UserDashboard = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [upcomingActive, setUpcomingActive] = useState(false)
    const [liveActive, setLiveActive] = useState(false)
    const [PastActive, setPastActive] = useState(true)
    const [activeButton1, setActiveButton1] = useState('today');
    const [activeButton, setActiveButton] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [isRotated, setIsRotated] = useState(true);
    const [isRotated1, setIsRotated1] = useState(true);
    const [activePerson, setActivePerson] = useState(false);
    const [user, setUser] = useState(null);
    console.log(user)
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const navigate = useNavigate();
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const checkAuth = () => {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {

                navigate('/login');
            }
        };
        checkAuth();
    }, [navigate]);


    const handleIconClick = () => {
        setIsRotated(!isRotated);
    };
    const handleIconClick1 = () => {
        setIsRotated1(!isRotated1);

    };
    // const fetchUserProfile = async () => {
    //     try {
    //         if (userId && authToken) {
    //             // console.log("mmmmmmmmmmmmmmmmm", authToken)
    //             const profile = await getUserProfile(userId);
    //             setUser(profile.user);
    //             setUserProfile(profile);
    //             toast.success('User profile fetched successfully');
    //         } else {
    //             toast.error(' ');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching user profile:', error);
    //         toast.error('Error fetching user profile');
    //     }
    // };

    // useEffect(() => {
    //     fetchUserProfile();

    //     return () => {

    //     };
    // }, [userId, authToken]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [liveEvents, setLiveEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        primary_position: '',
        state: '',
        primary_pharmacy_degree: '',
        secondary_pharmacy_degree: '',
        additional_degrees: '',
        city: '',
        country: '',
        pincode: '',
        current_work_institution: '',
        pharmacy_college_name: '',
        pharmacy_college_degree: '',
        image: ''
    });
    const [profile, setProfile] = useState(null);

   
        const fetchUserProfile = async () => {
            try {
                const profileData = await getUserProfile();
                setUser(profileData.user);
                setProfile(profileData.profile);

                // Assuming profileData contains all the necessary fields
                setFormData({
                    first_name: profileData.user.first_name || '',
                    last_name: profileData.user.last_name || '',
                    date_of_birth: profileData.profile.date_of_birth || '',
                    state: profileData.profile.state || '',
                    primary_pharmacy_degree: profileData.profile.primary_pharmacy_degree || '',
                    secondary_pharmacy_degree: profileData.profile.secondary_pharmacy_degree || '',
                    additional_degrees: profileData.profile.additional_degrees || '',
                    current_work_institution: profileData.profile.current_work_institution || '',
                    city: profileData.profile.city || '',
                    country: profileData.profile.country || '',
                    pincode: profileData.profile.pincode || '',
                    pharmacy_college_name: profileData.profile.pharmacy_college_name || '',
                    pharmacy_college_degree: profileData.profile.pharmacy_college_degree || ''
                });

                toast.success('User profile fetched successfully');
            } catch (error) {
                console.error('Error fetching user profile:', error);
                // toast.error('Error fetching user profile');
            }
        };
        useEffect(() => {
        fetchUserProfile();
    }, []);





    const [dropdownVisible, setDropdownVisible] = useState({
        today: true,
        thisWeek: false,
        thisMonth: false,
        thisYear: false,
    });
    const toggleDropdown1 = (button) => {
        setActiveButton1(button);
        setDropdownVisible((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map(key => [key, key === button ? !prevState[key] : false])),
        }));
    };
    const [eventstoday, setEventsToday] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventstoday/`),
                    axios.get(`${BASE_URL}/admins/general_eventstoday/`)
                ]);
    
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;
    
                // Combine and sort events
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });
    
                setEventsToday(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
    
        fetchEvents();
    }, []);
    

    const [eventsweek, setEventsWeek] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch data from both endpoints concurrently
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventsweek/`),
                    axios.get(`${BASE_URL}/admins/general_eventsweek/`)
                ]);
    
                // Extract events from both responses
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;
    
                // Combine events from both responses
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];
    
                // Sort combined events by date
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });
    
                // Update state with sorted events
                setEventsWeek(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
    
        fetchEvents();
    }, []);
    


    
    const [eventmonth, setEventsMonth] = useState({});

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch data from both endpoints concurrently
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventsmonth/`),
                    axios.get(`${BASE_URL}/admins/general_eventsmonth/`)
                ]);
    
                // Extract events from both responses
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;
    
                // Combine events from both responses
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];
    
                // Sort combined events by date
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });
    
                // Update state with sorted events
                setEventsMonth(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
    
        fetchEvents();
    }, []);
    


    const [eventyear, setEventsYear] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch data from both endpoints concurrently
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventsyear/`),
                    axios.get(`${BASE_URL}/admins/general_eventsyear/`)
                ]);
    
                // Extract events from both responses
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;
    
                // Combine events from both responses
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];
    
                // Sort combined events by date
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });
    
                // Update state with sorted events
                setEventsYear(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
    
        fetchEvents();
    }, []);
    

    const formatTime = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        const formattedHours = parseInt(hours) > 12 ? parseInt(hours) - 12 : parseInt(hours);
        return `${formattedHours}:${minutes}`;
    };


    const toggleUpcoming = () => {
        setUpcomingActive(!upcomingActive);

        if (!upcomingActive) {
            setPastActive(false);
            setLiveActive(false);
        }
    };
    const togglePast = () => {
        setPastActive(!PastActive);

        if (!PastActive) {
            setUpcomingActive(false);
            setLiveActive(false);
        }
    };
    const toggleLive = () => {
        setLiveActive(!liveActive);

        if (!liveActive) {
            setPastActive(false);
            setUpcomingActive(false);
        }
    };


    useEffect(() => {
        const fetchEnrolledEvents = async () => {
            try {
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/accounts/users/enrolled-events/`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }),
                    axios.get(`${BASE_URL}/accounts/users/general_enrolled-events/`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    })
                ]);

                // Combine the data from both responses
                const enrolledEvents1 = response1.data.events;
                const enrolledEvents2 = response2.data.events;
                const combinedEvents = [...enrolledEvents1, ...enrolledEvents2];

                // Filter events by status
                const upcoming = combinedEvents.filter(event => event.status === 'Upcoming');
                const live = combinedEvents.filter(event => event.status === 'Live');
                const past = combinedEvents.filter(event => event.status === 'Completed');

                // Update state with filtered events
                setUpcomingEvents(upcoming);
                setLiveEvents(live);
                setPastEvents(past);

                console.log("upcoming", upcoming);
                console.log("live", live);
                console.log("past", past);
            } catch (error) {
                console.error('Error fetching enrolled events:', error);
            }
        };

        fetchEnrolledEvents();
    }, [authToken]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const [editMode, setEditMode] = useState(false);
    const [showFormPopup, setShowFormPopup] = useState(false);
    const openFormPopup = () => {
        setFormSubmitted(false);
        setShowFormPopup(true);
    };

    const closeFormPopup = () => {
        setShowFormPopup(false);
        setFormSubmitted(false);
    };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setSelectedImage(imageUrl);

            setFormData(prevFormData => ({
                ...prevFormData,
                image: imageFile,
            }));
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const authToken = localStorage.getItem('authToken');

            if (!authToken || !user.id || !profile.id) {
                toast.error('User not authenticated');
                return;
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                if (key !== 'image') {
                    formDataToSend.append(key, formData[key]);
                }
            });

            if (formData.image instanceof File) {
                formDataToSend.append('image', formData.image);
            }

            const res = await axios.put(`${BASE_URL}/accounts/user/${user.id}/`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            toast.success('Profile updated successfully');
            fetchUserProfile();
            setEditMode(false);
            setFormSubmitted(true);
            setShowFormPopup(false);

        } catch (error) {
            console.error('Error updating profile:', error.response?.data || error.message);
            toast.error('Error updating profile');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='bg-[#f4f4f4] w-[100%] h-[100%] p-6 '>
            <div className='bg-white rounded-[8px] p-6'>

                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Welcome to FCPI Dashboard</p>


                <p className='text-[color:var(--Gray,#58585A)] text-[16px] pt-1 not-italic font-normal leading-[24px]'>Your FCPI Dashboard for today</p>
                <hr className='mt-6' />
                <div className='flex items-center bg-gry mm-block'>
                    <motion.div
                        className="relative"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 4 }}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                        }}
                        ref={ref}
                    >

                        <div className='user-img'>
                            {profile?.image ? (
                                <img src={BASE_URL + profile?.image} alt="User Image" />
                            ) : (
                                <img src={pro} alt="Default Icon" className='w-[40%]' />
                            )}
                        </div>
                        <div className='  pt-3     '>
                            <button className="text-white bg-[#00549A] rounded-[6px] px-11  p-3 text-center text-[20px] not-italic font-semibold leading-[normal]" onClick={openFormPopup}>Edit Profile</button>
                        </div>
                        {/* <img src={edit} alt="" className='absolute right-444 bottom-0 z-10 cursor-pointer' onClick={openFormPopup} /> */}
                    </motion.div>
                    {showFormPopup && !formSubmitted && (

                        <motion.div
                            className="form-popup mt-50 bg-black bg-opacity-50"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 4 }}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                            <div className='form-content'>
                                <div className='modal-content'>
                                    <button className='bg-[#00549A] text-white close-btn font-semibold p-3 rounded-[10px]' onClick={closeFormPopup}>Close</button>
                                    <div className=''>


                                        <div className='profile-section'>
                                            {selectedImage ? (
                                                <img src={selectedImage} className='pro-img-edit' alt="User Image" />
                                            ) : (
                                                profile?.image ? (
                                                    <img src={BASE_URL + profile?.image} className='pro-img-edit' alt="User Image" />
                                                ) : (
                                                    <img src={pro} alt="Default Icon" className='w-[40%]' />
                                                )
                                            )}
                                        </div>

                                        <div className="pt-1">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="input-file bg-[#F4F4F4]"
                                            />
                                        </div>


                                    </div>

                                    <div className='grid grid-cols-1 md:grid-cols-2 pt-4 w-full'>
                                        <div className=''>
                                            <div className=''>

                                                <div className="text-start">
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>First Name</p>
                                                    <div className="pt-2">
                                                        <input
                                                            type="text"
                                                            name="first_name"
                                                            value={formData.first_name}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="First Name"
                                                        />


                                                    </div>
                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Date of Birth</p>
                                                    <div className="pt-2">
                                                        <input
                                                            type="date"
                                                            name="date_of_birth"
                                                            value={formData.date_of_birth}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="Date of Birth"
                                                        />


                                                    </div>

                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>State</p>
                                                    <div className=" pt-2">
                                                        <input
                                                            type="text"
                                                            name="state"
                                                            value={formData.state}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="state"
                                                        />
                                                    </div>



                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Primary Pharmacy Degree</p>
                                                    <div className="relative pt-2">
                                                        <select
                                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            name="primary_pharmacy_degree"
                                                            defaultValue={formData.primary_pharmacy_degree}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="" disabled>Select Primary Pharmacy Degree</option>
                                                            <option value="Bachelors in Pharmacy">Bachelors in Pharmacy</option>
                                                            <option value="Doctor of Pharmacy">Doctor of Pharmacy</option>

                                                        </select>

                                                    </div>

                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Secondary Pharmacy Degree</p>
                                                    <div className="relative pt-2">
                                                        <select
                                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            name="secondary_pharmacy_degree"
                                                            value={formData.secondary_pharmacy_degree}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="" disabled>Select secondaryPharmacyDegree</option>
                                                            <option value="Doctor of Pharmacy Post Baccalaureate">Doctor of Pharmacy Post Baccalaureate</option>
                                                            <option value="Masters in Pharmacy">Masters in Pharmacy</option>

                                                        </select>

                                                    </div>
                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Additional Degrees</p>
                                                    <div className="pt-2">
                                                        <input
                                                            type="text"
                                                            name="additional_degrees"
                                                            value={formData.additional_degrees}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="additionalDegrees"
                                                        />
                                                    </div>

                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>current Work institution</p>
                                                    <div className="relative pt-2">
                                                        <input
                                                            type="text"
                                                            name="current_work_institution"
                                                            value={formData.current_work_institution}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="current_work_institution"
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='pl-8'>
                                            <div className=''>

                                                <div className="text-start">
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Last Name</p>
                                                    <div className="pt-2">

                                                        <input
                                                            type="text"
                                                            name="last_name"  // Make sure the name attribute matches the key in formData
                                                            value={formData.last_name}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="last_name"
                                                        />

                                                    </div>

                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>Primary Position</p>
                                                    <div className="pt-2 relative">
                                                        <div className="relative">
                                                            <select
                                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                name="primary_position"
                                                                value={formData.primary_position}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="" disabled selected>Select Primary Pharmacy Degree</option>
                                                                <option value="student">student</option>
                                                                <option value="clinical pharmacist">clinical pharmacist</option>
                                                                <option value="pharmacist">pharmacist</option>
                                                                <option value="faculty">faculty</option>

                                                                {/* Add more options as needed */}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>City</p>
                                                    <div className="relative pt-2">
                                                        <input
                                                            type="text"
                                                            name="city"
                                                            value={formData.city}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="city"
                                                        />
                                                    </div>




                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Country</p>
                                                    <div className="relative pt-2">
                                                        <input
                                                            type="text"
                                                            name="country"
                                                            value={formData.country}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="country"
                                                        />
                                                    </div>
                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>pincode</p>
                                                    <div className="relative pt-2">
                                                        <input
                                                            type="text"
                                                            name="pincode"
                                                            value={formData.pincode}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="pincode"
                                                        />
                                                    </div>

                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Name of Pharmacy College</p>
                                                    <div className="pt-2 relative">
                                                        <div className="relative">
                                                            <input
                                                                type="text"
                                                                name="pharmacy_college_name"
                                                                value={formData.pharmacy_college_name}
                                                                onChange={handleInputChange}
                                                                className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                placeholder="pharmacyCollegeName"
                                                            />
                                                        </div>

                                                    </div>
                                                    <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Name of  Secondary College </p>
                                                    <div className=" relative pt-2">

                                                        <input
                                                            type="text"
                                                            name="pharmacy_college_degree"
                                                            value={formData.pharmacy_college_degree}
                                                            onChange={handleInputChange}
                                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                            placeholder="pharmacyCollegeDegree"
                                                        />
                                                    </div>




                                                </div>

                                            </div>




                                        </div>
                                    </div>

                                    <div className='pt-8 flex'>
                                        <button type="submit" onClick={handleSubmit} className='profile-edit-btn '>
                                            <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>
                                                Submit
                                            </p>
                                        </button>
                                    </div>


                                </div>
                            </div>

                        </motion.div>
                    )}

                    <div className='w-[100%] pl-8 rounded-[8px] rounded-l-none p-pro-8-all mob-l-p-30'>
                        <div>
                            <div className='flex pt-3 gap-8'>
                                <p className='text-[color:var(--Black,#222)] text-[20px] not-italic font-semibold leading-[normal]'>{user?.first_name} {user?.last_name}</p>
                                {/* <button className='bg-[#EAFCEC] border border-[#64A15E] px-3 rounded-[30px]'>
                                            <p className='text-[#64A15E] text-[14px] not-italic font-semibold leading-[17px]'>Active</p>
                                        </button> */}
                            </div>
                        </div>
                        <div className='pt-2'>
                            <div className='profile-detailbox '>
                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Date of Birth: <span className='font-semibold'>{profile?.date_of_birth}</span></p>

                            </div>
                        </div>
                        <div className='flex'>
                            <div>
                                <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>Email: </p>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-normal leading-[normal]'>{user?.email} </p>
                            </div>
                            <div className='pl-6'>
                                <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>Phone:</p>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-normal leading-[normal]'>{user?.phone}</p>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='flex pt-6 gap-6 pro-mobile-block '>
                    <div className=' w-[50%]  p-6  bg-white shadow-equal h-[568px] rounded-[8px] pt-6 w-full-desk '>
                        <div className='flex justify-between mm-block'>
                            <p className='text-[color:var(--Black,#222)] text-[27px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Events</p>
                            <div className='mm-mt-15'>
                                <button className={`relative p-3 px-4 rounded-[4px] smooth ${PastActive ? 'bg-[#00549A]' : 'bg-gray-100'} hover:bg-[#00549a]`} onClick={togglePast}>
                                    <div className='flex gap-3'>
                                        <p className={`text-center text-[18px] not-italic font-normal leading-[28px] ${PastActive ? 'text-white' : 'text-gray-600 hover:text-white'}`}>
                                            Past <span className='mob-s-none'>Event</span>
                                        </p>
                                    </div>
                                </button>

                                <button className={`relative p-3 px-4 rounded-[4px] smooth ${liveActive ? ' bg-[#00549A]' : 'bg-gray-100'} hover:bg-[#00549a]`} onClick={toggleLive}>
                                    <div className='flex gap-3'>

                                        <p className={`text-center text-[18px] not-italic font-normal leading-[28px] ${liveActive ? 'text-white' : 'text-gray-600 hover:text-white'}`}>Live <span className='mob-s-none'>Event</span> </p>

                                    </div>
                                </button>
                                <button className={`relative p-3 px-4 rounded-[4px] smooth ${upcomingActive ? ' bg-[#00549A]' : 'bg-gray-100'} hover:bg-[#00549a]`} onClick={toggleUpcoming}>
                                    <div className='flex gap-3'>

                                        <p className={`text-center text-[18px] not-italic font-normal leading-[28px] ${upcomingActive ? 'text-white' : 'text-gray-600 hover:text-white'}`}>Upcoming <span className='mob-s-none'>Event</span></p>

                                    </div>
                                </button>

                            </div>


                        </div>


                        {PastActive && (

                            <motion.div
                                className="dropdown-content mt-4 max-h-[45vh] overflow-y-auto"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 4 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                                ref={ref}
                            >
                                {pastEvents.map(event => (
                                    <div key={event.id}>
                                        <Link to={`/events-detail/${event.slug}/single-events/`}>
                                            <div className='flex pt-6'>
                                                <div className='w-[125px]'>
                                                    <img src={BASE_URL + event.banner} alt="" className='rounded-[8px] w-[100%]' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-semibold leading-[normal]'>{event.event_name}</p>
                                                    <p className='text-[color:var(--Green,#1AB78D)] pt-2 text-[19px] not-italic font-medium leading-[20px]'>{event.forum_name}</p>
                                                    {formatDate(event.date)}
                                                    {/* {event.single_events && event.single_events.map(singleEvent => (
                                                    <div key={singleEvent.id}>

                                                        {singleEvent.multi_events && singleEvent.multi_events.map(multiEvent => (
                                                            <p key={multiEvent.id} className='text-[color:var(--Gray,#58585A)] pt-1 text-[17px] not-italic font-normal leading-[20px]'>

                                                                {`${formatTime(multiEvent.starting_time)} AM - ${formatTime(multiEvent.ending_time)}PM`}
                                                            </p>
                                                        ))}
                                                    </div>
                                                ))} */}
                                                </div>

                                            </div>
                                        </Link>
                                        <hr className='mt-6' />
                                    </div>
                                ))}




                                <div className='text-end pt-4'>
                                    <button className='bg-gray-100 bg-blue px-4 py-2 rounded-[4px]'>
                                        <p className='text-[color:var(--Blue,#00549A)] text-[18px] not-italic font-bolder leading-[normal]'>View all</p>
                                    </button>
                                </div>
                            </motion.div>
                        )}
                        {liveActive && (
                            <motion.div
                                className="dropdown-content mt-4 max-h-[45vh] overflow-y-auto"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 4 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                                ref={ref}
                            >
                                {liveEvents.map(event => (
                                    <div key={event.id}>
                                        <Link to={`/events-detail/${event.slug}/single-events/`}>
                                            <div className='flex pt-6'>
                                                <div className='w-[125px]'>
                                                    <img src={BASE_URL + event.banner} alt="" className='rounded-[8px] w-[100%]' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-semibold leading-[normal]'>{event.event_name}</p>
                                                    <p className='text-[color:var(--Green,#1AB78D)] pt-2 text-[19px] not-italic font-medium leading-[20px]'>{event.forum_name}</p>
                                                    {formatDate(event.date)}
                                                    {/* {event.single_events && event.single_events.map(singleEvent => (
                                                    <div key={singleEvent.id}>

                                                        {singleEvent.multi_events && singleEvent.multi_events.map(multiEvent => (
                                                            <p key={multiEvent.id} className='text-[color:var(--Gray,#58585A)] pt-1 text-[17px] not-italic font-normal leading-[20px]'>

                                                                {`${formatTime(multiEvent.starting_time)} AM - ${formatTime(multiEvent.ending_time)}PM`}
                                                            </p>
                                                        ))}
                                                    </div>
                                                ))} */}
                                                </div>
                                            </div>
                                        </Link>
                                        <hr className='mt-6' />
                                    </div>
                                ))}

                                <div className='text-end pt-4'>
                                    <button className='bg-gray-100 px-4 py-2 bg-blue rounded-[4px]'>
                                        <p className='text-[color:var(--Blue,#00549A)] text-[18px] not-italic font-medium leading-[normal]'>View all</p>
                                    </button>
                                </div>
                            </motion.div>
                        )}
                        {upcomingActive && (
                            <motion.div
                                className="dropdown-content mt-4 max-h-[45vh] overflow-y-auto"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 4 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                                ref={ref}
                            >
                                {upcomingEvents.map(event => (
                                    <div key={event.id}>
                                        <Link to={`/events-detail/${event.slug}/single-events/`}>
                                            <div className='flex pt-6'>
                                                <div className='w-[125px]'>
                                                    <img src={BASE_URL + event.banner} alt="" className='rounded-[8px] w-[100%]' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-semibold leading-[normal]'>{event.event_name}</p>
                                                    <p className='text-[color:var(--Green,#1AB78D)] pt-2 text-[19px] not-italic font-medium leading-[20px]'>{event.forum_name}</p>
                                                    {formatDate(event.date)}
                                                    {/* {event.single_events && event.single_events.map(singleEvent => (
                                                    <div key={singleEvent.id}>

                                                        {singleEvent.multi_events && singleEvent.multi_events.map(multiEvent => (
                                                            <p key={multiEvent.id} className='text-[color:var(--Gray,#58585A)] pt-1 text-[17px] not-italic font-normal leading-[20px]'>

                                                                {`${formatTime(multiEvent.starting_time)} AM - ${formatTime(multiEvent.ending_time)}PM`}
                                                            </p>
                                                        ))}
                                                    </div>
                                                ))} */}
                                                </div>
                                            </div>
                                        </Link>
                                        <hr className='mt-6' />
                                    </div>
                                ))}
                                <div className='text-end pt-4'>
                                    <button className='bg-gray-100 px-4 bg-blue py-2 rounded-[4px]'>
                                        <p className='text-[color:var(--Blue,#00549A)] text-[18px] not-italic font-medium leading-[normal]'>View all</p>
                                    </button>
                                </div>
                            </motion.div>
                        )}
                        <div className='flex pt-6'>


                        </div>


                    </div>
                    {/*  */}

                    <div className='w-[50%] desktop-full-width'>
                        <div className='pad-6 pad-6-mob '>
                            <p className='text-[#222] text-start text-[32px] not-italic font-semibold leading-[normal] ' >Event Calendar
                            </p>
                            <div className='pt-6'>

                                <div className='border border-[#CBCBCB] rounded-[12px] pall pall-12'>
                                    <div className=' pb-5  '>
                                        <p className='text-[#222] text-start text-[23px]  not-italic font-semibold leading-[normal]'  >Events</p>
                                        <div className='flex env-block events-items-date pt-4'>
                                            <div className='evn-item'>
                                                <button
                                                    className={`border border-[#CBCBCB] smooth rounded-[102px] pxx-44 py-1 ${activeButton1 === 'today' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                    onClick={() => toggleDropdown1('today')}>
                                                    <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'today' ? 'text-[#1AB78D]' : ''}`}>Today</p>
                                                </button>

                                                <button
                                                    className={`border border-[#CBCBCB] smooth rounded-[102px] pxx-44 py-1 ${activeButton1 === 'thisWeek' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                    onClick={() => toggleDropdown1('thisWeek')}>
                                                    <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisWeek' ? 'text-[#1AB78D]' : ''}`}>This Week</p>
                                                </button>
                                            </div>

                                            <div className='evn-item'>

                                                <button
                                                    className={`border border-[#CBCBCB] rounded-[102px] smooth pxx-44 py-1 ${activeButton1 === 'thisMonth' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                    onClick={() => toggleDropdown1('thisMonth')}>
                                                    <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisMonth' ? 'text-[#1AB78D]' : ''}`}>This Month</p>
                                                </button>
                                                <button
                                                    className={`border border-[#CBCBCB] rounded-[102px] smooth pxx-44 py-1 ${activeButton1 === 'thisYear' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                    onClick={() => toggleDropdown1('thisYear')}>
                                                    <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisYear' ? 'text-[#1AB78D]' : ''}`}>This Year</p>
                                                </button>
                                            </div>
                                        </div>


                                    </div>

                                    <hr className='w-full mt-2' />


                                    {dropdownVisible.today && (


                                        <motion.div
                                            className="max-h-[49vh] overflow-y-auto p-l-10"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <div className='   mt-4 eventitems'>
                                                {eventstoday.length > 0 ? (
                                                    eventstoday.map(event => (
                                                        <Link to={`/events-detail/${event.slug}/`}>
                                                            <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                <div className='wh-70'>
                                                                    <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                        <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                            {new Date(event.date).getDate()}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                            {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                    <div className='eventcontent'>
                                                                        <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                            {event.event_name}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                            {event.forum_name}
                                                                        </p>
                                                                    </div>
                                                                    <div className='pt-2'>
                                                                        <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                            <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                {formatDate(event.date)}
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Link>

                                                    ))
                                                ) : (
                                                    <p>No events today</p>
                                                )}

                                            </div>

                                        </motion.div>
                                    )}


                                    {dropdownVisible.thisWeek && (


                                        <motion.div
                                            className="max-h-[49vh] overflow-y-auto p-l-10"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <div className='   mt-4 eventitems'>
                                                {eventsweek.length > 0 ? (

                                                    eventsweek.map(event => (
                                                        <Link to={`/events-detail/${event.slug}/`}>
                                                            <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                <div className='wh-70'>
                                                                    <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                        <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                            {new Date(event.date).getDate()}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                            {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                    <div className='eventcontent'>
                                                                        <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                            {event.event_name}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                            {event.forum_name}
                                                                        </p>
                                                                    </div>
                                                                    <div className='pt-2'>
                                                                        <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                            <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                {formatDate(event.date)}
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Link>

                                                    ))
                                                ) : (
                                                    <p>No events this week</p>
                                                )}

                                            </div>

                                        </motion.div>
                                    )}
                                    {dropdownVisible.thisMonth && (

                                        <motion.div
                                            className="max-h-[49vh] overflow-y-auto p-l-10"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <div className='   mt-4 eventitems'>
                                                {eventmonth.length > 0 ? (

                                                    eventmonth.map(event => (
                                                        <Link to={`/events-detail/${event.slug}/`}>
                                                            <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                <div className='wh-70'>
                                                                    <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                        <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                            {new Date(event.date).getDate()}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                            {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                    <div className='eventcontent'>
                                                                        <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                            {event.event_name}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                            {event.forum_name}
                                                                        </p>
                                                                    </div>
                                                                    <div className='pt-2'>
                                                                        <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                            <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                {formatDate(event.date)}
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Link>

                                                    ))
                                                ) : (
                                                    <p>No events this month</p>
                                                )}

                                            </div>

                                        </motion.div>
                                    )}
                                    {dropdownVisible.thisYear && (

                                        <motion.div
                                            className="max-h-[49vh] overflow-y-auto p-l-10"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <div className='   mt-4 eventitems'>
                                                {eventyear.length > 0 ? (

                                                    eventyear.map(event => (
                                                        <Link to={`/events-detail/${event.slug}/`}>
                                                            <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                <div className='wh-70'>
                                                                    <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                        <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                            {new Date(event.date).getDate()}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                            {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                    <div className='eventcontent'>
                                                                        <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                            {event.event_name}
                                                                        </p>
                                                                        <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                            {event.forum_name}
                                                                        </p>
                                                                    </div>
                                                                    <div className='pt-2'>
                                                                        <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                            <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                {formatDate(event.date)}
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Link>

                                                    ))
                                                ) : (
                                                    <p>No events this year</p>
                                                )}

                                            </div>

                                        </motion.div>
                                    )}
                                </div>

                            </div>





                        </div>
                    </div>

                    {/*  */}


                </div>






                <div className='pt-6'>
                    <div className='bg-white shadow-equal rounded-[8px] w-[100%] p-6'>

                        <div className='flex justify-between'>
                            <div>
                                <button className='bg-gray-100 rounded-[4px] rounded-b-none px-8 py-2'>
                                    <p className='text-[color:var(--Black,#222)] text-[21px] not-italic font-medium leading-[25px] tracking-[-0.105px]'>Personal Information</p>
                                </button>
                            </div>
                            <div className={`pt-2 smooth ${isRotated1 ? '' : 'rotate-icon'}`} onClick={handleIconClick1}>
                                <img src={logo5} alt="" />
                            </div>

                        </div>
                        <hr />
                        {isRotated1 && (
                         
                                <motion.div
                                    className="flex pt-8   mm-t-0"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    {/* Dropdown content */}
                                    <div className='w-full'>

                                        <div className='flex pt-8 mm-t-0'>
                                            {/* Dropdown content */}
                                            <div className='w-full'>


                                                <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>
                                                    <div className='profile-detailbox' >
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>First Name </p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{user?.first_name}</p>
                                                    </div>
                                                    <div className='profile-detailbox '>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Last Name</p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'> {user?.last_name}</p>
                                                    </div>
                                                    <div className='profile-detailbox '>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Date of Birth:</p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>  {profile?.date_of_birth}</p>
                                                    </div>
                                                    <div className='profile-detailbox '>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Primary Position: </p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.primary_position}</p>
                                                    </div>

                                                </div>

                                                <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>

                                                    <div className='profile-detailbox'>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'> Current Work Institution Name: </p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.current_work_institution}</p>
                                                    </div>
                                                    <div className='profile-detailbox '>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'> City </p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.city}</p>
                                                    </div>
                                                    <div className='profile-detailbox '>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px]  not-italic font-normal leading-[22px] tracking-[-0.07px]'> State</p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.state}</p>
                                                    </div>
                                                    <div className='profile-detailbox '>
                                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'> Country</p>
                                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.country}</p>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>


                                        <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>
                                            <div className='profile-detailbox '>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Primary Pharmacy Degree:</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.primary_pharmacy_degree}</p>
                                            </div>
                                            <div className='profile-detailbox '>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Name of Pharmacy College:
                                                </p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'> {profile?.pharmacy_college_name}</p>
                                            </div>
                                            <div className='profile-detailbox '>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Secondary Pharmacy Degree</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'> {profile?.secondary_pharmacy_degree}</p>
                                            </div>


                                        </div>
                                        <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>

                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px]  not-italic font-normal leading-[22px] tracking-[-0.07px]'>Name of Secondary College </p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.pharmacy_college_degree}</p>
                                            </div>

                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Additional Degrees: </p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.additional_degrees}</p>
                                            </div>
                                        </div>
                                    </div>
                                    </motion.div>
                        )}

                    </div>
                </div>






                <div className='pt-6'>
                    <div className='bg-white shadow-equal rounded-[8px] w-[100%] p-6'>

                        <div className='flex justify-between'>
                            <div>
                                <button className='bg-gray-100 rounded-[4px] rounded-b-none px-5 py-2'>
                                    <p className='text-[color:var(--Black,#222)] text-[21px] not-italic font-medium leading-[25px] tracking-[-0.105px]'>Mailing Address</p>
                                </button>
                            </div>
                            <div className={`pt-2 ${isRotated ? '' : 'rotate-icon'}`} onClick={handleIconClick}>
                                <img src={logo5} alt="" />
                            </div>

                        </div>
                        <hr />

                        {isRotated && (
                           
                              <motion.div
                                    className=" "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                <div className='flex flex-wrap pt-8'>
                                    <div className='w-full'>
                                        <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  '>
                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>City</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.city}</p>
                                            </div>
                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>State </p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.state}</p>
                                            </div>
                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Pincode</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.pincode}</p>
                                            </div>
                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Country</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.country}</p>
                                            </div>
                                        </div>

                                        <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>
                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Email</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{user?.email}</p>
                                            </div>
                                            <div className='profile-detailbox'>
                                                <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Phone Number</p>
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{user?.phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                </motion.div>
                        )}



                    </div>
                </div>


            </div>
        </div>
    );
};

export default UserDashboard;
