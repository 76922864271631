import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import axios from "axios";
import parse from 'html-react-parser';
import cap from "../../../assets/images/lineee.svg";
import blog1 from "../../../assets/images/Blogs.jpg";
import arrow from "../../../assets/images/arrow-right-01-round.png";
import { motion, useAnimation, useInView } from 'framer-motion';

const BlogDetail = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isGeneralBlog, setIsGeneralBlog] = useState(false);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                // First, try to fetch general blog details
                let response = await axios.get(`${BASE_URL}/admins/general-blogs-detail/${slug}/`);
                setIsGeneralBlog(true);
                setBlog(response.data);
                setLoading(false);
            } catch (error) {
                // If general blog not found, fetch normal blog details
                try {
                    let response = await axios.get(`${BASE_URL}/admins/blogs-detail/${slug}/`);
                    setIsGeneralBlog(false);
                    setBlog(response.data);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching blog details:", error);
                    setLoading(false);
                }
            }
        };

        fetchBlogDetails();
    }, [slug]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const sanitizeAndParseHtml = (html) => {
        return parse(html, {
            replace: (domNode) => {
                if (domNode.name === 'a') {
                    return (
                        <a
                            href={domNode.attribs.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="custom-link"
                            key={domNode.attribs.href}
                        >
                            {domNode.children[0]?.data || 'Link'}
                        </a>
                    );
                }
                if (domNode.name === 'strong') {
                    return <strong key="strong">{domNode.children[0]?.data}</strong>;
                }
                if (domNode.name === 'em') {
                    return <em key="em">{domNode.children[0]?.data}</em>;
                }
                if (domNode.name === 'h1') {
                    return <h1 key="h1">{domNode.children[0]?.data}</h1>;
                }
                if (domNode.name === 'h2') {
                    return <h2 key="h2">{domNode.children[0]?.data}</h2>;
                }
                if (domNode.name === 'p') {
                    return (
                        <p key="p">
                            {domNode.children.map((child, i) =>
                                child.type === 'text' ? child.data : parse(child)
                            )}
                        </p>
                    );
                }
                if (domNode.name === 'br') {
                    return <br key="br" />;
                }
                return null;
            },
        });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!blog) {
        return <p>No blog data available.</p>;
    }

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px]">
                        <div className="grid grid-cols-1 md:grid-cols-2 bg-[#00549A] rounded-t-[30px] relative overflow-hidden brb-30 ptb-30">
                            <div>
                                <p className="text-white text-[32px] not-italic font-semibold leading-[normal] pl-50">Blogs</p>
                                <motion.div
                                    className="blogimg "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <img src={blog1} alt="Left Arrow" className="w-full pt-12 rounded-tr-[30px]" />
                                </motion.div>
                            </div>
                            <div className="start position-relative z-10 pl-30">
                                <p className="text-[#FFC233] text-[23px] pt-28 not-italic font-medium leading-[normal]">FEATURED</p>
                                <p className="text-white text-[42px] pt-4 not-italic font-normal leading-[51px]">Looking back at <br />2023</p>
                                <div className="pt-4">
                                    <button className="bg-white px-[32px] py-2 rounded-[30px]">
                                        <div className="flex gap-4">
                                            <p className="text-[#00549A] text-[16px] not-italic font-semibold leading-[normal]">Continue reading</p>
                                            <img src={arrow} alt="" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="bg-line">
                                <img src={cap} alt="Left Arrow" className="w-full pt-12 rounded-tr-[30px]" />
                            </div>
                        </div>

                        <div className="flex blog-details p-12">
                            <div className="blog-left">
                                <div>
                                    <p className="text-[#222] text-[21px] not-italic pt-2 font-medium leading-[normal]">Author</p>
                                    <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">{blog.author || 'Unknown'}</p>
                                    {blog.author_profile && <img className="blogautor-img" src={BASE_URL + blog.author_profile} alt="Author Profile" />}
                                </div>

                                <p className="text-[#222] text-[21px] not-italic pt-8 font-medium leading-[normal]">Date</p>
                                <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">{formatDate(blog.date)}</p>
                                <p className="text-[#222] text-[21px] not-italic pt-8 font-medium leading-[normal]">Forum Name</p>
                                <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">{blog.forum_title}</p>
                                {/* Add other fields as needed */}
                            </div>
                            <div className="blog-right">
                                <p className="text-[#222] text-[36px] not-italic font-medium leading-[normal]">{blog.title}</p>
                                <div className="pt-12">
                                    {blog.blog_contents && blog.blog_contents.map((content, index) => (
                                        <div key={index}>
                                            <p className="text-[#00549A] m-200 text-[25px] not-italic font-normal leading-[28px]">{content.topic}</p>
                                            <p className="text-[#58585A] pt-4 text-[18px] not-italic font-normal leading-[28px] blog-content">
                                                {sanitizeAndParseHtml(content.description)}
                                            </p>
                                            <motion.div
                                                className="  "
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 2,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                {content.image && <img className="blog-mainimg" src={BASE_URL + content.image} alt="Blog Content" />}
                                                {/* Render any other content fields */}
                                            </motion.div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetail;
