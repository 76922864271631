import React, { useState, useEffect, useRef } from "react";
import Logoa from "../../../src/assets/images/news1.jpg";
import Logob from "../../../src/assets/images/news2.jpg";
import rightArrowImage from "../../../src/assets/images/Frame 13333.svg";
import leftArrowImage from "../../../src/assets/images/Frame 113.svg";
import { Carousel } from 'flowbite-react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './Card7.css';

const Card7 = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">

                <div className="pt-6">
                    <div className="grid grid-cols-1 lg:grid-cols-2 bg-white rounded-[30px]">
                        <motion.div
                            className=""
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 1 }}
                            ref={ref}
                        >
                            <div className="text-start p-8 pl-12">
                                <p className="text-[#222] pt-12 m-pt-12 text-[32px] not-italic font-bold leading-[normal]" >Newsletters</p>
                                <p className="text-[#58585A] pt-8 m-pt-8 text-[18px] not-italic font-normal newsletter-text"  >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less   normal distribution of letters, as opposed to using</p>
                            </div>
                        </motion.div>
                     
                            <motion.div
                                className="p-8 rounded-[30px]"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 4 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                                ref={ref}
                            >
                                <Carousel indicators={false} className="h-[340px] rounded-[30px] ">

                                    <img src={Logoa} alt="Logo16" className="rounded-[30px]" />
                                    <img src={Logob} alt="Logo17" className="rounded-[30px]" />
                                </Carousel>
                            </motion.div>
                       
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Card7;


