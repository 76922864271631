import React, { useState, useEffect } from 'react';
import blue from "../../../assets/images/blue.png";
import "./Event.css";
import { BASE_URL } from '../../../Utils/Config';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Card2 = () => {
    const { slug } = useParams();
    console.log("slug", slug)
    const [event, setEvent] = useState(null);
    const [activeDay, setActiveDay] = useState(null);
    const [showDropdown, setShowDropdown] = useState(true);

    const handleDayClick = (day) => {
        setShowDropdown(activeDay !== day || !showDropdown);
        setActiveDay(day);
    };

    useEffect(() => {
        const fetchSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/`);
                setEvent(response.data);


                if (response.data && response.data.single_events && response.data.single_events.length > 0) {
                    setActiveDay(response.data.single_events[0].day);
                }

                console.log(response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvent();
    }, [slug]);

    useEffect(() => {
        const fetchGeneralSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_events/${slug}/`);
                setEvent(response.data);


                if (response.data && response.data.single_events && response.data.single_events.length > 0) {
                    setActiveDay(response.data.single_events[0].day);
                }

                console.log(response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchGeneralSingleEvent();
    }, [slug]);

    const formatTime = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        const formattedHours = parseInt(hours) > 12 ? parseInt(hours) - 12 : parseInt(hours);
        return `${formattedHours}:${minutes} ${parseInt(hours) >= 12 ? 'PM' : 'AM'}`;
    };


    return (
        <div className="w-full h-full bg-[#f4f4f4] mmb-20 ">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] '>
                        <div className='p-10 pl-12'>
                            <p className='text-[#222] text-[32px] not-italic font-semibold leading-[normal]'>Program Schedule</p>
                            <div className='flex gap-20 pt-8'>
                                {event && event.single_events && event.single_events.map((singleEvent, index) => (
                                    <button
                                        key={index}
                                        className={`bg-white py-2 px-10 ${activeDay === singleEvent.day ? 'border-b-2 border-[#00549A] text-blue-900' : 'text-custom-color'}`}
                                        onClick={() => handleDayClick(singleEvent.day)}
                                    >
                                        <p className={`text-center text-[23px] not-italic font-semibold leading-[21px] tracking-[0.25px] ${activeDay === singleEvent.day ? 'text-blue-900' : 'text-custom-color'}`}>{`Day - ${singleEvent.day}`}</p>
                                    </button>
                                ))}

                            </div>
                            {showDropdown && (
                                <div className='flex gap-10 pr-4 pt-8 mob-block'>
                                    <div className='bg-[#F4F4F4] rounded-[10px] w-[75%] mob-fullwidth p-6' style={{ backgroundColor: '#F4F4F4' }}>
                                        {event && event.single_events && (
                                            event.single_events
                                                .filter(singleEvent => singleEvent.day === activeDay)
                                                .map((singleEvent, index) => (

                                                    <div key={index} className=" ">
                                                        <div className="flex">
                                                            <p className="text-black pt-1 text-[17px] not-italic font-normal leading-[normal]">
                                                                {singleEvent.date}
                                                            </p>
                                                        </div>
                                                        {singleEvent.multi_events.map((multiEvent, innerIndex) => (
                                                            <div className='bg-white rounded-[10px] p-4 mt-3 mb-3'>
                                                                <div key={innerIndex} className="">
                                                                    <p className="text-[#58585A] pt-1 text-[17px] not-italic font-normal leading-[normal]">
                                                                        {`${formatTime(multiEvent.starting_time)} - ${formatTime(multiEvent.ending_time)}`}
                                                                    </p>

                                                                    <p className="text-[#58585A]   pt-1 text-[17px] not-italic font-normal leading-[normal]">
                                                                        {multiEvent.topics}
                                                                    </p>
                                                                </div>

                                                                <p className="text-[#00549A]   pt-1 text-[20px] not-italic font-medium leading-[normal]">
                                                                    {multiEvent.speaker_name}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>

                                                ))
                                        )}
                                    </div>
                                    <div className='bg-[#F4F4F4] rounded-[10px] w-[50%] mob-fullwidth mmt-20' style={{ backgroundColor: '#F4F4F4' }}>
                                        <div className='p-7 pl-10'>
                                            <p className='text-[#222] text-[25px] not-italic font-semibold leading-[normal]'>Highlights</p>
                                            <div className='pt-6'>
                                                {event &&
                                                    event.single_events &&
                                                    event.single_events
                                                        .filter(singleEvent => singleEvent.day === activeDay)
                                                        .map((singleEvent, index) => (
                                                            <div key={index}>
                                                                {Array.isArray(singleEvent.highlights) ? (
                                                                    <div>
                                                                        {singleEvent.highlights.map((highlight, innerIndex) => (
                                                                            <div key={innerIndex} className='text-black pt-1 text-[17px] not-italic font-normal leading-[normal]'>
                                                                                <div className='flex items-center gap-2 pt-2'>
                                                                                    <img src={blue} alt="" />

                                                                                    {highlight.split(',').map((word, wordIndex) => (
                                                                                        <p key={wordIndex} className="m-0">{word}</p>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ) : (
                                                                    <div className='text-black pt-1 text-[17px] not-italic font-normal leading-[normal]'>

                                                                        {singleEvent.highlights.split(',').map((part, innerIndex) => (
                                                                            <div key={innerIndex} className='flex items-center gap-4'>
                                                                                <img src={blue} alt="" />

                                                                                <p className="m-0">{part.trim()}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                            </div>








                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card2;
