import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Utils/Config';
import Logo23 from '../../../src/assets/images/Mask group.svg';
import { motion, useAnimation, useInView } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { scroller } from 'react-scroll';
import './Card5.css';

const Card5 = () => {
  const [users, setUsers] = useState([]);
  const [animatedCount, setAnimatedCount] = useState(0);
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false }); // Update to false to track multiple view events

  // Fetch users and update state
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/accounts/userlist/`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Start counting animation
  const startCounting = () => {
    let start = 0;
    const end = users.length;
    const duration = 1;  // Duration in seconds
    const increment = end / (duration * 30);  // Increment per frame

    const interval = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(interval);
        setAnimatedCount(end);
      } else {
        setAnimatedCount(Math.floor(start));
      }
    }, 1000 / 60); // Update every frame (60 FPS)
  };

  // Handle view changes and counting animation
  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
      startCounting(); // Restart counting animation when in view
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isInView, controls, users.length]);

  // Scroll to a specific section
  const handleScroll = () => {
    scroller.scrollTo('cardSection', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className="w-full h-full bg-[#f4f4f4]">
      <div className="container">
        <div className="pt-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className='bg-white rounded-[30px] lg:col-span-2'>
            <motion.div
              className=""
              initial={{ opacity: 0, y: 50 }}
              animate={controls}
              transition={{ duration: 1 }}
              ref={ref}
            >
              <div className="p-10 pl-12 p-m-10">
                <div className="flex justify-between items-center">
                  <p className="text-black text-start text-[32px] not-italic font-bold leading-[normal]">
                    Speciality Forums
                  </p>
                  <div className="pr-4">
                    <button
                      className="inline-flex justify-center items-center gap-[10px] shrink-0 px-[32px] py-[14px] rounded-[105px] bg-[#00549a] hidemob"
                      onClick={handleScroll}
                    >
                      <p className="text-white text-[14px] not-italic font-semibold leading-[normal]">
                        View all
                      </p>
                    </button>
                  </div>
                </div>

                <p className="text-[#58585A] text-[18px] speciality-text text-start not-italic font-normal">
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using
                </p>
              </div>
            </motion.div>
            </div>

            <motion.div
              className="bg-[#00549A] pall-member rounded-[30px] relative overhiden"
              initial={{ opacity: 0, y: 50 }}
              animate={controls}
              transition={{ duration: 1 }} 
              ref={ref}
            >
              <div className="flex items-center space-x-2">
                <p className="text-white text-start pleft-14 pttop-10 text-[67px] not-italic font-medium leading-[normal]">
                  {animatedCount}
                </p>
                <FontAwesomeIcon icon={faPlus} className="text-white text-[40px]" /> {/* Plus icon */}
              </div>
              <div className="flex text-star pleft-14 justify-start">
                <p className="text-white text-[17px] not-italic font-normal leading-[normal]">Members </p>
                <p className="text-[#78B6E9] text-[17px] plleft-5 not-italic font-normal leading-[normal]">use our</p>
                <img src={Logo23} alt="Image" className="absolute right-0 top-30 transform -translate-y-1/2" />
              </div>
              <p className="text-[#78B6E9] pleft-14 relative z-10 text-start text-[17px] not-italic font-normal leading-[normal] pbm-20">
                educational programs
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card5;
