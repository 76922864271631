import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import imag from "../../../assets/images/user-circle.png";
import imag1 from "../../../assets/images/mail.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles


const Gallerys = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setMailingAddressActive] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        images: [null]
    });
    const [imagePreviews, setImagePreviews] = useState([]);
    const [galleries, setGalleries] = useState([]);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e, index) => {
        const imageFile = e.target.files[0];
        const updatedImages = [...formData.images];
        updatedImages[index] = imageFile ? imageFile : { id: updatedImages[index].id, url: updatedImages[index].url };
        setFormData(prevFormData => ({
            ...prevFormData,
            images: updatedImages
        }));

        const preview = imageFile ? URL.createObjectURL(imageFile) : null;
        const updatedPreviews = [...imagePreviews];
        updatedPreviews[index] = preview;
        setImagePreviews(updatedPreviews);
    };


    const addImageInput = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            images: [...prevFormData.images, null]
        }));
        setImagePreviews(prevPreviews => [...prevPreviews, null]);
    };

    const removeImage = (indexToRemove) => {
        const updatedImages = formData.images.filter((_, index) => index !== indexToRemove);
        const updatedPreviews = imagePreviews.filter((_, index) => index !== indexToRemove);

        setFormData(prevFormData => ({
            ...prevFormData,
            images: updatedImages // Update formData to remove the image
        }));
        setImagePreviews(updatedPreviews); // Update imagePreviews to remove the image preview

        if (selectedGallery) {
            const updatedSelectedGalleryImages = selectedGallery.images.filter((_, index) => index !== indexToRemove);
            setSelectedGallery(prevGallery => ({
                ...prevGallery,
                images: updatedSelectedGalleryImages // Update selectedGallery if applicable
            }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const postData = new FormData();
            console.log("formData", formData)
            postData.append('title', formData.title);
            postData.append('description', formData.description); // Make sure this line is executed
            formData.images.forEach((image) => {
                if (image) {
                    postData.append('images', image);
                }
            });
            const response = await axios.post(`${BASE_URL}/admins/add-gallery/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Gallery added successfully!');
            window.location.reload();
            setFormData({
                title: '',
                description: '',
                images: [null]
            });
            setImagePreviews([]);
            setGalleries([...galleries, response.data]); // Update gallery list with the new gallery
        } catch (error) {
            console.error('Error adding Gallery:', error);
            alert('Failed to add Gallery. Please try again.');
        }
    };
    const handleQuillChange = (value) => {
        setFormData(prevData => ({
            ...prevData,
            description: value
        }));
    };

    const togglePersonalInfo = () => {
        setPersonalInfoActive(true);
        setMailingAddressActive(false);
    };

    const toggleMailingAddress = () => {
        setMailingAddressActive(true);
        setPersonalInfoActive(false);
    };

    useEffect(() => {
        return () => {
            imagePreviews.forEach(preview => URL.revokeObjectURL(preview));
        };
    }, [imagePreviews]);

    const handleEdit = (gallery) => {
        setSelectedGallery(gallery);
        setFormData({
            title: gallery.title,
            description: gallery.description,
            images: gallery.images.map(image => ({ id: image.id, url: image.image }))
        });
        setImagePreviews(gallery.images.map(image => image.image));
        setEditFormVisible(true);
    };


    const fetchGallery = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/gallery/`);
            setGallery(response.data);
        } catch (error) {
            console.error('Error fetching gallery:', error);
        }
    };
    useEffect(() => {
        fetchGallery();
    }, []);

    const handleDelete = async (galleryId) => {
        try {
            if (window.confirm("Are you sure you want to delete this gallery?")) {
                await axios.delete(`${BASE_URL}/admins/delete-gallery/${galleryId}/`);
                const updatedGalleries = galleries.filter(gallery => gallery.id !== galleryId);
                setGalleries(updatedGalleries);
                alert('Gallery deleted successfully!');
                fetchGallery()
            }
        } catch (error) {
            console.error('Error deleting Gallery:', error);
            alert('Failed to delete Gallery. Please try again.');
        }
    };
    const [gallery, setGallery] = useState([]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const putData = new FormData();
            putData.append('title', formData.title);
            putData.append('description', formData.description)

            formData.images.forEach((image, index) => {
                if (image.url) {
                    putData.append('existing_images', image.url);
                } else if (image instanceof File) {
                    putData.append('images', image);
                }
            });

            const response = await axios.put(`${BASE_URL}/admins/update-gallery/${selectedGallery.id}/`, putData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alert('Gallery updated successfully!');

            setFormData({ title: '', description: '', images: [null] });
            setImagePreviews([]);
            setEditFormVisible(false);

            const updatedGallery = response.data;

            // Update gallery state based on whether selectedGallery is defined
            setGallery(prevGallery => {
                if (!prevGallery) {
                    return [updatedGallery]; // Initialize if prevGallery is undefined
                } else {
                    return prevGallery.map(galleryItem => galleryItem.id === updatedGallery.id ? updatedGallery : galleryItem);
                }
            });

        } catch (error) {
            console.error('Error updating Gallery:', error);
            alert('Failed to update Gallery. Please try again.');
        }
    };






    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Manage Gallery</p>
                <div className='flex pt-12 gap-[12rem] p-8'>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={` text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Gallery</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={toggleMailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Gallery</p>
                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className="mt-4 w-[50%]">
                        <form onSubmit={editFormVisible ? handleFormSubmit : handleSubmit} className="space-y-4">

                            <div>
                                <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    placeholder='Title Name'
                                    className="mt-1 block w-full shadow-sm sm:text-sm py-4 border-gray-300 rounded-md"
                                />
                            </div>
                            <div>
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700"> Description</label>
                                <ReactQuill
                                    value={formData.description}
                                    onChange={handleQuillChange}
                                    placeholder="Enter description with links and formatting"
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Images</label>
                                {formData.images.map((image, index) => (
                                    <div key={index} className="mt-1 flex items-center">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e, index)}
                                            className="block w-full text-sm text-gray-900 border border-gray-300 py-2  cursor-pointer bg-gray-50 focus:outline-none"
                                        />
                                        {imagePreviews[index] && (
                                            <img src={imagePreviews[index]} alt="Preview" className="ml-4 h-20" />
                                        )}
                                        <button type="button" onClick={() => removeImage(index)} className="ml-2 text-red-600 hover:text-red-900">
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <div className='w-[30%] '>
                                    <button type="button" onClick={addImageInput} className="mt-2 p-3 rounded-[8px] submit-btn-cgeneratepaswd text-white     bg-[#1AB78D]">
                                        Add Another Image
                                    </button>
                                </div>
                            </div>
                            <div className='w-[40%] pt-4'>
                                <button type="submit" className=" submit-btn-certificate   p-4  text-white rounded-md ">
                                    {editFormVisible ? 'Update Gallery' : 'Add Gallery'}
                                </button>
                                {editFormVisible && (
                                    <button type="button" onClick={() => setEditFormVisible(false)} className="ml-2 px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700">
                                        Cancel
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <div className="mt-4">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="py-2">Title</th>
                                    <th className="py-2">Images</th>
                                    <th className="py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gallery.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border px-4 py-2">{item.title}</td>
                                        <td className="border px-4 py-2">
                                            {item.images.map((img, imgIndex) => (
                                                <img key={imgIndex} src={img.image} alt="Gallery" className="h-20 inline-block mr-2" />
                                            ))}
                                        </td>
                                        <td className="border px-4 py-2">
                                            <button onClick={() => handleEdit(item)} className="text-blue-600 hover:text-blue-900 mr-2">Edit</button>
                                            <button onClick={() => handleDelete(item.id)} className="text-red-600 hover:text-red-900">Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {editFormVisible && (
                            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                                <div className=' bg-white p-6 rounded-lg w-2/3 relative'>
                                    <button onClick={() => setEditFormVisible(false)} className='absolute top-4 right-4  text-black'>
                                        X
                                    </button>
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="relative pt-6">
                                            <p className='text-white text-[18px] not-italic font-medium leading-[24px]'>Title</p>
                                            <div className="pt-2">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={formData.title}
                                                    className="border border-gray-400 rounded-[6px] px-[26px] py-4 w-full bg-[#F4F4F4]"
                                                    placeholder="Title"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <p className='text-white text-[18px] not-italic font-medium leading-[24px]'>Description</p>
                                            <div className="pt-2">
                                                <ReactQuill
                                                    value={formData.description}
                                                    onChange={handleQuillChange}
                                                    placeholder="Enter description with links and formatting"
                                                    className="w-full"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-white text-[18px] not-italic pt-6 font-medium leading-[24px]'>Existing Images</p>
                                            {selectedGallery.images.map((image, index) => (
                                                <div key={index} className="pt-1 flex items-center">
                                                    <img
                                                        src={imagePreviews[index] || image.image}
                                                        alt={`Image ${index}`}
                                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                    />
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageChange(e, index)}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => removeImage(index)}
                                                        className="ml-2 text-red-600"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            ))}

                                            {formData.images.slice(selectedGallery.images.length).map((_, newIndex) => (
                                                <div key={selectedGallery.images.length + newIndex} className="pt-1 flex items-center">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleImageChange(e, selectedGallery.images.length + newIndex)}
                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => removeImage(selectedGallery.images.length + newIndex)}
                                                        className="ml-2 text-red-600"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='pt-2 w-[20%]'>
                                            <button type="button" onClick={addImageInput} className='submit-btn-cgeneratepaswd text-white rounded-[10px] p-3'>
                                                Add Image
                                            </button>
                                        </div>
                                        <div className='pt-8 w-[30%] '>
                                            <button type="submit" className='submit-btn-certificate text-white rounded-[10px] p-3'>
                                                Update Gallery
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Gallerys;
