import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom"
import log1 from "../../../assets/images/fcpi 1.png";
import log2 from "../../../assets/images/menu-02.svg";
import dash from "../../../assets/images/dashboard-circleblack.svg";
import log3 from "../../../assets/images/dashboard-circle.png";
import "./Asidebar.css";
import dash1 from "../../../assets/images/forumb.png";
import log4 from "../../../assets/images/camera-videowhite.svg";
import dash2 from "../../../assets/images/speakerb.png";
import log5 from "../../../assets/images/calendar-02white.svg";
import dash3 from "../../../assets/images/eventsb.png";
import log6 from "../../../assets/images/download-square-02white.svg";
import dash4 from "../../../assets/images/certificateblack.svg";
import log7 from "../../../assets/images/certificatewhite.svg";
import dash5 from "../../../assets/images/userw.png";
import log8 from "../../../assets/images/userb.png";
import dash6 from "../../../assets/images/logout-02black.svg";
import log9 from "../../../assets/images/logout-02whaite.svg";
import dash11 from "../../../assets/images/speakerw.png";
import dash12 from "../../../assets/images/forumw.png";
import circle from "../../../assets/images/circle1.png";
import circle2 from "../../../assets/images/circle2.png";
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import './Asidebar.css';
// 
const Asidebar = () => {
    const [hoveredButton, setHoveredButton] = useState(false);
    const [hovered1Button, setHovered1Button] = useState(false);
    const [hovered2Button, setHovered2Button] = useState(false);
    const [hovered3Button, setHovered3Button] = useState(false);
    const [hovered4Button, setHovered4Button] = useState(false);
    const [hovered5Button, setHovered5Button] = useState(false);
    const [hovered6Button, setHovered6Button] = useState(false);
    const [hovered7Button, setHovered7Button] = useState(false);
    const [hoveredAddButton, setHoveredAddButton] = useState(false);
    const [hoveredManageButton, setHoveredManageButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenEvent, setDropdownOpenEvent] = useState(false);
    const [dropdownOpenblog, setDropdownOpenblog] = useState(false);
    const [dropdownOpenevent, setDropdownOpenevent] = useState(false);
    const [activeContent, setActiveContent] = useState(location.pathname);
    const [dropdowncertificateOpenevent, setDropdowncertificateOpenevent] = useState(false);
    const [dropdowncertificateOpenEvent, setDropdowncertificateOpenEvent] = useState(false);
    const [dropdownCertificateOpen, setDropdownCertificateOpen] = useState(false);



    useEffect(() => {
        const cmsPaths = ['/banner', '/gallerys', '/news', '/board', '/boardmembers', '/newcms'];
        const certPaths = ['/certificates', '/general-certificate'];

        setDropdownOpen(cmsPaths.includes(location.pathname));
        setDropdownCertificateOpen(certPaths.includes(location.pathname));
    }, [location.pathname]);



    useEffect(() => {

        const blogPaths = ['/ablogs', '/general-blog'];
        if (blogPaths.includes(location.pathname)) {
            setDropdownOpenblog(true);
        } else {
            setDropdownOpenblog(false);
        }
    }, [location.pathname]);

    const handleDropdownToggle = () => {
        setDropdownCertificateOpen(!dropdownCertificateOpen);
    };
    useEffect(() => {

        const eventPaths = ['/aevents', '/general-event'];
        if (eventPaths.includes(location.pathname)) {
            setDropdownOpenevent(true);
        } else {
            setDropdownOpenevent(false);
        }
    }, [location.pathname]);

    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(true);
    const handleLogout = () => {
        localStorage.removeItem('adminAuthToken');
        localStorage.removeItem('logoutTime');
        navigate('/admin');
    };





    return (

        <div className='bg-gray-100'>
            <div className='bg-white p-6  '>

                <div className='flex justify-between'>
                    <img src={log1} alt="" />
                    <img src={log2} alt="" />
                </div>
                <div className='pt-8'>
                    <Link to="/admin-dashboard">
                        <button
                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/admin-dashboard' ? 'active' : ''}`}
                            onMouseEnter={() => setHoveredButton(true)}
                            onMouseLeave={() => setHoveredButton(false)}
                        >
                            <div className='flex gap-4 items-center pl-4 '>
                                <img src={location.pathname === '/admin-dashboard' ? log3 : dash} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    Dashboard
                                </p>
                            </div>
                        </button>
                        {/* <div className='pt-1'>
                            <Link to="/csv">
                                <button
                                    className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/csv' ? 'active' : ''}`}
                                >
                                    <div className='flex gap-4 items-center pl-4 '>

                                        <img src={location.pathname === '/csv' ? dash12 : dash1} alt="Logo" className='dashboard-image' />
                                        <p className='button-text text-[18px]'>
                                            csv
                                        </p>
                                    </div>
                                </button>
                            </Link>
                        </div> */}
                    </Link>
                    <div className='pt-1'>
                        <Link to="/aforum">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/aforum' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/aforum' ? dash12 : dash1} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Forum
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/member">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/member' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/member' ? dash12 : dash1} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Member
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/forummember">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/forummember' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/forummember' ? dash12 : dash1} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Forum Member
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/speaker">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/speaker' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/speaker' ? dash11 : dash2} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Speakers
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        {/* <Link to="/aevents"> */}
                        <button
                            className={`button  w-full py-3 rounded-[6px] ${location.pathname === '' ? 'active' : ''}`}
                            onClick={() => setDropdownOpenevent(!dropdownOpenEvent)}
                        >
                            <div className='flex gap-4 items-center pl-4 '>

                                <img src={location.pathname === ' ' ? log5 : dash3} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    Events
                                </p>
                            </div>
                        </button>
                        {dropdownOpenevent && (
                            <div className="dropdowncms-menu">

                                <Link to="/aevents">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/aevents' ? 'active' : ''}`}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={activeContent === '/aevents' ? log5 : dash3} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Forum Event</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/general-event">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/general-event' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/general-event' ? log5 : dash3} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    General Event
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>



                            </div>
                        )}
                  
                    </div>


                    

                 
                    <div className='pt-1'>
                     
                        <button
                            className={`button w-full py-3 rounded-[6px] ${dropdownCertificateOpen ? 'active' : ''}`}
                            onClick={handleDropdownToggle}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={dropdownCertificateOpen ? log5 : dash3} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>Certificates</p>
                            </div>
                        </button>
                        {dropdownCertificateOpen && (
                            <div className="dropdowncms-menu">

                                <Link to="/certificates">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/certificates' ? 'active' : ''}`}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={activeContent === '/certificates' ? log5 : dash3} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Forum Certificates</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/general-certificate">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/general-certificate' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/general-certificate' ? log5 : dash3} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    General  Certificates
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>



                            </div>
                        )}
                        {/* </Link> */}
                    </div>

                    {/* hhhhhhhhhhhhhhhhhhhhhhhhhhh */}
                    {/* <div className='pt-1'>
                        <Link to="/certificates">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/certificates' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/certificates' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Certificates
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div> */}
                    <div className='pt-1'>
                        <Link to="/user">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/user' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/user' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Users
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/seconduser">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/seconduser' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/seconduser' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Chat Users
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>


                    <div className='pt-1'>
                        <button
                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '' ? 'active' : ''}`}
                            onClick={() => setDropdownOpenblog(!dropdownOpen)}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={location.pathname === '' ? dash12 : dash1} alt="CMS Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>Blogs</p>
                            </div>
                        </button>
                        {dropdownOpenblog && (
                            <div className="dropdowncms-menu">

                                <Link to="/ablogs">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/ablogs' ? 'active' : ''}`}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={activeContent === '/ablogs' ? log8 : dash5} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Forum Blog</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/general-blog">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/general-blog' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/general-blog' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    General Blog
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>



                            </div>
                        )}


                    </div>

                    <div className='pt-1'>

                        <button
                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '' ? 'active' : ''}`}
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={location.pathname === '' ? log8 : dash5} alt="CMS Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>CMS</p>
                            </div>
                        </button>


                        {dropdownOpen && (
                            <div className="dropdowncms-menu">

                                <Link to="/banner">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/banner' ? 'active' : ''}`}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={activeContent === '/banner' ? log8 : dash5} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Home Banner</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/gallerys">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/gallerys' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/gallerys' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Gallery
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/news">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/news' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/news' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    News
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                {/* <Link to="/board">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/board' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/board' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Board
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link> */}
                                <Link to="/boardmembers">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/boardmembers' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/boardmembers' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Board Members
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className='pt-1'>
                        <Link to="/newsletter">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/newsletter' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/newsletter' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Newsletter
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/podcast">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/podcast' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/podcast' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                         Podcast
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                     
                    <div className='pt-1'>
                        <button
                            className='button w-full py-3 rounded-[6px]'
                            onMouseEnter={() => setHovered6Button(true)}
                            onMouseLeave={() => setHovered6Button(false)}
                        >
                            <div className='flex gap-4 items-center pl-4 '>
                                <img src={location.pathname === '#' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    FCPI
                                </p>
                            </div>
                        </button>
                    </div>
                    <div className='pt-1'>
                        <button
                            className='button w-full py-3 rounded-[6px]'
                            onMouseEnter={() => setHovered7Button(true)}
                            onMouseLeave={() => setHovered7Button(false)}
                            onClick={handleLogout}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={hovered7Button ? log9 : dash6} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    Logout
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
                <div className='pl-4 pt-4'>
                    <p className='text-[#969696] text-[14px] not-italic font-normal leading-[16px] tracking-[0.6px]'>Powered by <br />
                        Hoztox Technologies</p>
                    <div className='pt-3'>
                        <a href="https://www.hoztox.com" className='text-[#F58A2F] text-[14px] not-italic font-normal leading-[24px] tracking-[0.6px] underline' target="_blank" rel="noopener noreferrer">www.hoztox.com</a>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Asidebar;
