import React, { useState, useEffect, useRef } from 'react';
import adm from "../../../assets/images/admin-3.png";
import logo1 from "../../../assets/images/planet.png";
import grn from "../../../assets/images/green.png";
import cam from "../../../assets/images/camera.png";
import img from "../../../assets/images/ad-user.png";
import exp from "../../../assets/images/export.png";
import grp from "../../../assets/images/graph.png";
import adm1 from "../../../assets/images/bg-adm.png";
import blg from "../../../assets/images/blg.png";
import "./Adashboard.css";
import { BASE_URL } from '../../../Utils/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import pro from "../../../assets/images/download.jpg"
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
const Adashboard = () => {


    const [activeButton, setActiveButton] = useState('');
    const [activeButton1, setActiveButton1] = useState('today');
    const [liveEvents, setLiveEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [authChecking, setAuthChecking] = useState(true);
    const navigate = useNavigate();
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    useEffect(() => {
        const adminToken = localStorage.getItem('adminAuthToken');
        if (!adminToken) {
            navigate('/admin');
        } else {
            navigate('/admin-dashboard');
        }
    }, [navigate]);


    const [dropdownVisible, setDropdownVisible] = useState({
        today: true,
        thisWeek: false,
        thisMonth: false,
        thisYear: false,
    });





    const toggleDropdown1 = (button) => {
        setActiveButton1(button);
        setDropdownVisible((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map(key => [key, key === button ? !prevState[key] : false])),
        }));
    };
    const [pastEvents, setPastEvents] = useState([]);
    const [upcomingActive, setUpcomingActive] = useState(false)
    const [liveActive, setLiveActive] = useState(false)
    const [PastActive, setPastActive] = useState(true)
    const [completedEvents, setCompletedEvent] = useState([]);
    const toggleUpcoming = () => {
        setUpcomingActive(!upcomingActive);

        if (!upcomingActive) {
            setPastActive(false);
            setLiveActive(false);
        }
    };
    const togglePast = () => {
        setPastActive(!PastActive);

        if (!PastActive) {
            setUpcomingActive(false);
            setLiveActive(false);
        }
    };
    const toggleLive = () => {
        setLiveActive(!liveActive);

        if (!liveActive) {
            setPastActive(false);
            setUpcomingActive(false);
        }
    };

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch events from both endpoints in parallel
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventslist/`),
                    axios.get(`${BASE_URL}/admins/general_events/`)
                ]);

                console.log("First API Response:", response1.data);
                console.log("Second API Response:", response2.data);

                // Combine or update state based on both responses
                setLiveEvents([
                    ...response1.data.live_events,
                    ...response2.data.live_events
                ]);
                setUpcomingEvents([
                    ...response1.data.upcoming_events,
                    ...response2.data.upcoming_events
                ]);
                setCompletedEvent([
                    ...response1.data.completed_events,
                    ...response2.data.completed_events
                ]);

            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Make both API requests in parallel
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/blogslist/`),
                    axios.get(`${BASE_URL}/admins/general-blogslist/`)
                ]);

                console.log("First API Response:", response1.data);
                console.log("Second API Response:", response2.data);

                // Combine and sort the blogs from both responses
                const combinedBlogs = [
                    ...response1.data,
                    ...response2.data
                ];
                const sortedBlogs = combinedBlogs.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Update state with sorted blogs
                setBlogs(sortedBlogs);

            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);


    const [eventstoday, setEventsToday] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventstoday/`),
                    axios.get(`${BASE_URL}/admins/general_eventstoday/`)
                ]);

                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;

                // Combine and sort events
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });

                setEventsToday(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);



    const [eventsweek, setEventsWeek] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch data from both endpoints concurrently
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventsweek/`),
                    axios.get(`${BASE_URL}/admins/general_eventsweek/`)
                ]);

                // Extract events from both responses
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;

                // Combine events from both responses
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];

                // Sort combined events by date
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });

                // Update state with sorted events
                setEventsWeek(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);
    const [eventmonth, setEventsMonth] = useState({});

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch data from both endpoints concurrently
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventsmonth/`),
                    axios.get(`${BASE_URL}/admins/general_eventsmonth/`)
                ]);

                // Extract events from both responses
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;

                // Combine events from both responses
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];

                // Sort combined events by date
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });

                // Update state with sorted events
                setEventsMonth(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    const [eventyear, setEventsYear] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch data from both endpoints concurrently
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventsyear/`),
                    axios.get(`${BASE_URL}/admins/general_eventsyear/`)
                ]);

                // Extract events from both responses
                const fetchedEvents1 = response1.data.events;
                const fetchedEvents2 = response2.data.events;

                // Combine events from both responses
                const combinedEvents = [...fetchedEvents1, ...fetchedEvents2];

                // Sort combined events by date
                const sortedEvents = combinedEvents.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                });

                // Update state with sorted events
                setEventsYear(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);


    const [users, setUsers] = useState([]);
    const userCount = users.length;
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/userlist/`);
                setUsers(response.data);
                console.log("user", response.data)
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);
    const [userData, setUserData] = useState([]);
    const [dropdownStates, setDropdownStates] = useState(userData ? Array(userData.length).fill({ isOpen: false }) : []);
    console.log("dropdownStates:", dropdownStates);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/useralllist/`);
                const fetchedUserData = response.data;
                // Sort users by date_joined in descending order
                const sortedUsers = fetchedUserData.sort((a, b) => new Date(b.date_joined) - new Date(a.date_joined));
                setUserData(sortedUsers);
                console.log("sssss", response.data)
                setDropdownStates(Array(fetchedUserData.length).fill({ isOpen: false }));
            } catch (error) {
                console.error('Error fetching user data:', error);
                alert("Error fetching user data");
            }
        };

        fetchUserData();
    }, []);

    const [userCounts, setUserCounts] = useState({ last_7_days: 0, this_month: 0 });

    useEffect(() => {
        axios.get(`${BASE_URL}/accounts/user-countweek/`)
            .then(response => {
                setUserCounts(response.data);
                console.log("week user", response.data);
            })
            .catch(error => {
                console.error('Error fetching user counts:', error);
            });
    }, []);


    return (
        <div className='bg-[#f4f4f4] w-[100%] h-[100%] p-6 '>


            <div className='grading overflow-hidden rounded-[8px] relative'>
                <img src={adm} alt="" className='rounded-[8px] w-[100%] overflow-hidden' />
                <div className='absolute left-6 bottom-6  '>

                    <div className='w-full flex-justify between '>
                        <div className='flex'>
                            <p className='text-white relative z-10 text-[32px] not-italic font-bold leading-[normal] uppercase'>FCPI Virtual ID Week</p>
                            <p className='text-white relative z-10 pt-3 pl-2 text-[18px] not-italic font-medium leading-[normal]'>(Day-3)</p>


                        </div>
                        <p className='text-white relative z-10 text-[21px] not-italic font-normal leading-[normal]'>Infectious Diseases Forum</p>



                    </div>

                </div>


                <div className='  flex   z-10 gap-3 absolute right-6 bottom-6  '>


                    <div>
                        <img src={grn} alt="" className='pt-2' />
                    </div>
                    <p className='text-[color:var(--White,#FFF)] relative z-10 text-[21px] not-italic font-semibold leading-[25px] tracking-[-0.105px]'>Live Events</p>
                    <img src={cam} alt="" />
                </div>

            </div>
            <div className=''>
                <div className='flex pt-6 gap-6 '>
                    <div className=' w-[60%] pl-6 pr-6  bg-white shadow-equal  h-auto rounded-[8px] pt-6 '>
                        <div className='flex justify-between'>
                            <p className='text-[color:var(--Black,#222)] text-[27px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Events</p>
                            <div>
                                <button className={`relative p-3 px-4  smooth rounded-[4px] ${PastActive ? ' bg-[#00549A]' : 'bg-gray-100'}`} onClick={togglePast}>
                                    <div className='flex gap-3'>

                                        <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${PastActive ? 'text-white' : 'text-gray-600'}`}>Past Event</p>

                                    </div>
                                </button>
                                <button className={`relative p-3 px-4 smooth rounded-[4px] ${liveActive ? ' bg-[#00549A]' : 'bg-gray-100'}`} onClick={toggleLive}>
                                    <div className='flex gap-3'>

                                        <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${liveActive ? 'text-white' : 'text-gray-600'}`}>live Event</p>

                                    </div>
                                </button>
                                <button className={`relative p-3 px-4 smooth rounded-[4px] ${upcomingActive ? ' bg-[#00549A]' : 'bg-gray-100'}`} onClick={toggleUpcoming}>
                                    <div className='flex gap-3'>

                                        <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${upcomingActive ? 'text-white' : 'text-gray-600'}`}>Upcoming Event</p>

                                    </div>
                                </button>

                            </div>


                        </div>
                        {PastActive && (
                            <div className="dropdown-content mt-4 max-h-[45vh] overflow-y-auto">
                                {completedEvents.map(event => (
                                    <div key={event.id}>
                                        <Link to={`/events-detail/${event.slug}/single-events/`}>

                                            <motion.div
                                                className="flex pt-6 "
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='w-[100px]'>
                                                    <img src={BASE_URL + event.banner} alt="" className='rounded-[8px] w-[70px] h-[70px]' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-semibold leading-[normal]'>{event.event_name}</p>
                                                    <p className='text-[color:var(--Green,#1AB78D)] pt-2 text-[19px] not-italic font-medium leading-[20px]'>{event.forum_name}</p>
                                                    <p className='text-[color:var(--Gray,#58585A)] pt-1 text-[17px] not-italic font-normal leading-[20px]'>{formatDate(event.date)}</p>
                                                </div>
                                            </motion.div>
                                        </Link>
                                        <hr className='mt-6' />
                                    </div>
                                ))}
                                <div className='text-end pt-4'>
                                    <button className='bg-gray-100 px-4 py-2 rounded-[4px]'>
                                        <p className='text-[color:var(--Blue,#00549A)] text-[14px] not-italic font-medium leading-[normal]'>View all</p>
                                    </button>
                                </div>
                            </div>
                        )}
                        {liveActive && (
                            <div className="dropdown-content mt-4 max-h-[45vh] overflow-y-auto">
                                {liveEvents.map(event => (
                                    <div key={event.id}>
                                        <Link to={`/events-detail/${event.slug}/single-events/`}>
                                            <motion.div
                                                className="flex pt-6 "
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='w-[100px]'>
                                                    <img src={BASE_URL + event.banner} alt="" className='rounded-[8px]  w-[70px] h-[70px]' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-semibold leading-[normal]'>{event.event_name}</p>
                                                    <p className='text-[color:var(--Green,#1AB78D)] pt-2 text-[19px] not-italic font-medium leading-[20px]'>{event.forum_name}</p>
                                                    {formatDate(event.date)}

                                                </div>
                                            </motion.div>
                                        </Link>
                                        <hr className='mt-6' />
                                    </div>
                                ))}

                                <div className='text-end pt-4'>
                                    <button className='bg-gray-100 px-4 py-2 rounded-[4px]'>
                                        <p className='text-[color:var(--Blue,#00549A)] text-[14px] not-italic font-medium leading-[normal]'>View all</p>
                                    </button>
                                </div>
                            </div>
                        )}
                        {upcomingActive && (
                            <div className="dropdown-content mt-4 max-h-[45vh] overflow-y-auto">
                                {upcomingEvents.map(event => (
                                    <div key={event.id}>
                                        <Link to={`/events-detail/${event.slug}/single-events/`}>
                                            <motion.div
                                                className="flex pt-6 "
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='w-[100px]'>
                                                    <img src={BASE_URL + event.banner} alt="" className='rounded-[8px]  w-[70px] h-[70px]' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-semibold leading-[normal]'>{event.event_name}</p>
                                                    <p className='text-[color:var(--Green,#1AB78D)] pt-2 text-[19px] not-italic font-medium leading-[20px]'>{event.forum_name}</p>
                                                    {formatDate(event.date)}

                                                </div>
                                            </motion.div>
                                        </Link>
                                        <hr className='mt-6' />
                                    </div>
                                ))}
                                <div className='text-end pt-4'>
                                    <button className='bg-gray-100 px-4 py-2 rounded-[4px]'>
                                        <p className='text-[color:var(--Blue,#00549A)] text-[14px] not-italic font-medium leading-[normal]'>View all</p>
                                    </button>
                                </div>
                            </div>
                        )}


                    </div>


                    <div className='bg-white rounded-[8px] w-[40%] p-6'>
                        <p className='text-[color:var(--Black,#222)] text-[26px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Recent Registered User’s</p>
                        <hr className='mt-6' />
                        <div className='  pt-5 max-h-[45vh] overflow-y-auto '>
                            {userData.map((user, index) => (
                                <div key={index} className='py-4 flex items-center '>
                               
                                        <motion.div
                                            className=" flex "
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <div>
                                                {user.user_profile && user.user_profile.image ? (
                                                    <img src={BASE_URL + user.user_profile.image} alt="User Image" className='w-[60px] rounded-[30px]' />
                                                ) : (
                                                    <img src={pro} alt="Default Icon" className='w-[60px]' />
                                                )}
                                            </div>
                                            <div className='flex justify-between'>
                                                <div className='pl-4 nam-width'>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[normal] tracking-[0.25px]'>
                                                        {`${user.first_name} ${user.last_name}`}
                                                    </p>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[12px] not-italic font-normal leading-[normal] tracking-[0.25px]'>
                                                        Registered on {formatDate(user.date_joined)}
                                                    </p>
                                                </div>
                                                <div className='pl-4'>
                                                    <button className='px-4 py-2 rounded-[4px]' style={{ background: "rgba(26, 183, 141, 0.10)" }}>
                                                        <p className='text-[color:var(--Green,#1AB78D)] text-[14px] not-italic font-medium leading-[normal]'>Active</p>
                                                    </button>
                                                </div>
                                            </div>
                                            </motion.div>
                                    <hr className='mt-6' />
                                </div>
                            ))}

                            <div className='text-end pt-5'>
                                <button className='bg-gray-100 px-4 py-2 rounded-[4px]'>
                                    <p className='text-[color:var(--Blue,#00549A)] text-[14px] not-italic font-medium leading-[normal]'>View all User’s </p>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='flex pt-6 gap-6'>
                <div className='bg-white rounded-[8px] w-[423px]'>
                    <p className='text-[color:var(--Black,#222)] pl-6 pt-6 text-[26px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>User Count</p>
                    <hr className='mt-6 w-full' />
                    <div className='flex pt-6'>
                        <p className='text-[color:var(--Blue,#00549A)] pl-6 text-[40px] not-italic font-semibold leading-[23px] tracking-[-1.95px]'>{userCount}</p>
                        <img src={exp} alt="" className='pl-2' />
                        <p className='text-[color:var(--Gray,#58585A)] pl-4 text-[16px] not-italic font-normal leading-[normal] tracking-[-0.07px]'>Total Users</p>
                    </div>
                    <div className='flex pl-6 '>
                        <p className='text-[color:var(--Gray,#58585A)] text-[18px] pt-6 not-italic font-normal leading-[normal] tracking-[-0.08px]'>This week</p>
                        <div className='pl-4 pt-4'>
                            <div className=' p-2 rounded-[60px]' style={{ background: "rgba(238, 110, 110, 0.10)" }}>
                                <p className='text-[#EE6E6E] text-[17px] not-italic font-normal leading-[22.452px]'>  {userCounts.last_7_days}</p>
                            </div>
                        </div>
                        <p className='text-[color:var(--Gray,#58585A)] pt-6 text-[20px] pl-6 not-italic font-normal leading-[normal] tracking-[-0.08px]'>This month</p>
                        <div className='pl-4 pt-4'>
                            <div className='px-2 py-2 rounded-[30px]' style={{ background: "rgba(0, 84, 154, 0.10)" }}>
                                <p className='text-[#00549A] text-[17px] not-italic font-normal leading-[22.452px]'>{userCounts.this_month}</p>
                            </div>
                        </div>
                    </div>
                    <div className='pt-6 pl-6 pr-6 pb-6'>
                        <img src={grp} alt="" className='w-[100%]' />
                    </div>
                </div>
                <div className='bg-white rounded-[8px] w-[75%] p-6'>



                    <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Newsletters</p>
                    <hr className='mt-6' />

                    <div className='flex w-[100%] pt-6'>
                        <div>
                            <img src={adm1} alt="" className='rounded-[4px]' />
                        </div>
                        <div className='pl-4 w-[100%] '>
                            <p className='text-[color:var(--Black,#222)] text-[21px] not-italic font-medium leading-[23px]'>Product-Led Growth vs. Sales-Led Growth<br />
                                vs. Marketing-Led Growth</p>
                            <div className='flex justify-between'>
                                <div className='pt-1'>
                                    <button className='bg-gray-100 rounded-[74px] px-4 py-1'>
                                        <p className='text-[#58585A] text-[17px] not-italic font-normal leading-[normal]'>Critical Care Medicine Forum</p>
                                    </button>
                                </div>
                                <p className=' text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[20px]'>January 2024</p></div>
                        </div>
                    </div>
                    <hr className='mt-6' />
                    <div className='flex pt-6'>
                        <div>
                            <img src={adm1} alt="" className='rounded-[4px]' />
                        </div>
                        <div className='pl-4 w-[100%] '>
                            <p className='text-[color:var(--Black,#222)] text-[21px] not-italic font-medium leading-[23px]'>Product-Led Growth vs. Sales-Led Growth<br />
                                vs. Marketing-Led Growth</p>
                            <div className='flex justify-between'>
                                <div className='pt-1'>
                                    <button className='bg-gray-100 rounded-[74px] px-4 py-1'>
                                        <p className='text-[#58585A] text-[17px] not-italic font-normal leading-[normal]'>Critical Care Medicine Forum</p>
                                    </button>
                                </div>
                                <p className=' text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[20px]'>January 2024</p></div>
                        </div>
                    </div>
                    <hr className='mt-6' />
                    <div className='text-end pt-6'>
                        <button className='bg-gray-100 px-4 py-2 rounded-[4px]  '>
                            <p className='text-[color:var(--Blue,#00549A)] text-[14px] not-italic font-medium leading-[normal]'>View all Newsletters </p>
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex pt-6 gap-6'>
                <div className='bg-white rounded-[8px] w-[60%] p-6'>



                    <div className='flex justify-between'>
                        <p className='text-[color:var(--Black,#222)] text-[26px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>  <div className='flex justify-between'>
                            <p className='text-[color:var(--Black,#222)] text-[28px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Recent Blogs</p>
                            <div className=' '>

                            </div>
                        </div></p>
                        <Link to="/blogs">
                            <div className=' '>
                                <button className='bg-gray-100 px-4 py-2 rounded-[4px]'>
                                    <p className='text-[color:var(--Blue,#00549A)] text-[14px] not-italic font-medium leading-[normal]'>View all </p>
                                </button>
                            </div>
                        </Link>
                    </div>
                    <hr className='mt-6' />

                    <div className="max-h-[37vh] overflow-y-auto p-l-10">
                        {blogs.map(blog => (
                            <div key={blog.slug} className="pt-12">
                                <Link to={`/blogs-detail/${blog.slug}`}>
                                    <div className="pt-8">
                                        <div className="flex">
                                            <div className='w-[60px]'>
                                                <img src={BASE_URL + blog.blog_banner} alt="" className='rounded-[4px] w-[70px] h-[60px]' />
                                            </div>
                                            <div className='flex flex-1 justify-between items-start ml-4'>
                                                <div>
                                                    <p className="text-[#222] text-[20px] not-italic font-normal leading-normal">{blog.title}</p>
                                                    <p className="text-[18px] text-[#1AB78D] not-italic font-normal leading-normal">{blog.forum_title}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#222] text-[15px] not-italic font-normal leading-normal">{formatDate(blog.date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mt-6" />
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>


                </div>
                <div className='w-[40%] desktop-full-width bg-white rounded-[8px] p-3'>
                    <div className='pad-6 pad-6-mob '>
                        <p className='text-[#222] text-start text-[32px] not-italic font-semibold leading-[normal] ' >Event Calendar
                        </p>
                        <div className='pt-6'>

                            <div className='border border-[#CBCBCB] rounded-[12px] p-2 pl-3 '>
                                <div className=' pb-5  '>
                                    <p className='text-[#222] text-start text-[23px]  not-italic font-semibold leading-[normal]'  >Events</p>
                                    <div className='flex env-block events-items-date pt-4 gap-2'>

                                        <button
                                            className={`border border-[#CBCBCB] rounded-[102px] smooth p-5  py-1 ${activeButton1 === 'today' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                            onClick={() => toggleDropdown1('today')}>
                                            <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'today' ? 'text-[#1AB78D]' : ''}`}>Today</p>
                                        </button>

                                        <button
                                            className={`border border-[#CBCBCB] rounded-[102px] smooth  p-5 py-1 ${activeButton1 === 'thisWeek' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                            onClick={() => toggleDropdown1('thisWeek')}>
                                            <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisWeek' ? 'text-[#1AB78D]' : ''}`}>This Week</p>
                                        </button>




                                        <button
                                            className={`border border-[#CBCBCB] rounded-[102px] smooth p-5 py-1 ${activeButton1 === 'thisMonth' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                            onClick={() => toggleDropdown1('thisMonth')}>
                                            <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisMonth' ? 'text-[#1AB78D]' : ''}`}>This Month</p>
                                        </button>
                                        <button
                                            className={`border border-[#CBCBCB] rounded-[102px] smooth p-5 py-1 ${activeButton1 === 'thisYear' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                            onClick={() => toggleDropdown1('thisYear')}>
                                            <p className={`text-[#58585A] ev-date not-italic font-normal   leading-[normal] ${activeButton === 'thisYear' ? 'text-[#1AB78D]' : ''}`}>This Year</p>
                                        </button>
                                    </div>



                                </div>

                                <hr className='w-full mt-2' />


                                {dropdownVisible.today && (

                                    <div className='max-h-[37vh] overflow-y-auto p-l-10'>
                                        <div className='   mt-4 eventitems'>
                                            {eventstoday.length > 0 ? (
                                                eventstoday.map(event => (
                                                    <Link to={`/events-detail/${event.slug}/`}>
                                                        <motion.div
                                                            className="flex justify-items-center gap-2 pt-4 event-item"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 4 }}
                                                            transition={{
                                                                duration: 1,
                                                                ease: "easeInOut",
                                                            }}
                                                            ref={ref}
                                                        >

                                                            <div className='wh-70'>
                                                                <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                    <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                        {new Date(event.date).getDate()}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                <div className='eventcontent'>
                                                                    <p className='text-[#222] text-start text-[20px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                        {event.event_name}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                        {event.forum_name}
                                                                    </p>
                                                                </div>
                                                                <div className='pt-2'>
                                                                    <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                        <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                            {formatDate(event.date)}
                                                                        </p>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </motion.div>
                                                    </Link>

                                                ))
                                            ) : (
                                                <p>No events today</p>
                                            )}

                                        </div>

                                    </div>
                                )}


                                {dropdownVisible.thisWeek && (

                                    <div className='max-h-[37vh] overflow-y-auto p-l-10'>
                                        <div className='   mt-4 eventitems'>
                                            {eventsweek.length > 0 ? (

                                                eventsweek.map(event => (
                                                    <Link to={`/events-detail/${event.slug}/`}>
                                                        <motion.div
                                                            className="flex justify-items-center gap-2 pt-4 event-item"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 4 }}
                                                            transition={{
                                                                duration: 1,
                                                                ease: "easeInOut",
                                                            }}
                                                            ref={ref}
                                                        >
                                                            <div className='wh-70'>
                                                                <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                    <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                        {new Date(event.date).getDate()}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                <div className='eventcontent'>
                                                                    <p className='text-[#222] text-start text-[20px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                        {event.event_name}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                        {event.forum_name}
                                                                    </p>
                                                                </div>
                                                                <div className='pt-2'>
                                                                    <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                        <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                            {formatDate(event.date)}
                                                                        </p>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </motion.div>
                                                    </Link>

                                                ))
                                            ) : (
                                                <p>No events this week</p>
                                            )}

                                        </div>

                                    </div>
                                )}
                                {dropdownVisible.thisMonth && (

                                    <div className='max-h-[37vh] overflow-y-auto p-l-10'>
                                        <div className='   mt-4 eventitems'>
                                            {eventmonth.length > 0 ? (

                                                eventmonth.map(event => (
                                                    <Link to={`/events-detail/${event.slug}/`}>
                                                        <motion.div
                                                            className="flex justify-items-center gap-2 pt-4 event-item"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 4 }}
                                                            transition={{
                                                                duration: 1,
                                                                ease: "easeInOut",
                                                            }}
                                                            ref={ref}
                                                        >
                                                            <div className='wh-70'>
                                                                <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                    <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                        {new Date(event.date).getDate()}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                <div className='eventcontent'>
                                                                    <p className='text-[#222] text-start text-[20px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                        {event.event_name}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                        {event.forum_name}
                                                                    </p>
                                                                </div>
                                                                <div className='pt-2'>
                                                                    <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                        <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                            {formatDate(event.date)}
                                                                        </p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                    </Link>

                                                ))
                                            ) : (
                                                <p>No events this month</p>
                                            )}

                                        </div>

                                    </div>
                                )}
                                {dropdownVisible.thisYear && (

                                    <div className='max-h-[37vh] overflow-y-auto p-l-10'>
                                        <div className='   mt-4 eventitems'>
                                            {eventyear.length > 0 ? (

                                                eventyear.map(event => (
                                                    <Link to={`/events-detail/${event.slug}/`}>
                                                         <motion.div
                                                            className="flex justify-items-center gap-2 pt-4 event-item"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 4 }}
                                                            transition={{
                                                                duration: 1,
                                                                ease: "easeInOut",
                                                            }}
                                                            ref={ref}
                                                        >
                                                            <div className='wh-70'>
                                                                <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                    <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                        {new Date(event.date).getDate()}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                        {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                <div className='eventcontent'>
                                                                    <p className='text-[#222] text-start text-[20px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                        {event.event_name}
                                                                    </p>
                                                                    <p className='text-[#1AB78D] text-start text-[18px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                        {event.forum_name}
                                                                    </p>
                                                                </div>
                                                                <div className='pt-2'>
                                                                    <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                        <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                            {formatDate(event.date)}
                                                                        </p>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            </motion.div>
                                                    </Link>

                                                ))
                                            ) : (
                                                <p>No events this year</p>
                                            )}

                                        </div>

                                    </div>
                                )}
                            </div>

                        </div>





                    </div>
                </div>
            </div>
            {/* <div className='pt-6'>
                <div className='bg-[white] rounded-[8px] p-6'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-[color:var(--Black,#222)] text-[26px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Users Active Chart</p>
                        </div>

                        <div>
                            <button className={`relative p-3 px-4  rounded-[4px] ${upcomingActive ? ' bg-[#00549A]' : 'bg-gray-100'}`} onClick={toggleUpcoming}>
                                <div className='flex gap-3'>

                                    <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${upcomingActive ? 'text-white' : 'text-gray-600'}`}>Monthly</p>

                                </div>
                            </button>
                            <button className={`relative p-3 px-4 rounded-[4px] ${PastActive ? ' bg-[#00549A]' : 'bg-gray-100'}`} onClick={togglePast}>
                                <div className='flex gap-3'>

                                    <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${PastActive ? 'text-white' : 'text-gray-600'}`}>Weakly</p>

                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >

    );
};

export default Adashboard;
