import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/user-circle.png";
import imag1 from "../../../assets/images/mail.png";
import Icon from "../../../assets/images/Icon frame.png";
import { Link } from "react-router-dom";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import pic from "../../../assets/images/Picture.png";
import search from "../../../assets/images/search.png";
import cls from "../../../assets/images/close.png";
import icon from "../../../assets/images/icon.png";
import icon1 from "../../../assets/images/icon1.png";
import eye from "../../../assets/images/eye.png";
import img from "../../../assets/images/more.png";
import edi from "../../../assets/images/edituse.png";
import dil from "../../../assets/images/deluser.png";
import ch from "../../../assets/images/change.png";
import st from "../../../assets/images/status.png";
import editt from "../../../assets/images/editt.png";
import axios from 'axios';
import { BASE_URL } from "../../../Utils/Config";
import { toast, Toaster } from "react-hot-toast";
import { useParams } from 'react-router-dom';
import pro from "../../../assets/images/profile.png"


const User = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(false);
    const [mailingAddressActive, setmailingAddressActive] = useState(true);
    const [userRoleActive, setuserRoleActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [clickedStates, setClickedStates] = useState(Array(3).fill(false));

    const [imagePositionY, setImagePositionY] = useState(0);
    const [userRoles, setUserRoles] = useState([]);
    const [imagePositionX, setImagePositionX] = useState(0);
    const [userData, setUserData] = useState([]);
    const { userId } = useParams();

    const [selectedRole, setSelectedRole] = useState("");
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password2: '',
        userrole: ''
    });
    const {
        first_name,
        last_name,
        phone,
        email,
        password,
        password2,
        userrole
    } = formData;
    const [generatedPassword, setGeneratedPassword] = useState("");
    const generatePassword = () => {
        const length = 8;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let newPassword = "";
        for (let i = 0; i < length; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        setFormData({
            ...formData,
            password: newPassword,
            password2: newPassword
        });
        setGeneratedPassword(newPassword);
    };
    const handleRoleSelect = (e) => {
        setSelectedRole(e.target.value);

    };


    const renderPlaceholder = () => {
        return selectedRole ? "" : "User Role";
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name)

        if (name !== 'userrole') {
            setFormData({
                ...formData,
                [name]: value
            });
        } else {
            const selectedRoleName = e.target.options[e.target.selectedIndex].text; // Get the name of the selected role
            setSelectedRole(selectedRoleName); // Update selectedRole with the name of the selected role
            setFormData({
                ...formData,
                userrole: selectedRoleName // Update userrole with the name of the selected role
            });
        }
    };

    const [users, setUsers] = useState([]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/accounts/user-roles/`);
                console.log(response.data);
                setUserRoles(response.data);
            } catch (error) {
                console.error('Error fetching user roles:', error);
            }
        };

        fetchData();
    }, []);

    const [dropdownStates, setDropdownStates] = useState(userData ? Array(userData.length).fill({ isOpen: false }) : []);
    console.log("dropdownStates:", dropdownStates);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!first_name || !last_name || !phone || !email || !password || !password2 || !selectedRole) {
            toast.error("All fields are required.");
            return;
        }

        if (password.trim() !== password2.trim()) {
            toast.error("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/accounts/add_user/`, {
                first_name,
                last_name,
                email,
                phone,
                password,
                userrole: selectedRole // Send the selected role ID instead of its name
            });
            if (response.status === 200) {
                toast.success("Registration successful.");
                fetchUserData();
                window.location.reload();
            } else {
                toast.error("Something went wrong during registration.");
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.errors) {
                const errorMessages = Object.values(error.response.data.errors).flat();
                errorMessages.forEach(errorMessage => toast.error(errorMessage));
            } else {
                toast.error("An error occurred during registration.");
            }
        }
    };




    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/accounts/userlist/`);
            const fetchedUserData = response.data;
            setUserData(fetchedUserData);
            console.log("response.data", response.data);
            setDropdownStates(Array(fetchedUserData.length).fill({ isOpen: false }));
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('Error fetching user data');
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);



    const calculateImagePosition = (event) => {
        const imageRect = event.target.getBoundingClientRect();
        setImagePositionY(imageRect.bottom);
        setImagePositionX(imageRect.left);
    };


    const handleToggleDropdown = (index) => {
        setDropdownStates((prevStates) =>
            prevStates.map((state, i) => ({
                isOpen: i === index ? !state.isOpen : state.isOpen,
            }))
        );
    };

    const handleCloseModal = () => {
        setDropdownStates(prevStates =>
            prevStates.map(state => ({ isOpen: false }))
        );
    };
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            if (file.size <= 25 * 1024 * 1024) {
                setSelectedImage(file);
                setError(null);
            } else {
                setError('File size exceeds 25MB limit.');
            }
        } else {
            setError('Please select a valid image file.');
        }
    };

    const handleClick = (index) => {
        const newClickedStates = [...clickedStates];
        newClickedStates[index] = !newClickedStates[index];
        setClickedStates(newClickedStates);
    };

    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const toggleuserRole = () => {
        setuserRoleActive(!userRoleActive);
        if (!userRoleActive) {
            setPersonalInfoActive(false);
            setmailingAddressActive(false);
        }
    };


    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);
        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
            setuserRoleActive(false);
        }
    };

    const [dropdownStates1, setDropdownStates1] = useState(users.map(() => ({ isOpen: false })));

    const handleDelete = async (userId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this user?');
        if (isConfirmed) {
            try {
                await axios.delete(`${BASE_URL}/accounts/user-delete/${userId}/`);
                setUsers(users.filter(user => user.id !== userId));

                fetchUserData()
                // window.location.reload();
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        }
    };


    const exportToCSV = (data) => {
        // Header including all fields
        const header = "ID,First Name,Last Name,DOB,Mobile,Email,State,City\n";

        // Map over data to create rows
        const rows = data.map(user => {
            // Access profile details
            const profile = user.profile || {}; // Fallback to empty object

            // Return a CSV row with all fields
            return `${user.id || ''},${user.first_name || ''},${user.last_name || ''},${profile.date_of_birth || ''},${user.phone || ''},${user.email || ''},${profile.state || ''},${profile.city || ''}`;
        }).join('\n');

        // Create CSV content
        const csvContent = `data:text/csv;charset=utf-8,${header}${rows}`;


        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "user_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const [searchQuery, setSearchQuery] = useState('');


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUserData = userData.filter(user =>
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 15;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUserData.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredUserData.length / usersPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Users</p>
                <div className='flex pt-6 gap-[12rem] p-6'>

                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage User</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add User</p>
                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${userRoleActive ? 'border-b-2 border-[#00549A]' : 'border-b-2 border-white'}`} onClick={toggleuserRole}>
                            <div className='flex gap-3'>
                                {userRoleActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${userRoleActive ? 'text-[#00549A]' : 'text-gray-600'}`}>User Role</p>
                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <Toaster position="top-center reverseOrder={false}" />
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>First Name</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="First Name"
                                        name="first_name"
                                        value={first_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-8 not-italic font-medium leading-[24px]'>Last Name</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Last Name"
                                        name="last_name"
                                        value={last_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Email</p>
                                <div className="pt-2">
                                    <input
                                        type="email"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Email"
                                        name="email"
                                        value={email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>Phone</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Phone Number"
                                        name="phone"
                                        value={phone}
                                        onChange={handleChange}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>Password</p>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Enter your password"
                                        value={generatedPassword} // Bind the generated password to the value attribute
                                        onChange={handleChange}
                                        name="password"
                                    />


                                </div>
                                <div className='pt-6 grid justify-items-end'>
                                    <button type="button" className='submit-btn-cgeneratepaswd px-6 py-2 rounded-[6px]' onClick={generatePassword}>
                                        <p className='text-[color:var(--White,#FFF)] text-[16px] not-italic font-normal leading-[24px]'>Generate Password</p>
                                    </button>
                                </div>
                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Reenter Password</p>
                                <div className="pt-2 relative">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Reenter Password"
                                        name="password2"
                                        value={password2}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="pt-2 relative">
                                    <select
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4] pr-[30px]"
                                        name="userrole"
                                        value={selectedRole} // Bind selectedRole to the value of the select element
                                        onChange={handleChange} // Update the handleChange function to handle changes
                                    >
                                        <option value="" disabled selected>Choose a role</option>
                                        {userRoles.map(role => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'>
                                        <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}


                {mailingAddressActive && (
                    <div className=' '>
                        <div>
                            {/* Search bar */}
                            <div className="mb-6">
                                <input
                                    type="text"
                                    placeholder="Search by username"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="search-input  w-[50%] p-4 rounded-[10px]"
                                />
                                <img src={search} alt="Search" className="absolute left-[43rem] top-[19rem] transform -translate-y-1/2  " />
                            </div>

                        </div>
                        <div className='pt-8'>
                            <button
                                className='bg-[#00549A] rounded-[10px] w-full py-4'
                                onClick={() => exportToCSV(userData)}
                            >
                                <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>Export User Data</p>
                            </button>
                        </div>

                        <div className='pt-8'>
                            <div className='bg-gray-100 py-4 w-full flex p-6 gap-2 pl-8 pr-8 '>
                                <p className='text-white  text-[18px] not-italic font-semibold leading-[normal]'>#ID</p>
                                <div className=''>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold pl-8  leading-[normal]'>Name</p>
                                </div>
                                <div>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-[15.5rem] text-[18px] not-italic font-semibold leading-[normal]'>Email</p>
                                </div>
                                <div>
                                    <p className='text-[color:var(--Gray,#58585A)]  text-[18px] not-italic font-semibold pl-[17.5rem]  leading-[normal]'>Contact</p>
                                </div>

                                <p className='text-[color:var(--Gray,#58585A)] text-[18px] pl-[15rem] not-italic font-semibold   leading-[normal]'>Status</p>
                            </div>
                        </div>
                        {currentUsers.map((user, index) => (
                            <div key={index} className='py-4 w-full flex p-6 items-center pl-8 pr-8'>
                                <div className='w-[60px]'>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>
                                        {(currentPage - 1) * usersPerPage + index + 1}
                                    </p>
                                </div>
                                <div className='pl-4'>
                                    {user.profile.image ? (
                                        <img src={BASE_URL + user.profile.image} alt="User Image" className='w-[60px] rounded-[60px]' />
                                    ) : (
                                        <img src={pro} alt="Default Icon" className=' w-[60px] rounded-[60px]' />
                                    )}
                                </div>

                                <div className='w-[18%] pl-4'>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>{`${user.first_name} ${user.last_name}`}</p>
                                </div>
                                <div className='w-[25%]'>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>{user.email}</p>
                                </div>
                                <div className='w-[15%]'>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>{user.phone}</p>
                                </div>

                                <div className='pl-10'>

                                </div>
                                <div className='pl-[4rem]'>
                                    <button className='px-6 py-1 rounded-[4px]' style={{ background: "rgba(26, 183, 141, 0.10)" }}>
                                        <p className='text-[color:var(--Green,#1AB78D)] text-[16px] not-italic font-medium leading-[normal]'>Active</p>
                                    </button>
                                </div>
                                <img src={img} alt="" className='pl-[5rem]' onClick={() => handleToggleDropdown(index)} />
                                {dropdownStates[index].isOpen && (
                                    <div className=' absolute right-[14rem] bg-white rounded-[8px] z-60 p-6 w-[20%] shadow-2xl'>
                                        <div className='border border-gray-300 rounded-[8px]'>
                                            <Link to={`/user-editprofile/${user.id}`}>
                                                <div className='flex p-4 '>
                                                    <img src={edi} alt="" />
                                                    <p className='text-[color:var(--Black,#222)] pl-4 pt-2 text-[14px] not-italic font-semibold leading-[normal]'>Profile Edit</p>
                                                </div>
                                            </Link>
                                            <hr />
                                            <div className='flex p-4' onClick={() => handleDelete(user.id)}>
                                                <img src={dil} alt="" />
                                                <p className='text-[color:var(--Black,#222)] pl-4 pt-2 text-[14px] not-italic font-semibold leading-[normal]'>Delete</p>
                                            </div>
                                            <hr />
                                            <Link to="/usertype">
                                                <div className='flex p-4 '>
                                                    <img src={ch} alt="" />
                                                    <p className='text-[color:var(--Black,#222)] pl-4 pt-2 text-[14px] not-italic font-semibold leading-[normal]'>Change User Type</p>
                                                </div>
                                            </Link>
                                            <hr />
                                            <Link to={`/user-status/${user.id}`}>
                                                <div className='flex p-4'>
                                                    <img src={st} alt="" />
                                                    <p className='text-[color:var(--Black,#222)] pl-4 pt-2 text-[14px] not-italic font-semibold leading-[normal]'>Status</p>
                                                </div>
                                            </Link>
                                            <hr />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="pagination flex justify-center items-center space-x-4 mt-6">
                            <button
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                            >
                                <span className="pagination-icon">&#8249;</span> {/* Left arrow */}
                            </button>
                            <span className="pagination-info">
                                Page {currentPage} of {Math.ceil(filteredUserData.length / usersPerPage)}
                            </span>
                            <button
                                onClick={nextPage}
                                disabled={currentPage === Math.ceil(filteredUserData.length / usersPerPage)}
                                className={`pagination-arrow ${currentPage === Math.ceil(filteredUserData.length / usersPerPage) ? 'disabled' : ''}`}
                            >
                                <span className="pagination-icon">&#8250;</span> {/* Right arrow */}
                            </button>
                        </div>


                    </div>
                )}

                <div>

                </div>
            </div>
        </div>
    );
};

export default User;
