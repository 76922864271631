import React, { useState, useEffect, useRef } from "react";
import Logo3 from "../../../src/assets/images/car1.webp";
import SliderImage from "../../../src/assets/images/car2.webp";
import Slider from "../../../src/assets/images/car3.webp";
import { Carousel } from "flowbite-react";
import Logo25 from "../../../src/assets/images/Frame 10.svg";
import Logo26 from "../../../src/assets/images/Frame 82.svg";
import "./Card1.css";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from '../../Utils/Config';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';

const Card1 = () => {
    const [selectedImage, setSelectedImage] = useState(Logo3);
    const [eventData, setEventData] = useState([]);
    const [banner, setBanner] = useState([]);
    const [isUnderMaintenance, setIsUnderMaintenance] = useState(true);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedImage((prevImage) =>
                prevImage === Logo3 ? SliderImage : Logo3
            );
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const [response1, response2] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventslist/`),
                    axios.get(`${BASE_URL}/admins/general_events/`)
                ]);

                const combinedEvents = [
                    ...response1.data.upcoming_events,
                    ...response2.data.upcoming_events
                ];

                setEventData(combinedEvents);
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventData();
    }, []);
    useEffect(() => {
        const fetchBanner = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/banner/`);
                setBanner(response.data);
                console.log("banner", response.data);
            } catch (error) {
                console.error('Error fetching banner:', error);
            }
        };

        fetchBanner();
    }, []);


    const toggleMaintenanceMode = () => {
        setIsUnderMaintenance((prevMode) => !prevMode);
    };


    const sortedEventData = [...eventData].sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <div className="w-full h-full bg-[#f4f4f4] ">

            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 pt-6 gap-6">
                    <div className="grid grid-col">
                        <div className="towards">
                            <motion.div
                                className="rounded-[30px] text-start text-[#222] text-[51px] not-italic font-bold leading-[56px]"
                                initial={{ opacity: 0, y: 50 }}
                                animate={controls}
                                transition={{ duration: 1}}
                                ref={ref}
                            >

                                Towards Clinical <br />
                                Pharmacy Excellence

                                <p className="text-[#58585A] text-start hometext text-[18px] not-italic font-normal leading-[27px]">
                                    The Federation of Clinical Pharmacists in India (FCPI) is a non-profitable, professional pharmacy organization composed of a bunch of passionate   pharmacists involved in diverse clinical pharmacy...
                                </p>
                            </motion.div>

                        </div>
                        <Carousel
                            indicators={false}
                            showPrevNext={false}
                            className="hightcurser hide-mob mt-6 mb-6"
                            autoPlay={true} // Enables automatic sliding
                            infiniteLoop={true} // Allows infinite scrolling
                            interval={3000} // Time between each slide (in milliseconds)
                            transitionTime={1000} // Transition duration for each slide (in milliseconds)
                            swipeable={true} // Allows swipe gestures on touch devices
                            stopOnHover={true} // Stops auto-playing when hovered
                        >
                            {banner && banner.map((item, index) => {
                                const imageUrl = item.banner && item.banner.startsWith('http') ? item.banner : `${BASE_URL}/${item.banner}`;
                                console.log(`Rendering banner image: ${imageUrl}`);
                                return (
                                    <div key={index} className="">
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={imageUrl}
                                                alt={`Slide ${index + 1}`}
                                                className="w-full h-full rounded-[30px]"
                                                style={{ aspectRatio: "38/9" }}
                                            />
                                        </a>
                                    </div>
                                );
                            })}
                        </Carousel>

                    </div>
                    <div className="flex flex-col w-[100%] home-img rounded-[30px] aspect-[2] bg-card1-background bg-cover responsive-card">

                        <motion.div
                            className="aspect-[2] bg-card2-background bg-cover backdrop-blur-xl p-4 event-img  "
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                            <div className="flex justify-between">
                                <p className="text-[#00549A]   text-[26px]   not-italic font-semibold leading-[normal]">Event Calendar</p>
                                <Link to="/events">
                                    <div className="pl-8  ">
                                        <motion.div
                                            initial={{ rotate: 0 }}
                                            animate={{ rotate: 360 }}  // Rotate the image 360 degrees
                                            transition={{ duration: 1, ease: "easeInOut" }}  // Smooth rotation over 1 second
                                        >
                                            <img src={Logo25} alt="Logo" className="rounded-[30px]" />
                                        </motion.div>
                                    </div>
                                </Link>
                            </div>
                            <div className="pt-8">
                                <motion.div
                                    className=" "
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={controls}
                                    transition={{ duration: 1 }}
                                    ref={ref}
                                >
                                    {Array.isArray(sortedEventData) && sortedEventData.slice(0, 3).map((event, index) => (
                                        <div key={index} className="rounded-[180px] round-m-30 bg-[#FFF] w-[398px] mob-width-100 mb-5">
                                            <div className="flex mob-center-cal">

                                                <div className="pt-3 pl-3 pb-3 pt-pb-0">
                                                    <img src={Logo26} alt="Logo" className="rounded-[30px]" />
                                                </div>
                                                <Link to={`/events-detail/${event.slug}`}>
                                                    <div className="p-1 p-1-m">
                                                        <p className="text-[#222] text-[14px] mob-text-12 not-italic font-normal leading-[normal]">{event?.event_name}</p>
                                                        <div className="justify-start mr-28 pt-1">
                                                            <button className="bg-blue-50 inline-flex gap-[10px] p-[3px] rounded-[14px]">
                                                                <p className="text-[#00549A] text-[12px] not-italic font-normal leading-[normal]">{event?.date}</p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </motion.div>
                            </div>


                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card1;
