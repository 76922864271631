import React, { useState, useEffect, useRef } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import axios from "axios";
import { useParams, Link } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import './Events.css';
import { motion, useAnimation, useInView } from 'framer-motion';

const Card1 = () => {
    const [activeButton, setActiveButton] = useState('webinar');
    const [showYearDropdown, setShowYearDropdown] = useState({
        '2024': false,
        '2023': false,
        '2022': false,
    });
    const [events, setEvents] = useState({});
    const [eventstoday, setEventsToday] = useState({});
    const [showEventDropdown, setShowEventDropdown] = useState([]);
    const [blogs, setBlogs] = useState({});
    const [loading, setLoading] = useState(true);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const { slug } = useParams();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forumsyear/${slug}/`);
                const fetchedEvents = response.data.events;
                // console.log(fetchedEvents);
                setEvents(fetchedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, [slug]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/blogsyear/${slug}/`);
                // console.log("Blogyear", response);
                const blogsData = response.data;


                const groupedBlogs = blogsData.reduce((acc, blog) => {
                    const year = new Date(blog.date).getFullYear();
                    if (!acc[year]) acc[year] = [];
                    acc[year].push(blog);
                    return acc;
                }, {});

                setBlogs(groupedBlogs);
                setLoading(false);
                console.log("Blogs", groupedBlogs);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, [slug]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forum/${slug}/events/`);
                const fetchedEvents = response.data.events;
                const sortedEvents = fetchedEvents.sort((a, b) => {
                  
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;  
                });
                // console.log("today events", fetchedEvents);
                setEventsToday(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, [slug]);

    const [eventsweek, setEventsWeek] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forumweek/${slug}/events/`);
                const fetchedEvents = response.data.events;
                const sortedEvents = fetchedEvents.sort((a, b) => {
                  
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;  
                });
                // console.log("week events", fetchedEvents);
                setEventsWeek(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, [slug]);


    const [eventmonth, setEventsMonth] = useState({});

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forummonth/${slug}/events/`);
                const fetchedEvents = response.data.events;
                const sortedEvents = fetchedEvents.sort((a, b) => {
                  
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;  
                });
                console.log("month events", fetchedEvents);
                setEventsMonth(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, [slug]);

    const [eventyear, setEventsYear] = useState({});
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forumyear/${slug}/events/`);
                const fetchedEvents = response.data.events;
            
                const sortedEvents = fetchedEvents.sort((a, b) => {
                  
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;  
                });

                setEventsYear(sortedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [slug]);

    const handleYearSelect = (year) => {
        const updatedShowYearDropdown = { ...showYearDropdown };
        updatedShowYearDropdown[year] = !showYearDropdown[year];
        setShowYearDropdown(updatedShowYearDropdown);
    };

    const toggleYearDropdown = (year) => {
        setShowYearDropdown({ ...showYearDropdown, [year]: !showYearDropdown[year] });
    };

    const toggleDropdown = (button) => {
        setActiveButton(activeButton === button ? '' : button);
        setShowYearDropdown({ '2024': false, '2023': false, '2022': false });
    };

    const toggleEventDropdown = (index) => {
        const updatedShowEventDropdown = [...showEventDropdown];
        updatedShowEventDropdown[index] = !updatedShowEventDropdown[index];
        setShowEventDropdown(updatedShowEventDropdown);
    };

    // Create a ref for each event to manage dropdowns
    const eventRefs = useRef(events && Object.keys(events).flatMap(year => events[year].map(() => React.createRef())));
    const [dropdownVisible, setDropdownVisible] = useState({
        today: true,
        thisWeek: false,
        thisMonth: false,
        thisYear: false,
    });
    const [activeButton1, setActiveButton1] = useState('today');
    const toggleDropdown1 = (button) => {
        setActiveButton1(button);
        setDropdownVisible((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map(key => [key, key === button ? !prevState[key] : false])),
        }));
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className='evventcalnderdiv gap-[7rem] md:gap-6 pt-4 pp-6 bg-white rounded-[30px]'>
                        <div className='width65'>
                            <div className='text-start pad-6'>
                                <p className="text-[#222] text-[34px] not-italic font-semibold leading-[normal]">Events</p>
                                <div className='grid grid-cols-4 pt-8'>
                                    <button
                                        className={`bg-[#f4f4f4] smooth events-btn-pad rounded-l-lg ${activeButton === 'webinar' ? 'border-b-2 border-blue-500' : ''}`}
                                        style={{ color: activeButton === 'webinar' ? '#00549A' : '#000' }}
                                        onClick={() => toggleDropdown('webinar')}
                                    >
                                        <p className="btn-17-text not-italic font-semibold leading-[normal]">
                                            Webinar archives
                                        </p>
                                    </button>
                                    <button
                                        className={`bg-[#f4f4f4] smooth ${activeButton === 'blog' ? 'border-b-2 border-blue-500' : ''}`}
                                        style={{ color: activeButton === 'blog' ? '#00549A' : '#000' }}
                                        onClick={() => toggleDropdown('blog')}
                                    >
                                        <p className='btn-17-text not-italic font-semibold leading-normal'>
                                            Blog archives
                                        </p>
                                    </button>
                                    <button
                                        className={`bg-[#f4f4f4] smooth ${activeButton === 'newsletter' ? 'border-b-2 border-blue-500' : ''}`}
                                        style={{ color: activeButton === 'newsletter' ? '#00549A' : '#000' }}
                                        onClick={() => toggleDropdown('newsletter')}
                                    >
                                        <p className='btn-17-text not-italic font-semibold leading-normal'>
                                            Newsletter archives
                                        </p>
                                    </button>

                                    <button
                                        className={`bg-[#f4f4f4] smooth rounded-r-lg ${activeButton === 'podcast' ? 'border-b-2 border-blue-500' : ''}`}
                                        style={{ color: activeButton === 'podcast' ? '#00549A' : '#000' }}
                                        onClick={() => toggleDropdown('podcast')}
                                    >
                                        <p className={`text-${activeButton === 'podcast' ? 'blue' : 'black'} btn-17-text not-italic font-semibold leading-[normal]`}>
                                            Podcast archives
                                        </p>
                                    </button>

                                    {/* Add buttons for other archive types */}
                                </div>
                                {activeButton === 'webinar' && (
                                    <>
                                        {Object.keys(showYearDropdown)
                                            .sort((a, b) => Number(b) - Number(a)) // Sort years in descending order
                                            .map((year) => (
                                                <React.Fragment key={year}>

                                                    <motion.div
                                                        className="flex justify-between border-b-2 border-gray-100 items-center"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 4 }}
                                                        transition={{
                                                            duration: 1,
                                                            ease: "easeInOut",
                                                        }}
                                                        ref={ref}
                                                    >
                                                        <p
                                                            className="p-6 cursor-pointer text-[#333] smooth font-semibold"
                                                            onClick={() => handleYearSelect(year)}
                                                        >
                                                            {year}
                                                        </p>
                                                        <p className="text-[16px] pt-6 not-italic smooth font-semibold text-[#626262] leading-[normal] ml-auto" onClick={() => toggleYearDropdown(year)}>
                                                            {showYearDropdown[year] ? <FaAngleUp /> : <FaAngleDown />}
                                                        </p>
                                                    </motion.div>
                                                    {showYearDropdown[year] && (

                                                        <motion.div
                                                            className="flex flex-col smooth mt-4 pl-12"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 4 }}
                                                            transition={{
                                                                duration: 1,
                                                                ease: "easeInOut",
                                                            }}
                                                            ref={ref}
                                                        >
                                                            {events[year] && events[year].map((event, index) => (
                                                                <div key={index} className="">
                                                                    <div className="flex justify-between smooth transition" onClick={() => toggleEventDropdown(index)}>
                                                                        <Link to={`/events-detail/${event.slug}/`}>
                                                                            <p className="pt-4 pb-4 text-[16px] smooth not-italic font-semibold leading-[normal]" ref={eventRefs.current[index]}>
                                                                                {event.event_name}
                                                                            </p>
                                                                        </Link>
                                                                        {/* <p className="pb-4 pt-4 text-[16px] not-italic font-semibold leading-[normal] ml-auto" onClick={() => toggleEventDropdown(index)}>
                                                                            {showEventDropdown[index] ? <FaAngleUp /> : <FaAngleDown />}
                                                                        </p> */}
                                                                    </div>
                                                                    {/* {showEventDropdown[index] && (
                                                                        <div className="bg-white rounded-lg mt-2 px-4 py-2">
                                                                            <p>{event.description}</p>
                                                                        </div>
                                                                    )} */}
                                                                </div>
                                                            ))}
                                                        </motion.div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </>
                                )}
                                {activeButton === 'blog' && (
                                    <>
                                        {Object.keys(showYearDropdown)
                                            .sort((a, b) => Number(b) - Number(a))
                                            .map((year) => (
                                                <React.Fragment key={year}>

                                                    <motion.div
                                                        className="flex justify-between border-b-2 border-gray-100 items-center"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 4 }}
                                                        transition={{
                                                            duration: 1,
                                                            ease: "easeInOut",
                                                        }}
                                                        ref={ref}
                                                    >
                                                        <p
                                                            className="pp-6 cursor-pointer text-[#333] font-semibold"
                                                            onClick={() => handleYearSelect(year)}
                                                        >
                                                            {year}
                                                        </p>
                                                        <p className="text-[16px] pt-6 not-italic font-semibold text-[#626262] leading-[normal] ml-auto" onClick={() => toggleYearDropdown(year)}>
                                                            {showYearDropdown[year] ? <FaAngleUp /> : <FaAngleDown />}
                                                        </p>
                                                    </motion.div>
                                                    {showYearDropdown[year] && (

                                                        <motion.div
                                                            className="flex flex-col mt-4 pl-12"
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 4 }}
                                                            transition={{
                                                                duration: 1,
                                                                ease: "easeInOut",
                                                            }}
                                                            ref={ref}
                                                        >
                                                            {blogs[year] && blogs[year].map((blog, index) => (
                                                                <div key={index} className="">
                                                                    <div className="flex justify-between" onClick={() => toggleEventDropdown(index)}>
                                                                        <Link to={`/blogs-detail/${blog.slug}`}>
                                                                            <p className="pt-4 pb-4 text-[16px] not-italic font-semibold leading-[normal]" ref={eventRefs.current[index]}>
                                                                                {blog.title}
                                                                            </p>
                                                                        </Link>
                                                                        {/* <p className="pb-4 pt-4 text-[16px] not-italic font-semibold leading-[normal] ml-auto" onClick={() => toggleEventDropdown(index)}>
                                                                            {showEventDropdown[index] ? <FaAngleUp /> : <FaAngleDown />}
                                                                        </p> */}
                                                                    </div>

                                                                </div>
                                                            ))}
                                                        </motion.div>
                                                    )}
                                                </React.Fragment>
                                            ))}


                                    </>

                                )}
                            </div>
                        </div>

                        <div className='width35'>
                            <div className='pad-6 '>
                                <p className='text-[#222] text-start text-[32px] not-italic font-semibold leading-[normal] ' >Event Calendar
                                </p>
                                <div className='pt-6'>

                                    <div className='border border-[#CBCBCB] rounded-[12px] pall'>
                                        <div className=' pb-5  '>
                                            <p className='text-[#222] text-start text-[23px]  not-italic font-semibold leading-[normal]'  >Events</p>
                                            <div className='flex env-block events-items-date pt-4'>
                                                <div className='evn-item'>
                                                    <button
                                                        className={`border smooth border-[#CBCBCB] transition rounded-[102px] pxx-44 py-1 ${activeButton1 === 'today' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                        onClick={() => toggleDropdown1('today')}>
                                                        <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'today' ? 'text-[#1AB78D]' : ''}`}>Today</p>
                                                    </button>

                                                    <button
                                                        className={`border border-[#CBCBCB] smooth transition rounded-[102px] pxx-44 py-1 ${activeButton1 === 'thisWeek' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                        onClick={() => toggleDropdown1('thisWeek')}>
                                                        <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisWeek' ? 'text-[#1AB78D]' : ''}`}>This Week</p>
                                                    </button>
                                                </div>

                                                <div className='evn-item'>

                                                    <button
                                                        className={`border border-[#CBCBCB] smooth transition rounded-[102px] pxx-44 py-1 ${activeButton1 === 'thisMonth' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                        onClick={() => toggleDropdown1('thisMonth')}>
                                                        <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisMonth' ? 'text-[#1AB78D]' : ''}`}>This Month</p>
                                                    </button>
                                                    <button
                                                        className={`border border-[#CBCBCB] smooth transition  rounded-[102px] pxx-44 py-1 ${activeButton1 === 'thisYear' ? 'border-none bg-[#DFF9F2]' : ''}`}
                                                        onClick={() => toggleDropdown1('thisYear')}>
                                                        <p className={`text-[#58585A] ev-date not-italic font-normal leading-[normal] ${activeButton === 'thisYear' ? 'text-[#1AB78D]' : ''}`}>This Year</p>
                                                    </button>
                                                </div>
                                            </div>


                                        </div>

                                        <hr className='w-full mt-2' />


                                        {dropdownVisible.today && (


                                            <motion.div
                                                className="max-h-[49vh] overflow-y-auto p-l-10"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='   mt-4 eventitems'>
                                                    {eventstoday.length > 0 ? (
                                                        eventstoday.map(event => (
                                                            <Link to={`/events-detail/${event.slug}/`}>
                                                                <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                    <div className='wh-70'>
                                                                        <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                            <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                                {new Date(event.date).getDate()}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                                {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                        <div className='eventcontent'>
                                                                            <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                                {event.event_name}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                                {event.forum_name}
                                                                            </p>
                                                                        </div>
                                                                        <div className='pt-2'>
                                                                            <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                                <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                    {formatDate(event.date)}
                                                                                </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Link>

                                                        ))
                                                    ) : (
                                                        <p>No events  today</p>
                                                    )}

                                                </div>

                                            </motion.div>
                                        )}


                                        {dropdownVisible.thisWeek && (
                                            <motion.div
                                                className="max-h-[49vh] overflow-y-auto p-l-10"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='   mt-4 eventitems'>
                                                    {eventsweek.length > 0 ? (
                                                        eventsweek.map(event => (
                                                            <Link to={`/events-detail/${event.slug}/`}>
                                                                <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                    <div className='wh-70'>
                                                                        <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                            <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                                {new Date(event.date).getDate()}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                                {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                        <div className='eventcontent'>
                                                                            <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                                {event.event_name}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                                {event.forum_name}
                                                                            </p>
                                                                        </div>
                                                                        <div className='pt-2'>
                                                                            <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                                <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                    {formatDate(event.date)}
                                                                                </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Link>

                                                        ))
                                                    ) : (
                                                        <p>No events this week</p>
                                                    )}

                                                </div>

                                            </motion.div>
                                        )}
                                        {dropdownVisible.thisMonth && (


                                            <motion.div
                                                className="max-h-[49vh] overflow-y-auto p-l-10"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='   mt-4 eventitems'>
                                                    {eventmonth.length > 0 ? (

                                                        eventmonth.map(event => (
                                                            <Link to={`/events-detail/${event.slug}/`}>
                                                                <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                    <div className='wh-70'>
                                                                        <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                            <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                                {new Date(event.date).getDate()}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                                {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                        <div className='eventcontent'>
                                                                            <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                                {event.event_name}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                                {event.forum_name}
                                                                            </p>
                                                                        </div>
                                                                        <div className='pt-2'>
                                                                            <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                                <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                    {formatDate(event.date)}
                                                                                </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Link>

                                                        ))
                                                    ) : (
                                                        <p>No events this month</p>
                                                    )}

                                                </div>

                                            </motion.div>
                                        )}
                                        {dropdownVisible.thisYear && (

                                            <motion.div
                                                className="max-h-[49vh] overflow-y-auto p-l-10"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 4 }}
                                                transition={{
                                                    duration: 1,
                                                    ease: "easeInOut",
                                                }}
                                                ref={ref}
                                            >
                                                <div className='   mt-4 eventitems'>
                                                    {eventyear.length > 0 ? (

                                                        eventyear.map(event => (
                                                            <Link to={`/events-detail/${event.slug}/`}>
                                                                <div key={event.id} className='flex justify-items-center gap-2 pt-4 event-item'>
                                                                    <div className='wh-70'>
                                                                        <div className=' text-center wh-60 rounded-[100px] bg-[#DFF9F2] text-[#1AB78D]'>
                                                                            <p className='text-[22px] m-text-18 not-italic font-semibold leading-[normal]'>
                                                                                {new Date(event.date).getDate()}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-[14px] not-italic font-normal leading-[normal]'>
                                                                                {new Date(event.date).toLocaleString('default', { month: 'short' })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-30 pl-4 w-fullsection m-b-block-100'>
                                                                        <div className='eventcontent'>
                                                                            <p className='text-[#222] text-start text-[22px] m-font-20 not-italic font-medium leading-[normal]'>
                                                                                {event.event_name}
                                                                            </p>
                                                                            <p className='text-[#1AB78D] text-start text-[20px] m-font-18 m-pt-14 not-italic font-medium leading-[normal]'>
                                                                                {event.forum_name}
                                                                            </p>
                                                                        </div>
                                                                        <div className='pt-2'>
                                                                            <button className='bg-[#C5E5FF] rounded-[74px] eventdate'>
                                                                                <p className='text-[#00549A] evn-cal-btn not-italic font-normal leading-[normal]'>
                                                                                    {formatDate(event.date)}
                                                                                </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Link>

                                                        ))
                                                    ) : (
                                                        <p>No events this year</p>
                                                    )}

                                                </div>

                                                </motion.div>
                                        )}
                                    </div>

                                </div>





                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card1;




