import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import { BASE_URL } from '../../Utils/Config';
import gr from "../../assets/images/graph4.png";

const Card3 = () => {
  const [totalEvents, setTotalEvents] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [speakerCount, setSpeakerCount] = useState(0);
  const [newsletterCount, setNewsletterCount] = useState(0);
  const [podcastCount, setPodcastCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [chartData, setChartData] = useState({ labels: [], series: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch general data
        const [eventResponse, memberResponse, speakerResponse, newsletterResponse, podcastResponse, userResponse] = await Promise.all([
          axios.get(`${BASE_URL}/admins/total-events/`),
          axios.get(`${BASE_URL}/admins/member-count/`),
          axios.get(`${BASE_URL}/admins/speaker-count/`),
          axios.get(`${BASE_URL}/admins/newsletter-count/`),
          axios.get(`${BASE_URL}/admins/podcast-count/`),
          axios.get(`${BASE_URL}/accounts/user-count/`)
        ]);

        setTotalEvents(eventResponse.data.total_events);
        setMemberCount(memberResponse.data.member_count);
        setSpeakerCount(speakerResponse.data.speaker_count);
        setNewsletterCount(newsletterResponse.data.newsletter_count);
        setPodcastCount(podcastResponse.data.podcast_count);
        setUserCount(userResponse.data.user_count);

         
        const response = await axios.get(`${BASE_URL}/accounts/monthly-user-count/`);
        const { months, counts } = response.data;

        
        const displayMonths = ["Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"];
        const filteredCounts = displayMonths.map((month, index) => {
          const monthIndex = months.indexOf(month);
          return monthIndex !== -1 ? counts[monthIndex] : 0;
        });

        setChartData({
          labels: displayMonths,
          series: [{ name: 'User Counts', data: filteredCounts }]
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350
    },
    xaxis: {
      categories: chartData.labels,
      title: {
        
      }
    },
    yaxis: {
      title: {
        
      },
      min: 0,
      max: 300,
      labels: {
        formatter: function(value) {
          // Custom tick values
          const customTicks = [0, 50, 100, 150, 200, 250, 300];
          // Return the value if it exists in the customTicks array
          return customTicks.includes(value) ? value : '';
        }
      },
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        }
      }
    }
  };
  

  return (
    <div className="w-full h-full pt-6 bg-[#f4f4f4]">
      <div className="container">
        <div className="flex gap-6 ">
          <div className='bg-white rounded-[30px] w-[60%] relative'>
            <div className='p-16 '>
              <div className='grid grid-cols-3 pt-4'>
                <div className=' '>
                  <p className='text-[#58585A] text-[18px] '>Total Events</p>
                  <p className='text-[#00549A] text-[36px] font-semibold'>
                    {totalEvents}
                  </p>
                </div>
                <div>
                  <p className='text-[#58585A] text-[18px]'>FCPI Members</p>
                  <p className='text-[#00549A] text-[36px] font-semibold'>{memberCount}</p>
                </div>
                <div>
                  <p className='text-[#58585A] text-[18px]'>Speakers Collaborated</p>
                  <p className='text-[#00549A] text-[36px] font-semibold'>{speakerCount}</p>
                </div>
              </div>
              <div className='grid grid-cols-3 pt-14'>
                <div>
                  <p className='text-[#58585A] text-[18px]'>Newsletters</p>
                  <p className='text-[#00549A] text-[36px] font-semibold'>{newsletterCount}</p>
                </div>
                <div>
                  <p className='text-[#58585A] text-[18px]'>Podcasts</p>
                  <p className='text-[#00549A] text-[36px] font-semibold'>{podcastCount}</p>
                </div>
              </div>
            </div>
            <img src={gr} alt="" className='absolute right-0 top-56 transform -translate-y-1/2' />
          </div>

          <div className='bg-white rounded-[30px] w-[40%]'>
            <div className='p-8'>
              <div className='flex gap-4'>
                <p className='text-[#58585A] text-[24px] '>Website Users</p>
                <p className='text-[#1AB78D] text-[24px] font-bold'>{userCount}</p>
              </div>
              <div className='pt-8'>
                <p className='text-[#686868] text-[13px]'>User Count</p>
                <ApexCharts
                  options={chartOptions}
                  series={chartData.series}
                  type="bar"
                  height={216}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card3;
