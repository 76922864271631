import React, { useState, useEffect, useRef } from 'react';
import imag from "../../../../assets/images/user-circle.png";
import imag1 from "../../../../assets/images/mail.png";
import log from "../../../../assets/images/profile image.png";
import edit from "../../../../assets/images/edit-user.png"
import { BASE_URL } from '../../../../Utils/Config';
import axios from 'axios';
import { toast, Toaster } from "react-hot-toast";
import { getUserProfile } from '../../../../helpers/auth';
import "./MyProfile.css";
import pro from "../../../../assets/images/profile.png"
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';


const Profile = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [userData, setUserData] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const [showFormPopup, setShowFormPopup] = useState(false);
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const openFormPopup = () => {
        setFormSubmitted(false);
        setShowFormPopup(true);
    };

    const closeFormPopup = () => {
        setShowFormPopup(false);
        setFormSubmitted(false);
    };

    const [editMode, setEditMode] = useState(false);


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        primary_position: '',
        state: '',
        primary_pharmacy_degree: '',
        secondary_pharmacy_degree: '',
        additional_degrees: '',
        city: '',
        country: '',
        pincode: '',
        current_work_institution: '',
        pharmacy_college_name: '',
        pharmacy_college_degree: '',
        image: ''
    });

    const fetchUserProfile = async () => {
        try {
            const profileData = await getUserProfile();
            setUser(profileData.user);
            setProfile(profileData.profile);

            // Assuming profileData contains all the necessary fields
            setFormData({
                first_name: profileData.user.first_name || '',
                last_name: profileData.user.last_name || '',
                date_of_birth: profileData.profile.date_of_birth || '',
                state: profileData.profile.state || '',
                primary_pharmacy_degree: profileData.profile.primary_pharmacy_degree || '',
                secondary_pharmacy_degree: profileData.profile.secondary_pharmacy_degree || '',
                additional_degrees: profileData.profile.additional_degrees || '',
                current_work_institution: profileData.profile.current_work_institution || '',
                city: profileData.profile.city || '',
                country: profileData.profile.country || '',
                pincode: profileData.profile.pincode || '',
                pharmacy_college_name: profileData.profile.pharmacy_college_name || '',
                pharmacy_college_degree: profileData.profile.pharmacy_college_degree || ''
            });

            toast.success('User profile fetched successfully');
        } catch (error) {
            console.error('Error fetching user profile:', error);
            toast.error('Error fetching user profile');
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, []);


    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const authToken = localStorage.getItem('authToken');

            if (!authToken || !user.id || !profile.id) {
                toast.error('User not authenticated');
                return;
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                if (key !== 'image') {
                    formDataToSend.append(key, formData[key]);
                }
            });

            if (formData.image instanceof File) {
                formDataToSend.append('image', formData.image);
            }

            const res = await axios.put(`${BASE_URL}/accounts/user/${user.id}/`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            toast.success('Profile updated successfully');
            fetchUserProfile();
            setEditMode(false);
            setFormSubmitted(true);
            setShowFormPopup(false);

        } catch (error) {
            console.error('Error updating profile:', error.response?.data || error.message);
            toast.error('Error updating profile');
        } finally {
            setLoading(false);
        }
    };





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };
    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setSelectedImage(imageUrl);

            setFormData(prevFormData => ({
                ...prevFormData,
                image: imageFile,
            }));
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };



    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6 mob-m-p-6 '>
            <div className='bg-white p-6 mob-m-p-66 relative'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>My Profile</p>
                <div className='flex pt-12 gap-16 mm-t-10 line-1 mob-p-8-1'>
                    <div className='flex gap-3'>
                        <button className={`relative pt-m-10 p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex m-block gap-3 mob-text'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Personal Information</p>

                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex m-block gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Mailing Address</p>

                            </div>
                        </button>
                    </div>
                </div>
                <hr className='absolute top-48 right-0 bg-red-600 z-10' />
                {personalInfoActive && (
                    <div className=' '>
                        <div>
                            <div className='flex m-block pt-8 mm-block'>

                                <motion.div
                                    className="relative"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <div className='user-img'>
                                        {profile?.image ? (
                                            <img src={BASE_URL + profile?.image} alt="User Image" />
                                        ) : (
                                            <img src={pro} alt="Default Icon" className='w-[40%]' />
                                        )}
                                    </div>
                                    <div className='  pt-3     '>
                                        <button className="text-white bg-[#00549A] rounded-[6px] px-11  p-3 text-center text-[20px] not-italic font-semibold leading-[normal]" onClick={openFormPopup}>Edit Profile</button>
                                    </div>
                                    {/* <img src={edit} alt="" className='absolute right-444 bottom-0 z-10 cursor-pointer' onClick={openFormPopup} /> */}
                                </motion.div>

                                {showFormPopup && !formSubmitted && (

                                    <motion.div
                                        className="form-popup mt-50 bg-black bg-opacity-50"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 4 }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeInOut",
                                        }}
                                        ref={ref}
                                    >
                                        <div className='form-content'>
                                            <div className='modal-content'>
                                                <button className='bg-[#00549A] text-white close-btn font-semibold p-3 rounded-[10px]' onClick={closeFormPopup}>Close</button>
                                                <div className=''>


                                                    <div className='profile-section'>
                                                        {selectedImage ? (
                                                            <img src={selectedImage} className='pro-img-edit' alt="User Image" />
                                                        ) : (
                                                            profile?.image ? (
                                                                <img src={BASE_URL + profile?.image} className='pro-img-edit' alt="User Image" />
                                                            ) : (
                                                                <img src={pro} alt="Default Icon" className='w-[40%]' />
                                                            )
                                                        )}
                                                    </div>

                                                    <div className="pt-1">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleImageChange}
                                                            className="input-file bg-[#F4F4F4]"
                                                        />
                                                    </div>


                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 pt-4 w-full'>
                                                    <div className=''>
                                                        <div className=''>

                                                            <div className="text-start">
                                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>First Name</p>
                                                                <div className="pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="first_name"
                                                                        value={formData.first_name}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="First Name"
                                                                    />


                                                                </div>
                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Date of Birth</p>
                                                                <div className="pt-2">
                                                                    <input
                                                                        type="date"
                                                                        name="date_of_birth"
                                                                        value={formData.date_of_birth}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="Date of Birth"
                                                                    />


                                                                </div>

                                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>State</p>
                                                                <div className=" pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="state"
                                                                        value={formData.state}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="state"
                                                                    />
                                                                </div>



                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Primary Pharmacy Degree</p>
                                                                <div className="relative pt-2">
                                                                    <select
                                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        name="primary_pharmacy_degree"
                                                                        defaultValue={formData.primary_pharmacy_degree}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option value="" disabled>Select Primary Pharmacy Degree</option>
                                                                        <option value="Bachelors in Pharmacy">Bachelors in Pharmacy</option>
                                                                        <option value="Doctor of Pharmacy">Doctor of Pharmacy</option>

                                                                    </select>

                                                                </div>

                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Secondary Pharmacy Degree</p>
                                                                <div className="relative pt-2">
                                                                    <select
                                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        name="secondary_pharmacy_degree"
                                                                        value={formData.secondary_pharmacy_degree}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option value="" disabled>Select secondaryPharmacyDegree</option>
                                                                        <option value="Doctor of Pharmacy Post Baccalaureate">Doctor of Pharmacy Post Baccalaureate</option>
                                                                        <option value="Masters in Pharmacy">Masters in Pharmacy</option>

                                                                    </select>

                                                                </div>
                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Additional Degrees</p>
                                                                <div className="pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="additional_degrees"
                                                                        value={formData.additional_degrees}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="additionalDegrees"
                                                                    />
                                                                </div>

                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>current Work institution</p>
                                                                <div className="relative pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="current_work_institution"
                                                                        value={formData.current_work_institution}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="current_work_institution"
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='pl-8'>
                                                        <div className=''>

                                                            <div className="text-start">
                                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Last Name</p>
                                                                <div className="pt-2">

                                                                    <input
                                                                        type="text"
                                                                        name="last_name"  // Make sure the name attribute matches the key in formData
                                                                        value={formData.last_name}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="last_name"
                                                                    />

                                                                </div>

                                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>Primary Position</p>
                                                                <div className="pt-2 relative">
                                                                    <div className="relative">
                                                                        <select
                                                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                            name="primary_position"
                                                                            value={formData.primary_position}
                                                                            onChange={handleInputChange}
                                                                        >
                                                                            <option value="" disabled selected>Select Primary Pharmacy Degree</option>
                                                                            <option value="student">student</option>
                                                                            <option value="clinical pharmacist">clinical pharmacist</option>
                                                                            <option value="pharmacist">pharmacist</option>
                                                                            <option value="faculty">faculty</option>

                                                                            {/* Add more options as needed */}
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium pt-8 leading-[24px]'>City</p>
                                                                <div className="relative pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="city"
                                                                        value={formData.city}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="city"
                                                                    />
                                                                </div>




                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Country</p>
                                                                <div className="relative pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="country"
                                                                        value={formData.country}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="country"
                                                                    />
                                                                </div>
                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>pincode</p>
                                                                <div className="relative pt-2">
                                                                    <input
                                                                        type="text"
                                                                        name="pincode"
                                                                        value={formData.pincode}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="pincode"
                                                                    />
                                                                </div>

                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Name of Pharmacy College</p>
                                                                <div className="pt-2 relative">
                                                                    <div className="relative">
                                                                        <input
                                                                            type="text"
                                                                            name="pharmacy_college_name"
                                                                            value={formData.pharmacy_college_name}
                                                                            onChange={handleInputChange}
                                                                            className="border border-gray-400  rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                            placeholder="pharmacyCollegeName"
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <p className='text-[color:var(--Black,#222)] pt-8 text-[18px] not-italic font-medium leading-[24px]'>Name of  Secondary College </p>
                                                                <div className=" relative pt-2">

                                                                    <input
                                                                        type="text"
                                                                        name="pharmacy_college_degree"
                                                                        value={formData.pharmacy_college_degree}
                                                                        onChange={handleInputChange}
                                                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                                        placeholder="pharmacyCollegeDegree"
                                                                    />
                                                                </div>




                                                            </div>

                                                        </div>




                                                    </div>
                                                </div>

                                                <div className='pt-8 flex'>
                                                    <button type="submit" onClick={handleSubmit} className='profile-edit-btn '>
                                                        <p className='text-white text-center text-[20px] not-italic font-semibold leading-[normal]'>
                                                            Submit
                                                        </p>
                                                    </button>
                                                </div>


                                            </div>
                                        </div>

                                    </motion.div>
                                )}

                                <div className='w-[100%] pl-8 rounded-[8px] rounded-l-none h-[192px] m-h-auto mpl-0 '>
                                    <div>
                                        <div className='flex pt-3 gap-8'>
                                            <p className='profilename'>{user?.first_name} {user?.last_name}</p>

                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <p className='text-gray-600 text-sm text-[16px] font-normal leading-normal'>

                                            <div className="text-gray-600 text-[18px]">Date Of Birth:
                                                <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>  {profile?.date_of_birth}</p>
                                            </div>


                                        </p>
                                    </div>
                                    <div className='pt-2 flex'>
                                        <div>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>Email: </p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-normal leading-[normal]'>{user?.email} </p>
                                        </div>
                                        <div className='pl-6'>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-normal leading-[normal]'>Phone:</p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-normal leading-[normal]'>{user?.phone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                       
                            <motion.div
                                className="flex pt-8 pt-8 mm-t-0"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 4 }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                }}
                                ref={ref}
                            >
                                {/* Dropdown content */}
                                <div className='w-full'>

                                    <div className='flex pt-8 mm-t-0'>
                                        {/* Dropdown content */}
                                        <div className='w-full'>


                                            <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>
                                                <div className='profile-detailbox' >
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>First Name </p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{user?.first_name}</p>
                                                </div>
                                                <div className='profile-detailbox '>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Last Name</p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'> {user?.last_name}</p>
                                                </div>
                                                <div className='profile-detailbox '>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Date of Birth:</p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>  {profile?.date_of_birth}</p>
                                                </div>
                                                <div className='profile-detailbox '>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Primary Position: </p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.primary_position}</p>
                                                </div>

                                            </div>

                                            <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>

                                                <div className='profile-detailbox'>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'> Current Work Institution Name: </p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.current_work_institution}</p>
                                                </div>
                                                <div className='profile-detailbox '>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'> City </p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.city}</p>
                                                </div>
                                                <div className='profile-detailbox '>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px]  not-italic font-normal leading-[22px] tracking-[-0.07px]'> State</p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.state}</p>
                                                </div>
                                                <div className='profile-detailbox '>
                                                    <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'> Country</p>
                                                    <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.country}</p>
                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                    <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>
                                        <div className='profile-detailbox '>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Primary Pharmacy Degree:</p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.primary_pharmacy_degree}</p>
                                        </div>
                                        <div className='profile-detailbox '>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Name of Pharmacy College:
                                            </p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'> {profile?.pharmacy_college_name}</p>
                                        </div>
                                        <div className='profile-detailbox '>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Secondary Pharmacy Degree</p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'> {profile?.secondary_pharmacy_degree}</p>
                                        </div>


                                    </div>
                                    <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>

                                        <div className='profile-detailbox'>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[16px]  not-italic font-normal leading-[22px] tracking-[-0.07px]'>Name of Secondary College </p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.pharmacy_college_degree}</p>
                                        </div>

                                        <div className='profile-detailbox'>
                                            <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Additional Degrees: </p>
                                            <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.additional_degrees}</p>
                                        </div>
                                    </div>
                                </div>
                                </motion.div>
                    </div>
                )}
                {mailingAddressActive && (
                 <motion.div
                 className=" "
                 initial={{ opacity: 0 }}
                 animate={{ opacity: 4 }}
                 transition={{
                     duration: 1,
                     ease: "easeInOut",
                 }}
                 ref={ref}
             >
                        {/* <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  '> */}
                        {/* Dropdown content */}
                        {/* <div className='flex flex-wrap'> */}
                        {/* <div className='w-full'> */}
                        <div className='flex flex-wrap pt-8'>
                            <div className='w-full'>
                                <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  '>
                                    <div className='profile-detailbox'>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>City</p>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.city}</p>
                                    </div>
                                    <div className='profile-detailbox'>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>State </p>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.state}</p>
                                    </div>
                                    <div className='profile-detailbox'>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Pincode</p>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.pincode}</p>
                                    </div>
                                    <div className='profile-detailbox'>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Country</p>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{profile?.country}</p>
                                    </div>
                                </div>

                                <div className='pt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 '>
                                    <div className='profile-detailbox'>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Email</p>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{user?.email}</p>
                                    </div>
                                    <div className='profile-detailbox'>
                                        <p className='text-[color:var(--Gray,#58585A)] text-[16px] not-italic font-normal leading-[22px] tracking-[-0.07px]'>Phone Number</p>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] pt-1 not-italic font-semibold leading-[22px] tracking-[-0.08px]'>{user?.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* </div> */}

                        {/* </div> */}
                        </motion.div>
                )}
            </div>

            <div></div>


        </div>
    );
};

export default Profile;
