import React, { useState, useEffect } from 'react';
import pdficon from "../../../../assets/images/pdf-icon.svg";
import { BASE_URL } from '../../../../Utils/Config';
import axios from 'axios';
import { toast } from "react-hot-toast";
import { getUserProfile } from '../../../../helpers/auth';
import search from "../../../../assets/images/search.png";
import "./Download.css";

const Downloads = () => {
    const [user, setUser] = useState(null);
    const [attachments, setAttachments] = useState([]);  
    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');

    // Fetch user profile
    const fetchUserProfile = async () => {
        try {
            if (userId && authToken) {
                const profile = await getUserProfile(userId);
                setUser(profile.user);
                toast.success('User profile fetched successfully');
            } else {
                toast.error('User ID or authentication token is missing');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
            toast.error('Error fetching user profile');
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, [userId, authToken]);

    // Fetch attachments
    const fetchAttachments = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/user-attachments/`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            setAttachments(response.data.attachments);
            console.log("Fetched attachments:", response.data.attachments); // Debug line
        } catch (error) {
            console.error('Error fetching attachments:', error);
        }
    };

    useEffect(() => {
        if (authToken) {
            fetchAttachments();
        }
    }, [authToken]);

    // Handle file click to open the file
    const handleFileClick = (fileUrl) => {
        console.log('File URL:', fileUrl);  // Debug line
        if (fileUrl) {
            window.open(fileUrl, '_blank');
        } else {
            toast.error('File URL is missing');
        }
    };

    // Group attachments by single_event
    const groupedAttachments = attachments.reduce((groups, attachment) => {
        const { single_event, file, event_name, event_day } = attachment;
        if (!groups[single_event]) {
            groups[single_event] = { files: [], event_name, event_day };
        }
        groups[single_event].files.push(file);
        return groups;
    }, {});

    return (
        <div className='bg-[#f4f4f4] w-[100%] h-[100%] p-6'>
            <div className='bg-white p-6'>
                <div className='download-head'>
                    <div className='download-title'>
                        <h3>Downloads</h3>
                    </div>
                </div>

                <div className='download-main'>
                    {Object.keys(groupedAttachments).length > 0 ? (
                        Object.keys(groupedAttachments).map(singleEventId => (
                            <div className='download-item' key={singleEventId}>
                                <div className='download-name'>
                                    <p className='p-3 w-[100%]'>
                                       {groupedAttachments[singleEventId].event_name} 
                                        -Day {groupedAttachments[singleEventId].event_day} 
                                    </p>
                                </div>
                                <div className='download-files pl-8 flex  '>
                                    {groupedAttachments[singleEventId].files.map((fileUrl, index) => (
                                        <img
                                            src={pdficon}
                                            alt="File"
                                            onClick={() => handleFileClick(fileUrl)}
                                            style={{ cursor: 'pointer', marginRight: '10px' }}
                                            key={index}
                                            className='hover:bg-[#EDF7FF] p-3 rounded-[10px]'
                                        />
                                    ))}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Downloads;
