import React, { useState, useEffect } from 'react';
import Logo19 from "../../../assets/images/Upcoming Events (1).png";
import Logo17 from "../../../assets/images/Live Events (1).jpg";
import Logo18 from "../../../assets/images/Completed Events (1).jpg";
import Logoa from "../../../assets/images/add.png";
import Logo from "../../../assets/images/Events (1).jpg";
import Log1 from "../../../assets/images/cancel.png";

import { BASE_URL } from '../../../Utils/Config';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Podcast = () => {
    const navigate = useNavigate();
    const [livePodcastsVisible, setLivePodcastsVisible] = useState(true);
    const [upcomingPodcastsVisible, setUpcomingPodcastsVisible] = useState(false);
    const [completedPodcastsVisible, setCompletedPodcastsVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [livePodcasts, setLivePodcasts] = useState([]);
    const [upcomingPodcasts, setUpcomingPodcasts] = useState([]);
    const [completedPodcasts, setCompletedPodcasts] = useState([]);
    const [livePodcastsDropdownVisible, setLivePodcastsDropdownVisible] = useState(null);
    const [upcomingPodcastsDropdownVisible, setUpcomingPodcastsDropdownVisible] = useState(null);
    const [completedPodcastsDropdownVisible, setCompletedPodcastsDropdownVisible] = useState(null);
    const { slug } = useParams();
    const [watchNowVisible, setWatchNowVisible] = useState(false);
    const [podcast, setPodcast] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);



    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/podcasts_list/`);
                console.log("API Response:", response.data);

                setLivePodcasts(response.data.live_podcasts || []);
                setUpcomingPodcasts(response.data.upcoming_podcasts || []);
                setCompletedPodcasts(response.data.completed_podcasts || []);
            } catch (error) {
                console.error('Error fetching Podcasts:', error);
            }
        };

        fetchPodcasts();
    }, []);

    const handleLoginClick = () => {
        const currentPath = window.location.pathname;
        localStorage.setItem('loginRedirectPath', currentPath);
        navigate('/login');
    };
    useEffect(() => {
        if (isLoggedIn) {
            setWatchNowVisible(true);
        } else {
            setWatchNowVisible(false);
        }
    }, [isLoggedIn]);

    const handleJoinFCPI = () => { };

    const handleWatchEvent = () => { };

    const toggleLivePodcasts = () => {
        setLivePodcastsVisible(!livePodcastsVisible);
        setUpcomingPodcastsVisible(false);
        setCompletedPodcastsVisible(false);
        setDropdownVisible(null);
    };

    const toggleUpcomingPodcasts = () => {
        setUpcomingPodcastsVisible(!upcomingPodcastsVisible);
        setLivePodcastsVisible(false);
        setCompletedPodcastsVisible(false);
        setDropdownVisible(null);
    };

    const toggleCompletedPodcasts = () => {
        setCompletedPodcastsVisible(!completedPodcastsVisible);
        setLivePodcastsVisible(false);
        setUpcomingPodcastsVisible(false);
        setDropdownVisible(null);
    };

    const toggleImageVisible = (podcastType, index) => {
        switch (podcastType) {
            case 'live':
                setLivePodcastsDropdownVisible(livePodcastsDropdownVisible === index ? null : index);
                break;
            case 'upcoming':
                setUpcomingPodcastsDropdownVisible(upcomingPodcastsDropdownVisible === index ? null : index);
                break;
            case 'completed':
                setCompletedPodcastsDropdownVisible(completedPodcastsDropdownVisible === index ? null : index);
                break;
            default:
                break;
        }
    };
    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${period}`;
    };




    const [currentTime, setCurrentTime] = useState(new Date());


    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    const shouldShowWatchNowButton = (podcast, type) => {
        if (!isLoggedIn) return false;

        const eventStartTime = new Date(`${podcast.date}T${podcast.starting_time}`);
        const fifteenMinutesBeforeStart = new Date(eventStartTime - 15 * 60 * 1000);

        console.log('Current Time:', currentTime);
        console.log('Event Start Time:', eventStartTime);
        console.log('Fifteen Minutes Before Start:', fifteenMinutesBeforeStart);

        if (type === 'live') {

            return currentTime >= fifteenMinutesBeforeStart;
        }

        if (type === 'completed') {
            return true;
        }

        return false;
    };








    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-10 pl-12 pr-12'>
                        <p className='text-[#222] text-start text-[32px] not-italic font-semibold leading-[normal]'>Podcasts</p>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 pt-8">
                            <div className={`rounded-[30px] ${livePodcastsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D] cursor-pointer' : 'bg-gray-100 hover:bg-[#1AB78D] cursor-pointer'}`} onClick={toggleLivePodcasts}>
                                <img src={Logo17} alt="Logo" className="w-full rounded-[30px]" />
                                <p className={`text-[#222] p-7 text-start pl-8 hover:text-white ${livePodcastsVisible ? 'text-white' : ''} text-[21px] not-italic font-semibold leading-[normal] `}>Live Podcasts</p>
                            </div>
                            <div className={`rounded-[30px] ${upcomingPodcastsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D] cursor-pointer' : 'bg-gray-100 hover:bg-[#1AB78D] cursor-pointer'}`} onClick={toggleUpcomingPodcasts}>
                                <img src={Logo19} alt="Logo" className="w-full rounded-[30px]" />
                                <p className={`text-[#222] p-7 text-start pl-8 hover:text-white ${upcomingPodcastsVisible ? 'text-white' : ''} text-[21px] not-italic font-semibold leading-[normal]  `}>Upcoming Podcasts</p>
                            </div>
                            <div className={`rounded-[30px] ${completedPodcastsVisible ? 'bg-[#1AB78D] hover:bg-[#1AB78D] cursor-pointer' : 'bg-gray-100 hover:bg-[#1AB78D] cursor-pointer'}`} onClick={toggleCompletedPodcasts}>
                                <img src={Logo18} alt="Logo" className="w-full rounded-[30px]" />
                                <p className={`text-[#222] p-7 text-start pl-8 hover:text-white ${completedPodcastsVisible ? 'text-white' : ''} text-[21px] not-italic font-semibold leading-[normal]  `}>Completed Podcasts</p>
                            </div>
                        </div>

                        {livePodcastsVisible && livePodcasts.length > 0 && (
                            <div className='pt-16 gap-4'>
                                <div className='flex'>
                                    <div className='pl-2 w-[70%]'>
                                        {livePodcasts.map((podcast, index) => (
                                            <div key={index}>
                                                <div className='flex gap-[16rem] pt-6'>
                                                    <p className={`text-[#222] text-[19px] w-[60%] not-italic font-bold leading-[30px] ${livePodcastsDropdownVisible === index ? 'text-green-500' : ''}`}>{podcast.name}</p>
                                                    <button
                                                        className={`px-4 py-4 rounded-[30px]`}
                                                        style={{ backgroundColor: livePodcastsDropdownVisible === index ? '#00549A' : '#C5E5FF' }}
                                                        onClick={() => toggleImageVisible('live', index)}
                                                    >
                                                        <img src={livePodcastsDropdownVisible === index ? Log1 : Logoa} alt="Close Image" className="rounded-[30px]" />
                                                    </button>
                                                </div>
                                                {livePodcastsDropdownVisible === index && (
                                                    <div className=''>
                                                        <div className=''>
                                                            <div className='flex items-center gap-4 pt-4'>
                                                                <p className='text-[#58585A] text-[19px] not-italic font-normal leading-[normal]'>
                                                                    Hosts: {podcast.hosts && podcast.hosts.length > 0 ? podcast.hosts.map((host, idx) => (
                                                                        <span key={host.id}>{host.name}{idx < podcast.hosts.length - 1 ? ', ' : ''}</span>
                                                                    )) : 'None'}
                                                                </p>

                                                            </div>
                                                            <div className='flex items-center gap-4 pt-4'>
                                                                <p className='text-[#58585A] text-[19px] not-italic font-normal leading-[normal]'>
                                                                    Guests: {podcast.guests && podcast.guests.length > 0 ? podcast.guests.map((guest, idx) => (
                                                                        <span key={guest.id}>{guest.name}{idx < podcast.guests.length - 1 ? ', ' : ''}</span>
                                                                    )) : 'None'}
                                                                </p>

                                                            </div>

                                                            <div className='flex mobflex   pt-1'>
                                                                <div className='flex gap-4'>
                                                                    <p className='text-[#58585A] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>{podcast.date}</p>
                                                                    <p className='text-[#58585A] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>
                                                                        {formatTime(podcast.starting_time)} to {formatTime(podcast.ending_time)}
                                                                    </p>
                                                                </div>

                                                                <div className='pl-8 '>
                                                                    <button className=' '>




                                                                        <div className='flex'>
                                                                            {!isLoggedIn ? (
                                                                                <>
                                                                                    <button
                                                                                        onClick={handleLoginClick}
                                                                                        className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A]"
                                                                                    >
                                                                                        <p className="text-[14px] not-italic font-medium leading-[normal]">Login</p>
                                                                                    </button>
                                                                                    <Link to='/register'>
                                                                                        <button
                                                                                            onClick={handleJoinFCPI}
                                                                                            className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A] ml-4"
                                                                                        >
                                                                                            <p className="text-[14px] not-italic font-medium leading-[normal]">Join FCPI</p>
                                                                                        </button>
                                                                                    </Link>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {shouldShowWatchNowButton(podcast, 'live') && (
                                                                                        <Link to={`/podcast/${encodeURIComponent(podcast.name)}`}>
                                                                                            <button onClick={handleWatchEvent} className="bg-[#1AB78D] rounded-[105px] mt-mob px-7 py-1.5">
                                                                                                <p className="text-white text-[18px] not-italic font-semibold leading-[normal]">Watch Now</p>
                                                                                            </button>
                                                                                        </Link>
                                                                                    )}




                                                                                </>
                                                                            )}

                                                                        </div>


                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <hr className='mt-4 w-[94%]' />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='mob-hide'>
                                        <img src={Logo} alt="Logo17" className="rounded-[30px]" />
                                    </div>
                                </div>
                            </div>
                        )}

                        {upcomingPodcastsVisible && (
                            <div className='pt-16 gap-4'>
                                <div className='flex'>
                                    <div className='pl-2 w-[70%]'>
                                        {upcomingPodcasts.map((podcast, index) => (
                                            <div key={index}>
                                                <div className='flex gap-[16rem] pt-6'>
                                                    <p className={`text-[#222] text-[19px] w-[60%] not-italic font-bold leading-[30px] ${upcomingPodcastsDropdownVisible === index ? 'text-green-500' : ''}`}>{podcast.name}</p>
                                                    <button
                                                        className={`px-4 py-4 rounded-[30px]`}
                                                        style={{ backgroundColor: upcomingPodcastsDropdownVisible === index ? '#00549A' : '#C5E5FF' }}
                                                        onClick={() => toggleImageVisible('upcoming', index)}
                                                    >
                                                        <img src={upcomingPodcastsDropdownVisible === index ? Log1 : Logoa} alt="Close Image" className="rounded-[30px]" />
                                                    </button>
                                                </div>
                                                {upcomingPodcastsDropdownVisible === index && (
                                                    <div className=''>
                                                        <div className=''>
                                                            <div className='flex items-center gap-4 pt-4'>
                                                                <p className='text-[#58585A] text-[19px] not-italic font-normal leading-[normal]'>
                                                                    Hosts: {podcast.hosts && podcast.hosts.length > 0 ? podcast.hosts.map((host, idx) => (
                                                                        <span key={host.id}>{host.name}{idx < podcast.hosts.length - 1 ? ', ' : ''}</span>
                                                                    )) : 'None'}
                                                                </p>
                                                            </div>
                                                            <div className='flex items-center gap-4 pt-4'>
                                                                <p className='text-[#58585A] text-[19px] not-italic font-normal leading-[normal]'>
                                                                    Guests: {podcast.guests && podcast.guests.length > 0 ? podcast.guests.map((guest, idx) => (
                                                                        <span key={guest.id}>{guest.name}{idx < podcast.guests.length - 1 ? ', ' : ''}</span>
                                                                    )) : 'None'}
                                                                </p>

                                                            </div>

                                                            <div className='flex mobflex   pt-1'>
                                                                <div className='flex gap-4'>
                                                                    <p className='text-[#58585A] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>{podcast.date}</p>
                                                                    <p className='text-[#58585A] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>
                                                                        {formatTime(podcast.starting_time)} to {formatTime(podcast.ending_time)}
                                                                    </p>
                                                                </div>

                                                                <div className='pl-8 '>
                                                                    <button className=' '>




                                                                        <div className='flex'>
                                                                            {!isLoggedIn ? (
                                                                                <>
                                                                                    <button
                                                                                        onClick={handleLoginClick}
                                                                                        className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A]"
                                                                                    >
                                                                                        <p className="text-[14px] not-italic font-medium leading-[normal]">Login</p>
                                                                                    </button>
                                                                                    <Link to='/register'>
                                                                                        <button
                                                                                            onClick={handleJoinFCPI}
                                                                                            className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A] ml-4"
                                                                                        >
                                                                                            <p className="text-[14px] not-italic font-medium leading-[normal]">Join FCPI</p>
                                                                                        </button>
                                                                                    </Link>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {shouldShowWatchNowButton(podcast, 'upcoming') && (
                                                                                        <Link to={`/podcast/${encodeURIComponent(podcast.name)}`}>
                                                                                            <button onClick={handleWatchEvent} className="bg-[#1AB78D] rounded-[105px] mt-mob px-7 py-1.5">
                                                                                                <p className="text-white text-[18px] not-italic font-semibold leading-[normal]">Watch Now</p>
                                                                                            </button>
                                                                                        </Link>
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                        </div>


                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <hr className='mt-4 w-[94%]' />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='mob-hide'>
                                        <img src={Logo} alt="Logo17" className="rounded-[30px]" />
                                    </div>
                                </div>
                            </div>
                        )}

                        {completedPodcastsVisible && (
                            <div className='pt-16 gap-4'>
                                <div className='flex'>
                                    <div className='pl-2 w-[70%]'>
                                        {completedPodcasts.map((podcast, index) => (
                                            <div key={index}>
                                                <div className='flex gap-[16rem] pt-6'>
                                                    <p className={`text-[#222] text-[19px] w-[60%] not-italic font-bold leading-[30px] ${completedPodcastsDropdownVisible === index ? 'text-green-500' : ''}`}>{podcast.name}</p>
                                                    <button
                                                        className={`px-4 py-4 rounded-[30px]`}
                                                        style={{ backgroundColor: completedPodcastsDropdownVisible === index ? '#00549A' : '#C5E5FF' }}
                                                        onClick={() => toggleImageVisible('completed', index)}
                                                    >
                                                        <img src={completedPodcastsDropdownVisible === index ? Log1 : Logoa} alt="Close Image" className="rounded-[30px]" />
                                                    </button>
                                                </div>
                                                {completedPodcastsDropdownVisible === index && (
                                                    <div className=''>
                                                        <div className=''>
                                                            <div className='flex items-center gap-4 pt-4'>
                                                                <p className='text-[#58585A] text-[19px] not-italic font-normal leading-[normal]'>
                                                                    Hosts: {podcast.hosts && podcast.hosts.length > 0 ? podcast.hosts.map((host, idx) => (
                                                                        <span key={host.id}>{host.name}{idx < podcast.hosts.length - 1 ? ', ' : ''}</span>
                                                                    )) : 'None'}
                                                                </p>
                                                            </div>
                                                            <div className='flex items-center gap-4 pt-4'>
                                                                <p className='text-[#58585A] text-[19px] not-italic font-normal leading-[normal]'>
                                                                    Guests: {podcast.guests && podcast.guests.length > 0 ? podcast.guests.map((guest, idx) => (
                                                                        <span key={guest.id}>{guest.name}{idx < podcast.guests.length - 1 ? ', ' : ''}</span>
                                                                    )) : 'None'}
                                                                </p>

                                                            </div>

                                                            <div className='flex mobflex   pt-1'>
                                                                <div className='flex gap-4'>
                                                                    <p className='text-[#58585A] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>{podcast.date}</p>
                                                                    <p className='text-[#58585A] pt-4 text-[17px]    not-italic font-normal leading-[normal]'>
                                                                        {formatTime(podcast.starting_time)} to {formatTime(podcast.ending_time)}
                                                                    </p>
                                                                </div>
                                                                <div className='pl-8 '>
                                                                    <button className=' '>




                                                                        <div className='flex'>
                                                                            {!isLoggedIn ? (
                                                                                <>
                                                                                    <button
                                                                                        onClick={handleLoginClick}
                                                                                        className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A]"
                                                                                    >
                                                                                        <p className="text-[14px] not-italic font-medium leading-[normal]">Login</p>
                                                                                    </button>
                                                                                    <Link to='/register'>
                                                                                        <button
                                                                                            onClick={handleJoinFCPI}
                                                                                            className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A] ml-4"
                                                                                        >
                                                                                            <p className="text-[14px] not-italic font-medium leading-[normal]">Join FCPI</p>
                                                                                        </button>
                                                                                    </Link>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {shouldShowWatchNowButton(podcast, 'completed') && (
                                                                                        <Link to={`/podcast/${encodeURIComponent(podcast.name)}`}>
                                                                                            <button onClick={handleWatchEvent} className="bg-[#1AB78D] rounded-[105px] mt-mob px-7 py-1.5">
                                                                                                <p className="text-white text-[18px] not-italic font-semibold leading-[normal]">Watch Now</p>
                                                                                            </button>
                                                                                        </Link>
                                                                                    )}

                                                                                </>
                                                                            )}

                                                                        </div>


                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <hr className='mt-4 w-[94%]' />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='mob-hide'>
                                        <img src={Logo} alt="Logo17" className="rounded-[30px]" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Podcast;
