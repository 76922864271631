import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../Utils/Config';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Ucertificate.css';
import { useReactToPrint } from 'react-to-print';
import down from '../../../../assets/images/download-05.png';
import { motion, useAnimation, useInView } from 'framer-motion';

const Certificate = () => {
    const [userDetails, setUserDetails] = useState({});
    const [ecertificates, setEcertificates] = useState([]);
    const [generalCertificates, setGeneralCertificates] = useState([]);
    const [error, setError] = useState('');
    const [certificateIndexToPrint, setCertificateIndexToPrint] = useState(null);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    const contentToPrint = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentToPrint.current,
    });

    const printCertificate = (index) => {
        setCertificateIndexToPrint(index);
        handlePrint();
    };

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) {
                    setError('Authentication token not found.');
                    return;
                }

                const response = await axios.get(`${BASE_URL}/accounts/usercertificate/`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });
                setUserDetails(response.data);
            } catch (error) {
                setError('An error occurred while fetching user details.');
            }
        };

        const fetchCertificates = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) {
                    setError('Authentication token not found.');
                    return;
                }

                const [ecertificatesResponse, generalCertificatesResponse] = await Promise.all([
                    axios.get(`${BASE_URL}/accounts/ecertificate/`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                        },
                    }),
                    axios.get(`${BASE_URL}/accounts/general_ecertificate/`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                        },
                    })
                ]);

                setEcertificates(ecertificatesResponse.data);
                setGeneralCertificates(generalCertificatesResponse.data);
            } catch (error) {
                if (error.response && error.response.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError('An error occurred while fetching the certificates.');
                }
            }
        };

        fetchUserDetails();
        fetchCertificates();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();  
        const year = date.getFullYear();

        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const monthName = months[monthIndex];

        return `${day}-${monthName}-${year}`;
    };

    const downloadCertificate = async (certificate) => {
        try {
            if (!certificate || !certificate.certificate_image) {
                throw new Error('Certificate data is invalid.');
            }
    
            const response = await axios.get(BASE_URL + certificate.certificate_image, {
                responseType: 'arraybuffer',
            });
    
            if (!response || !response.data || !response.data.byteLength) {
                throw new Error('Empty response or no data received.');
            }
    
            const imageBlob = new Blob([response.data], { type: 'image/png' });
    
            const imageData = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(imageBlob);
            });
    
            const img = new Image();
            img.src = imageData;
            img.onload = function () {
                const targetWidth = 1920;
                const targetHeight = 1350;
    
                const pdfDoc = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format: [targetWidth, targetHeight],
                });
    
                pdfDoc.addImage(imageData, 'PNG', 0, 0, targetWidth, targetHeight);
    
                const marginRight = 50;
                const marginTop = 50;
                const marginLeft = 50;
    
                const rightAlignText = (text, fontSize) => {
                    const textWidth = pdfDoc.getTextWidth(text);
                    return targetWidth - textWidth - marginRight;
                };
    
                const moveLeftForUser = 273;
                pdfDoc.setFontSize(90);
                pdfDoc.setTextColor(26, 187, 141);
                pdfDoc.setFont("times", "bold");
                const usernameText = `${userDetails.first_name} ${userDetails.last_name}`;
                const usernameX = rightAlignText(usernameText, 60) - moveLeftForUser;
                pdfDoc.text(usernameText, usernameX, marginTop + 530);
    
                const moveLeftForEvent = 273;
                pdfDoc.setFontSize(60);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                const eventName = certificate.event_days > 1 ? `${certificate.event_name} (Day ${certificate.single_event.day})` : certificate.event_name;
                const eventNameX = rightAlignText(eventName, 45) - moveLeftForEvent;
                pdfDoc.text(eventName, eventNameX, marginTop + 650);
    
                // Add forum name to PDF
                const moveLeftForForum = 273;
                pdfDoc.setFontSize(60);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                const forumName = certificate.forum ||  ''; // Add this line
                const forumNameX = rightAlignText(forumName, 38) - moveLeftForForum;
                pdfDoc.text(forumName, forumNameX, marginTop + 900);
    
                const moveLeftForDate = 273;
                pdfDoc.setFontSize(60);
                pdfDoc.setTextColor(0, 84, 154);
                pdfDoc.setFont("inter", "bold");
                const eventDateText = `${formatDate(certificate.event_date)}`;
                const eventDateX = rightAlignText(eventDateText, 38) - moveLeftForDate;
                pdfDoc.text(eventDateText, eventDateX, marginTop + 950);
    
                const moveRightForPoints = 95;
                pdfDoc.setFontSize(60);
                pdfDoc.setTextColor(26, 187, 141);
                pdfDoc.setFont("inter", "bold");
                const pointsValue = certificate.single_event.points.toFixed(2);
                const pointsText = ` ${pointsValue}`;
                const pointsX = marginLeft + moveRightForPoints;
                pdfDoc.text(pointsText, pointsX, marginTop + 1180);
    
                pdfDoc.save(`certificate_${certificate.event_name}.pdf`);
            };
        } catch (error) {
            console.error('Error generating certificate PDF:', error);
        }
    };
    

    return (
        <div className='bg-[#f4f4f4] p-6'>
            <div className='bg-white p-6'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Certificates</p>
                <hr className='mt-8' />

                {/* Section for eCertificates */}
                <motion.div
                    className="mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 4 }}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                    }}
                    ref={ref}
                >
                
                    {ecertificates.length > 0 ? (
                        ecertificates.map((certificate, index) => (
                            <div key={index} className='flex justify-between pt-6' id={`ecertificate-${index}`}>
                                <div className='text-[20px] font-semibold'>
                                    {certificate.event_days > 1 ? `${certificate.event_name} (Day ${certificate.single_event.day})` : certificate.event_name}
                                </div>
                           
                                <div className=''>
                                    <button className='border p-3 w-[197px] border-[#00549A] rounded-[4px] flex justify-between'>
                                        <p className='text-[#00549A] font-medium text-[14px]' onClick={() => downloadCertificate(certificate)}>Download Certificate</p>
                                        <img src={down} alt="Download" />
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p> </p>
                    )}
                </motion.div>

                {/* Section for General Certificates */}
                <motion.div
                    className="mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 4 }}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                    }}
                >
                   
                    {generalCertificates.length > 0 ? (
                        generalCertificates.map((certificate, index) => (
                            <div key={index} className='flex justify-between pt-6' id={`generalCertificate-${index}`}>
                                <div className='text-[20px] font-semibold'>
                                    {certificate.event_days > 1 ? `${certificate.event_name} (Day ${certificate.single_event.day})` : certificate.event_name}
                                </div>
                                
                                <div className=''>
                                    <button className='border p-3 w-[197px] border-[#00549A] rounded-[4px] flex justify-between'>
                                        <p className='text-[#00549A] font-medium text-[14px]' onClick={() => downloadCertificate(certificate)}>Download Certificate</p>
                                        <img src={down} alt="Download" />
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p> </p>
                    )}
                </motion.div>
            </div>
        </div>
    );
};

export default Certificate;
