import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import imag from "../../../../assets/images/user-circle.png";
import imag1 from "../../../../assets/images/mail.png";
import axios from "axios";
import { BASE_URL } from '../../../../Utils/Config';
import "./Newsletter.css";
import del from "../../../../assets/images/del.png";
import ed from "../../../../assets/images/edit.png";

const Newsletter = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setMailingAddressActive] = useState(false);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [newsletters, setNewsletters] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        edition: '',
        thumbnail: null,
        pdf: null,
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentNewsletter, setCurrentNewsletter] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState('');
    const fetchNewsletters = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/newsletters/`);
            setNewsletters(response.data);
            console.log("datas get", response.data)
        } catch (error) {
            console.error('Error fetching newsletters:', error);
        }
    };

    useEffect(() => {
        fetchNewsletters();
    }, []);
    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const postData = new FormData();
            postData.append('title', formData.title);
            postData.append('edition', formData.edition);
            postData.append('start_date', startDate ? startDate.toISOString().split('T')[0] : '');
            postData.append('end_date', endDate ? endDate.toISOString().split('T')[0] : '');
            postData.append('thumbnail', formData.thumbnail);
            postData.append('pdf', formData.pdf);

            await axios.post(`${BASE_URL}/admins/newsletters/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alert('Newsletter added successfully!');
            setFormData({ title: '', edition: '', thumbnail: null, pdf: null });
            setStartDate(null);
            setEndDate(null);
            fetchNewsletters();
        } catch (error) {
            console.error('Error adding newsletter:', error);
            alert('Failed to add newsletter. Please try again.');
        }
    };

    const handleDelete = async (newsletterId) => {
        if (window.confirm('Are you sure you want to delete this newsletter?')) {
            try {
                await axios.delete(`${BASE_URL}/admins/newsletters/${newsletterId}/delete/`);
                alert('Newsletter deleted successfully!');
                setNewsletters(prevNewsletters => prevNewsletters.filter(newsletter => newsletter.id !== newsletterId));
            } catch (error) {
                console.error('Error deleting newsletter:', error);
                alert('Failed to delete newsletter. Please try again.');
            }
        }
    };

    const handleEditClick = (newsletter) => {
        setCurrentNewsletter(newsletter);
        setThumbnailPreview(newsletter.thumbnail); // Display existing thumbnail
        setPdfPreview(newsletter.pdf ? newsletter.pdf.split('/').pop() : ''); // Extract file name
        setEditFormVisible(true);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('title', currentNewsletter.title);
        formData.append('edition', currentNewsletter.edition);
        formData.append('start_date', currentNewsletter.start_date);
        formData.append('end_date', currentNewsletter.end_date);

        // Append files only if a new file has been selected
        if (thumbnail) { // Use the `thumbnail` state here
            formData.append('thumbnail', thumbnail);
        }

        if (currentNewsletter.pdf && typeof currentNewsletter.pdf !== 'string') {
            formData.append('pdf', currentNewsletter.pdf);
        }

        try {
            await axios.put(`${BASE_URL}/admins/newsletters/${currentNewsletter.id}/update/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            alert('Newsletter updated successfully!');
            setEditFormVisible(false);
            fetchNewsletters();
        } catch (error) {
            console.error('Error updating newsletter:', error.response ? error.response.data : error.message);
            alert('Failed to update newsletter. Please try again.');
        }
    };

     
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };



    const [pdfPreview, setPdfPreview] = useState('');

    const [thumbnail, setThumbnail] = useState(null);

    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);
        if (!personalInfoActive) setMailingAddressActive(false);
    };

    const toggleMailingAddress = () => {
        setMailingAddressActive(!mailingAddressActive);
        if (!mailingAddressActive) setPersonalInfoActive(false);
    };
    useEffect(() => {
        if (currentNewsletter && currentNewsletter.thumbnail) {
            setThumbnailPreview(currentNewsletter.thumbnail);
        } else {
            setThumbnailPreview('');
        }
    }, [currentNewsletter]);

    const handleThumbnailChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setThumbnail(file);
            setThumbnailPreview(URL.createObjectURL(file));
        }
    };

    const [pdfUrl, setPdfUrl] = useState('');
    const handleFileNameClick = (event) => {
        event.preventDefault();

    };
    const handlePdfChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPdfPreview(file.name); // Set file name only
            setCurrentNewsletter({ ...currentNewsletter, pdf: file });
        }
    };




    return (
        <div className='bg-[#f4f4f4] h-[100vh] p-6'>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Newsletter</p>
                <div className='flex pt-12 gap-[12rem] p-8'>
                    <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                        <div className='flex gap-3'>
                            {personalInfoActive && <img src={imag} alt="" />}
                            <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Newsletter</p>
                        </div>
                    </button>
                    <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={toggleMailingAddress}>
                        <div className='flex gap-3'>
                            {mailingAddressActive && <img src={imag1} alt="" />}
                            <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Newsletter</p>
                        </div>
                    </button>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Title</p>
                                <input
                                    type="text"
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    placeholder="Title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Edition</p>
                                <input
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    placeholder="Edition"
                                    value={formData.edition}
                                    onChange={(e) => setFormData({ ...formData, edition: e.target.value })}
                                />
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Period</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholderText="Start Date"
                                        dateFormat="MMM yyyy"
                                        showMonthYearPicker
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholderText="End Date"
                                        dateFormat="MMM yyyy"
                                        showMonthYearPicker
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Thumbnail</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    onChange={(e) => setFormData({ ...formData, thumbnail: e.target.files[0] })}
                                />
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>PDF</p>
                                <input
                                    type="file"
                                    accept=".pdf"
                                    className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                    onChange={(e) => setFormData({ ...formData, pdf: e.target.files[0] })}
                                />
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <div className=' '>
                        {displaySectionVisible && !editFormVisible && (
                            <div className="w-full mt-6">
                                <div className='bg-gray-100 py-4 w-full flex p-6 '>
                                    <p className='text-[color:var(--Gray,#58585A)]  text-[18px] not-italic font-semibold leading-[normal]'>Title</p>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-[30rem] text-[18px] not-italic font-semibold leading-[normal]'>Edition</p>
                                    <p className='text-[color:var(--Gray,#58585A)]  pl-[16.5rem] text-[18px] not-italic font-semibold leading-[normal]'>Period</p>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-[25rem] text-[18px] not-italic font-semibold leading-[normal]'>Actions</p>
                                </div>

                                {newsletters.map((newsletter) => {
                                    const startDate = new Date(newsletter.start_date);
                                    const endDate = new Date(newsletter.end_date);

                                    const options = { year: 'numeric', month: 'long' };
                                    const formattedStartDate = startDate.toLocaleDateString('en-US', options);
                                    const formattedEndDate = endDate.toLocaleDateString('en-US', options);

                                    const displayPeriod = formattedStartDate.split(' ')[0] === formattedEndDate.split(' ')[0]
                                        ? `${formattedStartDate.split(' ')[0]} ${formattedStartDate.split(' ')[1]} - ${formattedEndDate.split(' ')[0]} ${formattedEndDate.split(' ')[1]}`
                                        : `${formattedStartDate} - ${formattedEndDate}`;

                                    return (
                                        <div key={newsletter.id} className='w-full'>
                                            <div className='flex pt-4 pb-4 pl-8'>
                                                <div className='flex gap-4'>
                                                    <p className='text-[color:var(--Gray,#58585A)] w-[500px] text-[16px] not-italic font-normal leading-[normal]'>
                                                        {newsletter.title}
                                                    </p>
                                                    <p className='text-[color:var(--Gray,#58585A)] w-[300px] text-[16px] not-italic font-normal leading-[normal]'>
                                                        {newsletter.edition}
                                                    </p>
                                                    <p className='text-[color:var(--Gray,#58585A)] w-[450px] text-[16px] not-italic font-normal leading-[normal]'>
                                                        {displayPeriod}
                                                    </p>
                                                </div>
                                                <div className='flex gap-6'>
                                                    <div>
                                                        <img src={ed} alt="" onClick={() => handleEditClick(newsletter)} />
                                                    </div>
                                                    <div>
                                                        <img src={del} alt="" onClick={() => handleDelete(newsletter.id)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    );
                                })}

                            </div>
                        )}
                        {editFormVisible && currentNewsletter && (
                            <div className='w-[50%]'>
                                <form onSubmit={handleEditSubmit}>
                                    <div className="text-start">
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Title</p>
                                        <input
                                            type="text"
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Title"
                                            value={currentNewsletter.title}
                                            onChange={(e) => setCurrentNewsletter({ ...currentNewsletter, title: e.target.value })}
                                        />
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Edition</p>
                                        <input
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            placeholder="Edition"
                                            value={currentNewsletter.edition}
                                            onChange={(e) => setCurrentNewsletter({ ...currentNewsletter, edition: e.target.value })}
                                        />
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Period</p>
                                        <div className="grid grid-cols-2 gap-6">
                                            <DatePicker
                                                selected={new Date(currentNewsletter.start_date)}
                                                onChange={(date) => setCurrentNewsletter({ ...currentNewsletter, start_date: date.toISOString().split('T')[0] })}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholderText="Start Date"
                                                dateFormat="MMM yyyy"
                                                showMonthYearPicker
                                            />
                                            <DatePicker
                                                selected={new Date(currentNewsletter.end_date)}
                                                onChange={(date) => setCurrentNewsletter({ ...currentNewsletter, end_date: date.toISOString().split('T')[0] })}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholderText="End Date"
                                                dateFormat="MMM yyyy"
                                                showMonthYearPicker
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Thumbnail</p>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            onChange={handleThumbnailChange}
                                        />
                                        {thumbnailPreview && (
                                            <img src={thumbnailPreview} alt="Thumbnail Preview" className="mt-2" style={{ maxWidth: '100px' }} />
                                        )}
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>PDF</p>
                                        <input
                                            type="file"
                                            accept=".pdf"
                                            className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            onChange={handlePdfChange}
                                        />
                                        {pdfPreview && (
                                            <p className="mt-2 text-gray-700">{pdfPreview}</p> // Display file name
                                        )}





<div className='flex'>
                                        <div className='pt-8 w-[70%]'>
                                            <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                                <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                            </button>
                                        </div>
                                        

                                        <div className='pt-8 w-[30%] ml-6'>
                                        <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitEditForm}>Close</p>
                                            </button>
                                    </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        )}

                    </div>
                )}
            </div>
        </div>
    );
};

export default Newsletter;
