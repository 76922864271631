import React, { useState, useEffect } from 'react';
import imag from "../../../assets/images/user-circle.png";
import imag1 from "../../../assets/images/mail.png";
import Icon from "../../../assets/images/Icon frame.png";
import { Link } from "react-router-dom";
import del from "../../../assets/images/del.png";
import ed from "../../../assets/images/edit.png";
import click from "../../../assets/images/click.png";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import search from "../../../assets/images/search.png";
// import "./Aforum.css"



const Amember = () => {
    const [personalInfoActive, setPersonalInfoActive] = useState(true);
    const [mailingAddressActive, setmailingAddressActive] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        qualification: '',
        image: null,
        recent_job_title: '',
        additional_job_titles: '',
        previous_work_experience: '',
        publications: '',
        linkedin: '',
        conference: '',
        additional_information: '',
        achievements: '',
        areas: ''
    });
    const [members, setMembers] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.title]: e.target.value });
    };

    const fetchMembers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/admins/members/`);
            setMembers(response.data);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchMembers();
    }, []);

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setSelectedImage(imageFile);
        setFormData(prevFormData => ({
            ...prevFormData,
            image: imageFile
        }));
    };

    const [displaySectionVisible, setDisplaySectionVisible] = useState(true);
    const handleExitEditForm = () => {
        setEditFormVisible(false);
        setDisplaySectionVisible(true);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('name', formData.name);
            postData.append('qualification', formData.qualification);
            postData.append('recent_job_title', formData.recent_job_title);
            postData.append('additional_job_titles', formData.additional_job_titles);
            postData.append('previous_work_experience', formData.previous_work_experience);
            postData.append('publications', formData.publications);
            postData.append('linkedin', formData.linkedin)
            postData.append('conference', formData.conference);
            postData.append('additional_information', formData.additional_information);
            postData.append('achievements', formData.achievements);
            postData.append('areas', formData.areas);

            if (formData.image) {
                postData.append('image', formData.image);
            }

            const response = await axios.post(`${BASE_URL}/admins/members/`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);
            alert('Member added successfully!');
            window.location.reload();
            // Reset form state
            setFormData({
                name: '',
                qualification: '',
                image: null,
                recent_job_title: '',
                additional_job_titles: '',
                previous_work_experience: '',
                publications: '',
                linkedin: '',
                conference: '',
                additional_information: '',
                achievements: '',
                areas: ''
            });
        } catch (error) {
            console.error('Error adding member:', error);
            alert('Failed to add member. Please try again.');
        }
    };

    const handleDelete = async (memberId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this member?');

        if (confirmDelete) {
            try {
                await axios.delete(`${BASE_URL}/admins/members/${memberId}/delete/`);
                alert('Member deleted successfully!');

                fetchMembers();
            } catch (error) {
                console.error('Error deleting member:', error);
                alert('Failed to delete member. Please try again.');
            }
        }
    };


    const handleEdit = (member) => {
        setSelectedMember(member);
        setEditFormVisible(!editFormVisible);
        setSelectedImage(null);

        setFormData(prevFormData => ({
            ...prevFormData,
            name: member.name,
            qualification: member.qualification,
            image: null,
            recent_job_title: member.recent_job_title,
            additional_job_titles: member.additional_job_titles,
            previous_work_experience: member.previous_work_experience,
            publications: member.publications,
            linkedin: member.linkedin,
            conference: member.conference,
            additional_information: member.additional_information,
            achievements: member.achievements,
            areas: member.areas
        }));

    };




    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const postData = new FormData();
            postData.append('name', formData.name);
            postData.append('qualification', formData.qualification);
            postData.append('recent_job_title', formData.recent_job_title);
            postData.append('additional_job_titles', formData.additional_job_titles);
            postData.append('previous_work_experience', formData.previous_work_experience);
            postData.append('publications', formData.publications);

            // Handle the 'linkedin' field
            if (formData.linkedin) {
                postData.append('linkedin', formData.linkedin);
            } else {
                postData.append('linkedin', ''); // or omit it entirely if empty
            }

            postData.append('conference', formData.conference);
            postData.append('additional_information', formData.additional_information);
            postData.append('achievements', formData.achievements);
            postData.append('areas', formData.areas);

            if (formData.image) {
                postData.append('image', formData.image);
            }

            let response;
            if (!selectedMember) {
                response = await axios.post(`${BASE_URL}/admins/members/`, postData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                console.log('Add response:', response.data);
                alert('Member added successfully!');
            } else {
                response = await axios.put(`${BASE_URL}/admins/members/${selectedMember.id}/update/`, postData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                console.log('Update response:', response.data);
                alert('Member updated successfully!');
            }

            // Update the member list in the state
            const updatedMember = response.data;
            setMembers(prevMembers => {
                if (!selectedMember) {
                    return [...prevMembers, updatedMember];
                } else {
                    return prevMembers.map(member => member.id === updatedMember.id ? updatedMember : member);
                }
            });


            setFormData({
                name: '',
                qualification: '',
                image: '',
                recent_job_title: '',
                additional_job_titles: '',
                previous_work_experience: '',
                publications: '',
                linkedin: '',
                conference: '',
                additional_information: '',
                achievements: '',
                areas: ''
            });


            setEditFormVisible(false);

            setSelectedMember(null);

        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert('Failed to submit member. Please try again.');
        }
    };





    const togglePersonalInfo = () => {
        setPersonalInfoActive(!personalInfoActive);

        if (!personalInfoActive) {
            setmailingAddressActive(false);
        }
    };

    const togglemailingAddress = () => {
        setmailingAddressActive(!mailingAddressActive);

        if (!mailingAddressActive) {
            setPersonalInfoActive(false);
        }
    };
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredMembers = members.filter(member =>
        `${member.name}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [currentPage, setCurrentPage] = useState(1);
    const membersPerPage = 10;

    const indexOfLastMember = currentPage * membersPerPage;
    const indexOfFirstMember = indexOfLastMember - membersPerPage;
    const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredMembers.length / membersPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='bg-[#f4f4f4] h-[100vh]  p-6 '>
            <div className='bg-white p-6 rounded-[8px]'>
                <p className='text-[color:var(--Black,#222)] text-[24px] not-italic font-semibold leading-[25px] tracking-[-0.12px]'>Add Member</p>
                <div className='flex pt-12 gap-[12rem] p-8 '>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${personalInfoActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglePersonalInfo}>
                            <div className='flex gap-3'>
                                {personalInfoActive && <img src={imag} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${personalInfoActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Add Member</p>

                            </div>
                        </button>
                    </div>
                    <div className='flex gap-3'>
                        <button className={`relative p-8 ${mailingAddressActive ? 'border-b-2 border-[#00549A]' : ''}`} onClick={togglemailingAddress}>
                            <div className='flex gap-3'>
                                {mailingAddressActive && <img src={imag1} alt="" />}
                                <p className={`text-center text-[18px] not-italic font-normal leading-[20px] ${mailingAddressActive ? 'text-[#00549A]' : 'text-gray-600'}`}>Manage Member</p>

                            </div>
                        </button>
                    </div>
                </div>

                {personalInfoActive && (
                    <div className='w-[50%]'>
                        <form onSubmit={handleSubmit}>
                            <div className="text-start">
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px]'>Name</p>
                                <div className="pt-1">
                                    <input
                                        type="text"
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Qualifications</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                        placeholder="qualification"
                                        value={formData.qualification}
                                        onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Image</p>
                                <div className="pt-1">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="border border-gray-400 rounded-[6px] px-[20px] py-2 w-full bg-[#F4F4F4]"
                                    />
                                </div>

                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Recent job title</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="recent_job_title"
                                        value={formData.recent_job_title}
                                        onChange={(e) => setFormData({ ...formData, recent_job_title: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Additional Job Titles</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="additional_job_titles"
                                        value={formData.additional_job_titles}
                                        onChange={(e) => setFormData({ ...formData, additional_job_titles: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Previous Work Experience</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="previous_work_experience"
                                        value={formData.previous_work_experience}
                                        onChange={(e) => setFormData({ ...formData, previous_work_experience: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Publications</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="publications"
                                        value={formData.publications}
                                        onChange={(e) => setFormData({ ...formData, publications: e.target.value })}
                                    />
                                </div>

                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Conference Presentations</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="conference"
                                        value={formData.conference}
                                        onChange={(e) => setFormData({ ...formData, conference: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Additional Information</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="additional_information"
                                        value={formData.additional_information}
                                        onChange={(e) => setFormData({ ...formData, additional_information: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Achievements</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="achievements"
                                        value={formData.achievements}
                                        onChange={(e) => setFormData({ ...formData, achievements: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>Areas of Interest</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="areas"
                                        value={formData.areas}
                                        onChange={(e) => setFormData({ ...formData, areas: e.target.value })}
                                    />
                                </div>
                                <p className='text-[color:var(--Black,#222)] text-[18px] not-italic pt-6 font-medium leading-[24px]'>LinkedIn</p>
                                <div className="pt-1">
                                    <textarea
                                        className="border rounded-[6px] px-[20px] py-4  w-full bg-[#F4F4F4]"
                                        placeholder="LinkedIn_url"
                                        value={formData.linkedin}
                                        onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
                                    />
                                </div>
                                <div className='pt-8'>
                                    <button className='bg-[#00549A] rounded-[10px] w-full py-4'  >
                                        <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'  >Submit</p>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                {mailingAddressActive && (
                    <>
                        {displaySectionVisible && !editFormVisible && (
                            <div className=' pt-4'>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        placeholder="Search speakers..."
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500 w-[50%] relative"
                                    />

                                    <img src={search} alt="Search" className="absolute left-[40rem] top-6 transform -translate-y-1/2" />
                                </div>
                                <div className='bg-gray-100 py-4 w-full flex p-6 gap-[35rem]'>
                                    <p className='text-[color:var(--Gray,#58585A)] pl-12 text-[18px] not-italic font-semibold leading-[normal]'> Name</p>
                                    <p className='text-[color:var(--Gray,#58585A)] text-[18px] not-italic font-semibold   leading-[normal]'>Actions</p>
                                </div>

                                <div>
                                    {currentMembers.map((member, index) => (
                                        <div key={index} className='w-full'>
                                            <div className='flex pt-4 pb-4 pl-4 gap-40 items-center'>
                                                <div className='flex gap-4 items-center'>
                                                    <div className='text-[16px]  '>
                                                        {index + 1 + (currentPage - 1) * membersPerPage}
                                                    </div>
                                                    <div className='ml-4'>
                                                        <img src={member.image} alt="" className='w-[80px]  rounded-[60px]' />
                                                    </div>
                                                    <p className='text-[color:var(--Gray,#58585A)] w-[355px] text-[16px] not-italic font-normal leading-[normal]'>
                                                        {member.name}
                                                    </p>
                                                </div>
                                                <div className='flex gap-6'>
                                                    <div>
                                                        <img src={ed} alt="" onClick={() => handleEdit(member)} />
                                                    </div>
                                                    <div>
                                                        <img src={del} alt="" onClick={() => handleDelete(member.id)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}
                                </div>



                            </div>
                        )}


                        {editFormVisible && selectedMember && (
                            <div className='w-[50%]'>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="text-start">
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-4'> Name</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="Forum Name"
                                                value={formData.name}
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Qualification</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="qualification"
                                                value={formData.qualification}
                                                onChange={(e) => setFormData({ ...formData, qualification: e.target.value })}
                                            />
                                        </div>


                                        {selectedMember && (
                                            <div className="pt-4">

                                                <img src={selectedImage ? URL.createObjectURL(selectedImage) : selectedMember.image} alt="Old Forum Image" className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-[200px] bg-[#F4F4F4]" />
                                            </div>
                                        )}
                                        <div className="pt-4">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Recent job title</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="recent_job_title"
                                                value={formData.recent_job_title}
                                                onChange={(e) => setFormData({ ...formData, recent_job_title: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Additional job titles</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="additional_job_titles"
                                                value={formData.additional_job_titles}
                                                onChange={(e) => setFormData({ ...formData, additional_job_titles: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Previous work experience</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="previous_work_experience"
                                                value={formData.previous_work_experience}
                                                onChange={(e) => setFormData({ ...formData, previous_work_experience: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Publications</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="publications"
                                                value={formData.publications}
                                                onChange={(e) => setFormData({ ...formData, publications: e.target.value })}
                                            />
                                        </div>

                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'> Conference</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="conference"
                                                value={formData.conference}
                                                onChange={(e) => setFormData({ ...formData, conference: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'> Additional information</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="additional_information"
                                                value={formData.additional_information}
                                                onChange={(e) => setFormData({ ...formData, additional_information: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'> Achievements</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="achievements"
                                                value={formData.achievements}
                                                onChange={(e) => setFormData({ ...formData, achievements: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Areas</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="areas"
                                                value={formData.areas}
                                                onChange={(e) => setFormData({ ...formData, areas: e.target.value })}
                                            />
                                        </div>
                                        <p className='text-[color:var(--Black,#222)] text-[18px] not-italic font-medium leading-[24px] pt-6'>Linkedin</p>
                                        <div className="pt-2">
                                            <input
                                                type="text"
                                                className="border border-gray-400 rounded-[6px] px-[20px] py-4 w-full bg-[#F4F4F4]"
                                                placeholder="linkedin_url"
                                                value={formData.linkedin}
                                                onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
                                            />
                                        </div>

                                        <div className='flex'>
                                            <div className='pt-8 w-[70%]'>
                                                <button className='bg-[#00549A] rounded-[10px] w-full  py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]'>Update</p>
                                                </button>
                                            </div>


                                            <div className='pt-8 w-[30%] ml-6'>
                                                <button className='bg-gray-500 rounded-[10px] w-full py-4'>
                                                    <p className='text-white  text-center text-[20px] not-italic font-semibold leading-[normal]' onClick={handleExitEditForm}>Close</p>
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        )}
                        <div className="pagination flex justify-center items-center space-x-4 mt-6">
                            <button
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                            >
                                <span className="pagination-icon">&#8249;</span> {/* Left arrow */}
                            </button>
                            <span className="pagination-info">
                                Page {currentPage} of {Math.ceil(filteredMembers.length / membersPerPage)}
                            </span>
                            <button
                                onClick={nextPage}
                                disabled={currentPage === Math.ceil(filteredMembers.length / membersPerPage)}
                                className={`pagination-arrow ${currentPage === Math.ceil(filteredMembers.length / membersPerPage) ? 'disabled' : ''}`}
                            >
                                <span className="pagination-icon">&#8250;</span> {/* Right arrow */}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Amember;
