import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import Page1 from "../../../assets/images/Forum Members (1).jpg";
import Logo23 from "../../../assets/images/Vector-2.svg";
import { Link } from "react-router-dom";
import './Forum.css';

const Forum = () => {
    const [selectedForum, setSelectedForum] = useState(null);
    const [forums, setForums] = useState([]);
    const [forumMembers, setForumMembers] = useState([]);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const gridRef = useRef(null);  // New ref for the grid
    const isGridInView = useInView(gridRef, { once: true });
    useEffect(() => {
        if (isGridInView) {
            controls.start({ opacity: 1, y: 0 });
        }
    }, [isGridInView, controls]);
    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    useEffect(() => {
        const fetchForums = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/forums/`);
                setForums(response.data);

                // Fetch members of the first forum
                if (response.data.length > 0) {
                    handleForumChange(response.data[0]);
                }
            } catch (error) {
                console.error('Error fetching forums:', error);
            }
        };

        fetchForums();
    }, []);

    const handleForumChange = async (forum) => {
        setSelectedForum(forum);
        try {
            const response = await axios.get(`${BASE_URL}/admins/forums/${forum.id}/members/`);
            setForumMembers(response.data);
            console.log("response", response.data)
        } catch (error) {
            console.error('Error fetching forum members:', error);
        }
    };

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container mx-auto">
                <div className="pt-4">
                    <div className="bg-white rounded-[30px] pall-20">



                        <div className=" about-boards">
                            <motion.div
                                className="md:text-start pt-4 width50 mob-with-100 "
                                initial={{ opacity: 0, y: 50 }}
                                animate={controls}
                                transition={{ duration: 2 }}
                                ref={ref}
                            >

                                <p className="text-[#222] pt-14 m-pt-14 pt-m-0 text-[34px] not-italic font-bold leading-[normal]">Forum Members</p>
                                <p className="text-[#58585A] pt-8 mob-pat-pt-4 text-[19px] not-italic font-normal leading-[28px] about-text-padding">
                                    The Federation of Clinical Pharmacists in India (FCPI) is a non-profitable, professional pharmacy organization composed of a bunch of passionate pharmacists involved in diverse clinical pharmacy responsibilities in India and across the globe. We have come together voluntarily with the undeterred goal of enhancing the clinical pharmacy profession, empowering the clinical pharmacists and thereby delivering high-quality patient care.
                                </p>
                            </motion.div>
                            <div className="pt-6 d-md-none">

                                <motion.div
                                    className="relative mar-r-20 "
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 8 }}
                                    transition={{
                                        duration: 4,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <img src={Logo23} alt="Image" className="boarticon" />
                                    <img src={Page1} alt="Left Arrow" className="boardimg rounded-[30px]" />
                                </motion.div>
                            </div>
                        </div>



                        <div className="pt-28 p-all-2-0">

                            <div className="flex mob-dblock ">
                                <div className=" w-[29%] ">
                                    <div className="inline-flex flex-col md:flex-row items-start p-2  border rounded-[12px] border-solid border-[#00549A]">
                                        {forums.map((forum) => (
                                            <div key={forum.id} className="pt-2">
                                                <div className='w-[280px]'>
                                                    <button onClick={() => handleForumChange(forum)} className={`justify-center smooth items-center  w-[100%] px-8 py-3 rounded-[105px] ${selectedForum === forum ? "bg-[#00549A] text-white border-none" : "text-[#222] bg-[#F0FAFF] border"}`}  >
                                                        <p className="text-[14px] font-semibold">{forum.title}</p>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <motion.div
                                    className="flex flex-col gap-12 mob-top20 w-[71%]"
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={controls}
                                    transition={{ duration: 3 }}
                                    ref={gridRef}  // Use the new ref
                                >
                                    
                                        {forumMembers.length > 0 ? (
                                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">

                                                {forumMembers.map((forumMember) => (
                                                    <React.Fragment key={forumMember.id}>
                                                        {forumMember.member.map((member) => (
                                                            <Link to={`/members/${member.slug}`} key={member.id}>

                                                                <div className="shrink-0 rounded-[8px] p-formdiv border-b-4 memberbg">

                                                                    <img src={member.image} alt="" className="forum-img" />
                                                                    <p className="text-black pt-6 text-start membertext not-italic font-semibold leading-normal">{member.name}</p>
                                                                    <p className="text-black pt-2 text-start text-[16px] not-italic font-normal leading-normal">{member.qualification}</p>
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            
                                        </div>

                                    ) : (
                                    <p>No members found for this forum.</p>
                                    )}
                           
                            </motion.div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div >



    );
};

export default Forum;