import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavigationProvider, useNavigation } from './Context/NavigationContext';
import HomePage from './Pages/Home/Home';
import AboutfcpiPage from './Pages/User/About/AboutfcpiPage';
import AboutBoardPage from './Pages/User/About/AboutBoardPage';
import CommitteePage from './Pages/User/About/CommitteesPage';
import AboutForumPage from './Pages/User/About/AboutForumPage';
import Events from './Pages/User/Events/Eventpage'; 
import DetailPage from './Pages/User/Events/EventDetailPage'; 
import SpecialityForum from './Pages/User/SpecialityForum/SpecialityPage';
import MemberPage from './Pages/User/About/MemberPage';
import BlogsPage from './Pages/User/Blogs/BlogsPage';
import GalleryPage from './Pages/User/Gallery/GalleryPage';
import BlogDetailPage from './Pages/User/Blogs/BlogDetailPage';
import ContactPage from './Pages/User/Contact/ContactPage'; 
import RegisterPage from './Pages/User/Register/RegisterPage';
import Otp from './Components/User/Dashboard/Otp';
import OtpVerification from './Components/User/Dashboard/Login/OtpForgot';
import ResetPage from './Pages/User/Dashboard/ForgotPasswordPage/ResetPage';
import LoginPage from './Pages/User/Dashboard/Login/LoginPage';
import  UserDashboardPage from './Pages/User/Dashboard/UserDashbordPage';
import  MyProfilePage from './Pages/User/Dashboard/MyProfilePage';
import  PasswordPage from './Pages/User/Dashboard/ChangePasswordPage';
import  MyPointsPage from './Pages/User/Dashboard/MyPointsPage';
import  ALogin from './Components/Admin/AdminLogin/ALogin';
import  Adashboard from './Pages/Admin/Adashboard';
import  AforumPage from './Pages/Admin/AforumPage';
import  SpeakerPage from './Pages/Admin/SpeakerPage';
import  AuserPage from './Pages/Admin/AuserPage';
import  ProfilePage from './Pages/Admin/ProfileEditPage';
import  UserTypePage from './Pages/Admin/AuserTypePage';
// import  StatuPage from './Pages/Admin/Astatus';
import StatusPage from './Pages/Admin/Astatus';
import EventsPage from './Pages/Admin/AEventsPage';
import EditPage from './Pages/Admin/EditEventPage';
import ChatPage from './Pages/User/ChatPage/ChatPage';
import  SLogin from './Components/Speaker/SLogin';
import  AsuserPage from './Pages/Admin/AsuserPage'; 
import SecondUser from './Components/Speaker/SDashboard';
import AmemberPage from './Pages/Admin/AmemberPage';
import AForumMemberPage from './Pages/Admin/AForumMemberPage'; 
import AblogPage from './Pages/Admin/ABlogs'; 
import CertificatesPage from './Pages/Admin/CertificatePage';
import CertificatePage from './Pages/User/Dashboard/UCertificates';  
import HomeBannerPage from './Pages/Admin/CMSPage/HomeBannerPage';
import NewsPage from './Pages/Admin/CMSPage/NewPage';
import BoardPage from './Pages/Admin/CMSPage/BoardMembersPage';
import ABoardPage from './Pages/Admin/CMSPage/BoardPage';
import LivePage from './Pages/User/Dashboard/LivePage';
import UpcomingPage from './Pages/User/Dashboard/UpcomingPage';
import PastPage from './Pages/User/Dashboard/PastPage';
import GallerysPage from './Pages/Admin/CMSPage/GallerysPage';
import Csv from './Components/Admin/ACsv/csv';
import ForgotPasswordPage from './Pages/User/Dashboard/ForgotPasswordPage/ForgotpasswordPage';
import DownloadsPage from './Pages/User/Dashboard/DownloadsPage';
import GeneralBlogPage from './Pages/Admin/GeneralBlogPage'; 
import GeneralEventPage from './Pages/Admin/GeneralEventPage'; 
import ProtectedRoute from './Components/ProtectedRoute';
import GeneralCertificatesPage from './Pages/Admin/GeneralCertificatePage';
import NewsletterPage from './Pages/Admin/NewsletterPage'
import NewslettersPage from './Pages/User/Newsletter/NewsletterPage'
import  PodcastPage from './Pages/Admin/PodcastPage'
import  PodcastsPage from './Pages/User/Podcast/Podcastpage'
import  PodcastsDeailPage from './Pages/User/Podcast/PodcastDetailPage'
import  NewssPage from './Pages/User/News/NewsPage'

function App() {
  const isAuthenticated = !!localStorage.getItem('adminAuthToken');
  return (
 
    <div className="App">
         
      
      <Router>
     
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about-fcpi' element={<AboutfcpiPage />} />
          <Route path='/board-of-directors' element={<AboutBoardPage />} />
          <Route path='/committees' element={<CommitteePage />} />
          <Route path='/forum-members' element={<AboutForumPage />} />
          <Route path="/speciality-forum/:slug" element={<SpecialityForum/>} />
          <Route path='/events' element={<Events />} />
          <Route path='/members/:slug'  element={<MemberPage />} />
          <Route path='/events-detail/:slug' element={<DetailPage />} />
          <Route path='/enroll/:slug/' element={<ChatPage/>} />
          <Route path='/newsletters' element={<NewslettersPage/>}/> 
          <Route path='/podcasts' element={< PodcastsPage/>}/> 
          <Route path='/blogs' element={<BlogsPage/>} />
          <Route path='/blogs-detail/:slug' element={<BlogDetailPage />} />
          <Route path='/general-blogs-detail/:slug' element={<BlogDetailPage />} />
          <Route path='/contact' element={<ContactPage/>} />
          <Route path='/register' element={<RegisterPage/>} />
          <Route path='/gallery' element={<GalleryPage/>} />
          <Route path='/gallerys' element={<GallerysPage/>} />
          <Route path='/forgopassword' element={<ForgotPasswordPage/>} />
          <Route path="/podcast/:name" element={<PodcastsDeailPage />} />
          <Route path='/newspage' element={<NewssPage/>} />


{/* .......................................................................................................................................*/}
           <Route path='/otp' element={<Otp/>} />
           <Route path='/otp-forgot' element={<OtpVerification/>} />
           <Route path='/reset-password' element={<ResetPage/>} />
              
           <Route path='/login' element={<LoginPage/>} />
           <Route path="/dashboard"  element={<UserDashboardPage />}  />
           <Route path='/profile' element={<MyProfilePage/>} />
           <Route path='/password' element={<PasswordPage/>} />
           <Route path='/points' element={<MyPointsPage/>} />
           <Route path='/certificate' element={<CertificatePage/>} />
           <Route path='/live' element={<LivePage/>} />
           <Route path='/upcoming' element={<UpcomingPage/>} />
           <Route path='/past' element={<PastPage/>} />
           <Route path='/download' element={<DownloadsPage/>} />
           <Route path='/csv' element={<Csv/>} />
{/* /...................................................................................................................................... */}

           <Route path='/admin' element={<ALogin/>} />
         
           <Route path='/admin-dashboard' element={<Adashboard/>} />
         
           <Route path='/aforum' element={<AforumPage/>} />
           <Route path='/speaker' element={<SpeakerPage/>} />
           <Route path='/user' element={<AuserPage/>} />
           <Route path='/seconduser' element={<AsuserPage/>} />
           <Route path='/user-editprofile/:userId' element={<ProfilePage />} />
           <Route path='/usertype' element={<UserTypePage/>} />
           <Route path='/user-status/:userId' element={<StatusPage/>} />
           <Route path='/aevents' element={<EventsPage/>} />
           <Route path='/general-event' element={<GeneralEventPage/>} />          
           <Route path="/events/:eventId" element={<EditPage />} />
           <Route path='/member' element={<AmemberPage/>} />
           <Route path='/forummember' element={<AForumMemberPage/>} />
           <Route path='/ablogs' element={<AblogPage/>} />
           <Route path='/general-blog' element={<GeneralBlogPage/>} />
           <Route path='/certificates' element={<CertificatesPage/>} />
           <Route path='/general-certificate' element={<GeneralCertificatesPage/>} />       
           <Route path='/banner' element={<HomeBannerPage/>} />
           <Route path='/news' element={<NewsPage/>} />
           <Route path='/boardmembers' element={<BoardPage/>} />
           <Route path='/board' element={<ABoardPage/>} />
           <Route path='/newsletter' element={<NewsletterPage/>} />
           <Route path='/podcast' element={<PodcastPage/>} />
{/* ....................................................................................................................................... */}

           <Route path='/speakerlogin' element={<SLogin/>} />  
           <Route path='/seconduserd' element={<SecondUser/>} /> 
           



        </Routes>
      
      </Router>
     
    </div>
  
  );
}

export default App;
// xhtsi8aD