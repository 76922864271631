import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import dot from "../../../assets/images/dot.png";
import lin from "../../../assets/images/linkdn.png";

const Member = () => {
    const { slug } = useParams();
    const [member, setMember] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMemberDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/members/${slug}/detail/`);
                const memberData = response.data;
                setMember(memberData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching member details:', error);
                setLoading(false);
            }
        };

        fetchMemberDetails();
    }, [slug]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!member) {
        return <div>Member not found</div>;
    }

    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className="pt-6">
                    <div className="bg-white rounded-[30px]">
                        <div className="bg-[#EAFBF1] rounded-t-[30px]">
                            <div className="flex m-blok-div gap-16 m-gap-16 p-all-200 align-center">
                                <img
                                    src={BASE_URL + member.image}
                                    alt="Image"
                                    className="rounded-[400px] w-[18%] m-w-100 "
                                />
                                <div className="m-p-21">
                                    <p className="text-[#00549A] text-[42px] not-italic font-bold leading-[140%] tracking-[0.42px]">
                                        {member.name}
                                    </p>
                                    <p className="text-[#222] pt-2 text-[24px] not-italic font-medium leading-[140%]">
                                        {member.qualification}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-all-200 ">
                            {member.recentJobTitle && member.recentJobTitle !== "N/A" && (
                                <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                    <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                        Current/Recent job title:
                                    </p>
                                    <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                        {member.recentJobTitle}
                                    </p>
                                </div>
                            )}

                            {member.additionalJobTitles && member.additionalJobTitles !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3 ">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2  border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Additional Job Titles:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.additionalJobTitles}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.previousWorkExperience && member.previousWorkExperience !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Previous Work Experience apart from Current Job Title:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.previousWorkExperience}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.publications && member.publications !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Publications:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.publications}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.currentResearch && member.currentResearch !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Current research work (2022):
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.currentResearch}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.conference && member.conference !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Conference Presentations and Invited Talks:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.conference}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.additionalInformation && member.additionalInformation !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Additional Information:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.additionalInformation}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.achievements && member.achievements !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Achievements and Awards:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.achievements}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {member.areasOfInterest && member.areasOfInterest !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <p className="text-[#222] text-[23px] text-20 not-italic font-semibold leading-[normal]">
                                            Areas of Interest:
                                        </p>
                                        <p className="text-[#58585A] text-[19px] pt-2 not-italic font-normal leading-[normal]">
                                            {member.areasOfInterest}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {member.linkedin && member.linkedin !== "N/A" && (
                                <div className="">
                                    <div className="pt-3 pb-3">
                                        <img src={dot} alt="Dot Image" />
                                    </div>
                                    <div className="border-l-2 border-[#00549A] pl-10 pl-m-5">
                                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                                            <img src={lin} alt="LinkedIn" />
                                        </a>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Member;
